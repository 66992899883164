import React, {FC, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {Breadcrumb, Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {ConfirmModal, Footer, FormCol, FormSelect, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {useImmer} from "use-immer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useTRPC} from "backoffice/ui/App";
import {DepositTransactionStatus} from "src/codes";
import {AppRouterOutput} from "backoffice/api/AppRouter";
import {userConv} from "src/utils/conv";

const pageSize = 50;

const defaultCondition = {
  statusId     : DepositTransactionStatus['カスタマー判定 待ち'].id.toString(),
  confirmStatus: 'yet',
  since        : '',
  until        : '',
}

export const MembersDepositListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  // ステート
  const [query, setQuery]           = useSearchParams(),
        [condition, setCondition]   = useImmer(defaultCondition),
        [result, setResult]         = useState({total: 0, list: []} as AppRouterOutput['member']['deposit']['list']),
        [confirming, setConfirming] = useState<number | false>(false),
        [selection, setSelection]   = useState([] as number[]),
        [modal, setModal]           = useState('');


  // 初期化
  useEffect(() => {

    // 選択クリア
    setSelection([]);

    const cond = {
            statusId     : query.get('statusId') || defaultCondition.statusId,
            confirmStatus: query.get('confirmStatus') || defaultCondition.confirmStatus,
            since        : query.get('since') || defaultCondition.since,
            until        : query.get('until') || defaultCondition.until,
          },
          page = Number(query.get('page')) || 1;

    //
    setCondition(cond);

    const statusId = Number(cond.statusId);
    switch (statusId) {
      case DepositTransactionStatus['カスタマー判定 OK'].id:
      case DepositTransactionStatus['カスタマー判定 NG'].id: {
        setConfirming(statusId);
        break;
      }

      default: {
        setConfirming(false);
      }
    }

    // データ取得
    (async () => {
      const req = {...cond, page, pageSize};
      await Loader.task(async () => await tRPC.member.deposit.list.query(req), 300)
                  .then(res => setResult(res))
                  .catch(err => {
                    console.error(err);
                    Message.error(err);
                  });
    })();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', String(new Date().getTime()));
    setQuery(query);
  }


  // ヘッダーチェックのクリック
  const handleClickHeaderCheck = () => {
    if (!confirming) {
      return;
    }

    for (const elem of result.list) {
      if (!selection.includes(elem.id)) {
        setSelection(result.list.map(el => el.id));
        return;
      }
    }
    setSelection([]);
  };

  /**
   * 登録処理
   */
  const handleConfirm = () => {
    setModal('');

    if (!confirming) {
      return;
    }

    Loader.task(async () => {
      try {
        await tRPC.member.deposit.confirm.mutate({
          statusId: confirming,
          list    : selection,
        })
        Message.show('登録が完了しました', () => handlePage(1));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    })
  };

  return (
      <>
        <Helmet>
          <title>仮想口座入金</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>お客様管理</Breadcrumb.Item>
            <Breadcrumb.Item active>仮想口座入金</Breadcrumb.Item>
            <Breadcrumb.Item active>取引一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Form className={'my-5'}>
                <input type='submit' className={'d-none'} disabled/>
                <Row>
                  <FormCol inputId={'statusId'} className={'py-1'}
                           title={'ステータス'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect value={condition.statusId}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.statusId = value))}>
                      {DepositTransactionStatus.all.map(elem =>
                          <option value={elem.id} key={elem.id}>{elem.value}</option>)}
                    </FormSelect>
                  </FormCol>

                  <FormCol inputId={'confirmStatus'} className={'py-1'}
                           title={'承認状況'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect value={condition.confirmStatus}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.confirmStatus = value))}>
                      <option value=''>-</option>
                      <option value='yet'>未承認</option>
                      <option value='done'>承認済み</option>
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'since'} className={'py-1'}
                           title={'期間開始'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormText type={"date"}
                              value={condition.since}
                              onChange={({target: {value}}) => setCondition(draft => void (draft.since = value))}/>
                  </FormCol>

                  <FormCol inputId={'until'} className={'py-1'}
                           title={'期間終了'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormText type={"date"}
                              value={condition.until}
                              onChange={({target: {value}}) => setCondition(draft => void (draft.until = value))}/>
                  </FormCol>
                </Row>

                <Row style={{marginTop: 20}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'}
                            onClick={handleSearch}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <div className={'container small'}>
          <Table hover>
            <thead>
            <tr>
              <th className={`text-center`} onClick={handleClickHeaderCheck}>
                <OverlayTrigger placement={'top'} overlay={
                  <Tooltip>
                    一つでも選択されていなければ「すべて選択」<br/>
                    すべて選択されていれば「すべて選択解除」
                  </Tooltip>}>
                  <FontAwesomeIcon icon={faCheck}/>
                </OverlayTrigger>
              </th>
              <th className={'text-end'}>ID</th>
              <th className={'text-center'} style={{width: '6rem'}}>日付</th>
              <th className={'text-center'} style={{width: '6rem'}}>ユーザーID</th>
              <th style={{width: '6rem'}}>登録名義</th>
              <th style={{width: '6rem'}}>振込名義</th>
              <th className={'text-end'} style={{width: '6rem'}}>金額</th>
              <th style={{width: '6rem'}}>メモ</th>
              <th style={{width: '6rem'}}>判定者</th>
              <th className={'text-center'} style={{width: '12rem'}}>判定日時</th>
              <th style={{width: '6rem'}}>承認者</th>
              <th className={'text-center'} style={{width: '12rem'}}>承認日時</th>
              <th style={{width: '3rem'}}>&nbsp;</th>
            </tr>
            </thead>

            <tbody>
            {/* データなし */}
            {result.list.length === 0 && (
                <tr>
                  <td colSpan={14}>---</td>
                </tr>
            )}

            {result.list.map((elem, index) => (
                    <tr key={elem.id} className={(index % 2) ? 'bg-white' : 'bg-light'}>
                      <td className={`p-1`} style={{width: "2em"}}>
                        <label className={'d-flex justify-content-center m-0'}
                               style={{width: "2em", height: "2em"}}>
                          <input className={'align-self-center'}
                                 checked={selection.includes(elem.id)} type={'checkbox'}
                                 onChange={() => setSelection(selection.toggle(elem.id))}
                                 disabled={!confirming}/>
                        </label>
                      </td>
                      <td className={'p-1 text-end    align-middle'}>{elem.id}</td>
                      <td className={'p-1 text-center align-middle'}>{elem.valueDate}</td>
                      <td className={'p-1 text-center align-middle'}>{elem.userId}</td>
                      <td className={'p-1 text-start  align-middle'}>{elem.userName}</td>
                      <td className={'p-1 text-start  align-middle'}>{elem.remitterName}</td>
                      <td className={'p-1 text-end    align-middle'}>{elem.amount?.toString().withComma ?? '-'}</td>
                      <td className={'p-1 text-start  align-middle'}>{elem.decisionMemo}</td>
                      <td className={'p-1 text-start  align-middle'}>{userConv(elem.decidedBy) || '---'}</td>
                      <td className={'p-1 text-center align-middle'}>{elem.decidedAt}</td>
                      <td className={'p-1 text-start  align-middle'}>{userConv(elem.confirmedBy) || '---'}</td>
                      <td className={'p-1 text-center align-middle'}>{elem.confirmedAt}</td>
                      <td className={'p-1 text-center align-middle'}>
                        <Link to={`./${elem.id}`} className={'btn btn-primary btn-sm'}>
                          <FontAwesomeIcon icon={faEdit}/>
                        </Link>
                      </td>
                    </tr>
                )
            )}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-between'}>
            <span>件数: {String(result.total).withComma}</span>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>

        {/* フッター */}
        <Footer>
          <Button variant='primary'
                  className={'m-2'}
                  style={{width: 200}}
                  onClick={() => setModal('confirm')}
                  disabled={!confirming || selection.length === 0}>
            承認登録
          </Button>
        </Footer>

        {/* 承認モーダル */}
        <ConfirmModal show={modal === 'confirm'}
                      confirmLabel={'承認する'}
                      onConfirm={handleConfirm}
                      onCancel={() => setModal('')}>

          {selection.length}件のレコードを承認しますか？
        </ConfirmModal>
      </>
  );
}
