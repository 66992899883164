import React, {FC, useEffect, useState} from "react";
import {useTRPC} from "backoffice/ui/App";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {ConfirmModal, FormCol, FormNumber, FormSelect, FormText, useLoader, useMessage} from "backoffice/ui/Components";
import {Helmet} from "react-helmet";
import type {AccountType} from "src/services/GmoAozoraNetBankAPI/types";

export const VirtualAccountPage: FC = () => {

  const Loader  = useLoader(),
        Message = useMessage(),
        tRPC    = useTRPC();


  const [raList, setRaList]           = useState<AccountType[]>([]),
        [raId, setRaId]               = useState(''),
        [nextItemKey, setNextItemKey] = useState(''),
        [vaCount, setVaCount]         = useState({total: 0, used: 0}),
        [request, setRequest]         = useState('500'),
        [modal, setModal]             = useState(''),
        [age, setAge]                 = useState(1);


  // 口座情報
  useEffect(() => {
    Loader.task(tRPC.banking.gmoAozoraNet.accounts.query, 300)
          .then(([raList, raId]) => {
            setRaList(raList);
            setRaId(raId);
          })
          .catch(err => Message.error(err));
  }, [])


  // 仮想口座情報
  useEffect(() => {
    if (raId === '') {
      return;
    }

    Loader.task(() => tRPC.banking.gmoAozoraNet.countAccounts.query({raId}), 300)
          .then(res => setVaCount(res))
          .catch(err => Message.error(err));
  }, [raId])


  const importAccounts = async () => {
    Loader.task(() => tRPC.banking.gmoAozoraNet.importAccounts.mutate({raId, nextItemKey}), 1000)
          .then(({nextItemKey, ...count}) => {
            setNextItemKey(nextItemKey || '');
            setVaCount(count);
          })
          .then(() => setModal(''))
          .then(() => Message.show('完了しました'))
          .catch(err => Message.error(err));
  };


  const issueAccounts = async () => {
    const num = request.toNumber();
    if (num === null) {
      Message.error('リクエスト数を入力してください');
      return;
    }

    Loader.task(() => tRPC.banking.gmoAozoraNet.issueAccounts.mutate({raId, count: Number(num)}), 1000)
          .then(res => setVaCount(res))
          .then(() => setModal(''))
          .then(() => Message.show('完了しました'))
          .catch(err => Message.error(err));
  };

  return (
      <>
        <Helmet>
          <title>仮想口座管理 GMOあおぞら銀行連携</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'./'}>GMOあおぞら銀行連携</Breadcrumb.Item>
            <Breadcrumb.Item active>仮想口座管理</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'pt-5'}>
          <Row>
            <Col xs={{span: 12}}
                 md={{span: 8, offset: 2}}>
              <Container>
                <Row>
                  <FormCol inputId={'raId'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'入金口座'}
                           size={{xs: [2, 10]}}>

                    <FormSelect value={raId}
                                onChange={({currentTarget: {value}}) => {
                                  (tRPC.banking.gmoAozoraNet.setAccountId).mutate({raId: value})
                                                                          .then(() => setRaId(value));
                                }}>

                      {raId === "" && <option value="">-</option>}
                      {raList.map(el => (
                          <option key={el.accountId} value={el.accountId}>
                            {el.branchName} | {el.accountTypeName} | {el.accountNumber} | {el.accountName}
                          </option>
                      ))}
                    </FormSelect>
                  </FormCol>
                </Row>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col xs={{span: 12}}
                 md={{span: 8, offset: 2}}>
              <Container>
                <Row>
                  <FormCol inputId={'nextItemKey'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'取り込みキー'}
                           size={{xs: [2, 8]}}>

                    <FormText value={nextItemKey}
                              onChange={({currentTarget: {value}}) => setNextItemKey(value)}
                              placeholder={''}/>

                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'total'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'トータル'}
                           size={{xs: [2, 8]}}>

                    <FormText value={vaCount?.total?.withComma ?? '-'}
                              placeholder={''}
                              readOnly/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'total'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'使用中'}
                           size={{xs: [2, 8]}}>

                    <FormText value={vaCount?.used?.withComma ?? '-'}
                              placeholder={''}
                              readOnly/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'stock'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'ストック'}
                           size={{xs: [2, 8]}}>

                    <FormText value={vaCount ? (vaCount.total - vaCount.used).withComma : '-'}
                              placeholder={''}
                              readOnly/>
                  </FormCol>
                </Row>

                <Row className={'pt-3'}>
                  <Col className={'text-center'}>
                    <Button onClick={() => setModal('import')}
                            variant={'secondary'}
                            className={'w-50'}>
                      仮想口座取り込み
                    </Button>
                  </Col>
                </Row>


                <Row style={{marginTop: 100}}>
                  <FormCol inputId={'request'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'リクエスト'}
                           size={{xs: [2, 8]}}>

                    <FormNumber value={request}
                                onChange={({currentTarget: {value}}) => setRequest(value)}
                                placeholder={''}/>
                  </FormCol>
                </Row>

                <Row className={'pt-3'}>
                  <Col className={'text-center'}>
                    <Button onClick={() => setModal('issue')}
                            variant={'secondary'}
                            className={'w-50'}
                            disabled={request.toNumber() === null}>
                      仮想口座作成
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>


        <ConfirmModal show={modal === 'import'}
                      confirmLabel={'インポート'}
                      onConfirm={importAccounts}
                      onCancel={() => setModal('')}>

          仮想口座をDBに反映させますか？

          <div className={'my-3 p-2 fs-6'}>
            * DBと実際のデータとで不一致が発生した場合などに実行
          </div>

        </ConfirmModal>

        <ConfirmModal show={modal === 'issue'}
                      confirmLabel={'作成する'}
                      onConfirm={issueAccounts}
                      onCancel={() => setModal('')}>

          {request.withComma}件の仮想口座を作成しますか？

        </ConfirmModal>
      </>
  );
}
