import {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {ConfirmModal, FormSelect, FormText, FormTextArea, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate, useParams} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {useImmer} from "use-immer";
import {ValidationError} from "backoffice/api/ValidationError";
import {NoticeInfoModel} from "backoffice/api/RDS/Models/NoticeMng/NoticeInfoModel";

const defaultPlanDeliveryDate = (() => {
  const date = new Date();

  if (date.getMinutes() < 30) {
    date.setMinutes(30);
  } else {
    date.addHour(1);
    date.setMinutes(0);
  }
  date.setSeconds(0);
  return date;
});

const CategoryOptions = [
  {value: "お知らせ:#FFFF99", label: "お知らせ"},
  {value: "キャンペーン:#FF99FF", label: "キャンペーン"},
  {value: "分配・償還:#99FFFF", label: "分配・償還"},
]


const newModel = {
  notice_name       : "",
  title             : "",
  message           : "",
  category          : CategoryOptions[0].value,
  plan_delivery_date: defaultPlanDeliveryDate(),
};


export const MembersNoticeMngNotificationFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const [editable, setEditable] = useState(true),
        [deleting, setDeleting] = useState(false),
        [model, setModel]       = useImmer<Partial<NoticeInfoModel & { count?: number }> | null>(newModel),
        [error, setError]       = useState<ValidationError | null>(null),
        [file, setFile]         = useState<File | null>(null),
        [age, setAge]           = useState(0);

  const {id} = useParams();


  // 初回実行
  useEffect(() => {
    document.title = `${id === 'new' ? '配信予約登録' : '配信予約確認'} | プッシュ通知管理`;

    if (id === 'new') {
      setModel(newModel);
      return;
    }

    Loader.task(async () => {
      try {
        // データ登録
        const model = await trpc.noticemng.notification.get.query(id ?? '');
        setModel(model);
        setEditable(model.delivery_status === 0);


      } catch (err) {
        console.error(err);
        Message.error(err, () => Navigate('../'));
      }
    }, 300).then();
  }, [id, age]);

  /**
   * 保存
   */
  const handleSubmit = () => {

    // エラー解除
    setError(null);

    Loader.task(async () => {
      try {
        // データ登録
        const result = await trpc.noticemng.notification.set.mutate(model as NoticeInfoModel);

        // ファイルが指定されていれば、アップロードして取り込み
        if (file) {
          const {url, key} = await trpc.file.uploadLink.query({
            contentType: 'text/plain',
            prefix     : 'noticemng/upload',
            filename   : file.name,
          });

          // アップロードして
          await fetch(url, {method: 'PUT', body: file});

          // 取り込み
          await trpc.noticemng.notification.import.mutate({notice_id: result.notice_id, key});
        }

        if (id === 'new') {
          Message.show('登録が完了しました');
          Navigate(`../${result.notice_id}`);

        } else {
          Message.show('登録が完了しました');
        }
        setAge(age + 1);


      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  /**
   * CSVダウンロード
   */
  const handleDownload = () => {
    Loader.task(async () => {
      try {
        const result = await trpc.noticemng.notification.export.query(id as string);
        location.href = result.url;

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  /**
   * 削除
   */
  const handleDelete = () => {
    Loader.task(async () => {
      try {
        // 削除
        await trpc.noticemng.notification.delete.mutate(id as string);
        setDeleting(false);

        Message.show('削除が完了しました', () => Navigate(`../`));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>プッシュ通知管理</Breadcrumb.Item>
            <Breadcrumb.Item href={'./'}>配信状況一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>{id === 'new' ? '配信予約登録' : '配信予約確認'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>
          {model === null && <div>not found</div>}
          {model !== null && (
              <Form className={'my-5'}>
                <Row>
                  <Col md={{span: 5}} lg={{span: 5, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="notice_name">
                      <Form.Label>名称</Form.Label>
                      <FormText value={model.notice_name}
                                onChange={({currentTarget: {value}}) => setModel(draft => draft && void (draft.notice_name = value))}
                                placeholder={'例: 通知名 - 新規ファンド募集開始'}
                                disabled={!editable}/>

                      <div>
                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get('notice_name')}/>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={{span: 6}} lg={{span: 5, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="title">
                      <Form.Label>タイトル</Form.Label>
                      <FormText value={model.title}
                                onChange={({currentTarget: {value}}) => setModel(draft => draft && void (draft.title = value))}
                                placeholder={'例: 新規ファンド募集開始！'}
                                disabled={!editable}/>

                      <div>
                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get('title')}/>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={{span: 6}} lg={{span: 6, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="message">
                      <Form.Label>メッセージ</Form.Label>
                      <FormTextArea value={model.message}
                                    onChange={({currentTarget: {value}}) => setModel(draft => draft && void (draft.message = value))}
                                    style={{minHeight: '10rem'}}
                                    disabled={!editable}/>

                      <div>
                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get('message')}/>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={{span: 3}} lg={{span: 3, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="category">
                      <Form.Label>カテゴリ</Form.Label>
                      <FormSelect value={model.category}
                                  onChange={({currentTarget: {value}}) => setModel(draft => draft && void (draft.category = value))}
                                  disabled={!editable}>
                        {CategoryOptions.map((elem, index) => (
                            <option key={index} value={elem.value}>{elem.label}</option>
                        ))}
                      </FormSelect>

                      <div>
                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get('category')}/>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={{span: 6}} lg={{span: 4, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="plan_delivery_date">
                      <Form.Label>配信予定日</Form.Label>
                      <FormText value={model.plan_delivery_date?.toYmdThms() ?? ''} name={'plan_delivery_date'}
                                onChange={({currentTarget: {value}}) => setModel(draft => draft && void (draft.plan_delivery_date = value ? new Date(value) : undefined))}
                                type={'datetime-local'}
                                disabled={!editable}/>
                      <div>
                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get('plan_delivery_date')}/>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>


                <Row>
                  <Col md={{span: 6}} lg={{span: 6, offset: 3}} style={{padding: 5}}>
                    <Form.Group controlId="user">
                      <div className={'d-flex flex-row justify-content-between'}>
                        <div style={{whiteSpace: 'nowrap'}}>配信対象ユーザ</div>
                        {model.count && (
                            <div className={'flex-shrink-1 m-1'}>
                              <Button variant={'outline-primary'}
                                      size={'sm'}
                                      onClick={handleDownload}>
                                ダウンロードする ({model.count}名)
                              </Button>
                            </div>
                        )}
                      </div>
                      <Form.Control type="file"
                                    key={age}
                                    onChange={({currentTarget}) => "files" in currentTarget && setFile(currentTarget.files?.[0] ?? null)}
                                    disabled={!editable}/>
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{marginTop: 50}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'}
                            disabled={!editable}
                            onClick={handleSubmit}>
                      {id === 'new' ? '登録' : '更新'}する
                    </Button>
                  </Col>
                </Row>

                {id !== 'new' && (
                    <Row style={{marginTop: 0}}>
                      <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                        <Button variant="danger" className={'w-100'}
                                disabled={!editable}
                                onClick={() => setDeleting(true)}>
                          削除する
                        </Button>
                      </Col>
                    </Row>
                )}
              </Form>
          )}
        </div>

        {/* 削除モーダル */}
        <ConfirmModal show={deleting}
                      confirmLabel={'削除する'}
                      confirmButtonProps={{
                        variant: 'danger',
                      }}
                      onConfirm={handleDelete}
                      onCancel={() => setDeleting(false)}>

          編集中の通知を削除しますか？
        </ConfirmModal>
      </>
  );
}
