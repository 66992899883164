import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {LoanManagerProjectTradeListPage} from "./List";
import {LoanManagerProjectTradeFormPage} from "./Form";


export const LoanManagerProjectTradePage: FC = () => {
  return (
      <Routes>
        <Route index element={<LoanManagerProjectTradeListPage/>}/>
        <Route path=":tradeId" element={<LoanManagerProjectTradeFormPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


