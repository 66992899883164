import React, {ChangeEventHandler, FC, useCallback, useState} from "react";
import {useImmer} from "use-immer";
import {Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import {FormNumber, FormText, Nl2Br} from "backoffice/ui/Components";
import {Currency} from "src/codes";
import Big from "big.js";
import {FormModel} from "./index";
import {ValidationError} from "backoffice/api/ValidationError";

import './Conditions.scss';


interface Props {
  model: ReturnType<typeof useImmer<FormModel>>[0],
  setModel: ReturnType<typeof useImmer<FormModel>>[1],
  error: ReturnType<typeof useState<ValidationError | null>>[0],
}

export const Conditions: FC<Props> = ({model, setModel, error}) => {


  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({
    currentTarget: {type, name, dataset: {index}, value, checked}
  }) => {
    setModel(draft => {
      // @ts-ignore
      draft.conditions[index][name] = type === 'checkbox' ? checked : value;
    })
  }, [setModel]);

  const currencyPrecision = Currency.find(model.currencyId)?.precision ?? 0;
  return (
      <Row>
        <Col>
          <Table className={`my-1 p-0 border`} borderless>
            <tbody>
            {model?.conditions?.map((cond, index) => {

              const clazz    = cond.deleted ? 'bg-tomato' : (index % 2) ? 'bg-white' : 'bg-light',
                    tabIndex = (index + 1) * 100;
              return (
                  <React.Fragment key={cond.id ?? cond.uuid ?? index}>
                    <tr className={clazz}>
                      <th className={'p-1'} style={{minWidth: 50}}>
                        <div className={'form-control-plaintext form-control-sm text-end'}>名称</div>
                      </th>
                      <td colSpan={4} className={'p-1'}>
                        <FormText value={cond.name} name={'name'} data-index={index}
                                  onChange={handleChange}
                                  tabIndex={tabIndex + 1} required/>

                        <div>
                          <Form.Text className="text-danger">
                            <Nl2Br text={error?.get(`conditions.${index}.name`)}/>
                          </Form.Text>
                        </div>
                      </td>
                      <td className={'p-1'}>&nbsp;</td>

                      <th className={'p-0 align-bottom'}>
                        <div className={'form-control-plaintext form-control-sm text-center'} style={{width: 100}}>
                          営業者報酬
                        </div>
                      </th>
                      <th className={'p-0 align-bottom'}>
                        <div className={'form-control-plaintext form-control-sm text-center'} style={{width: 100}}>
                          投資家分配率
                        </div>
                      </th>
                      <th className={'p-0 align-bottom'}>
                        <div className={'form-control-plaintext form-control-sm text-center'} style={{width: 100}}>
                          融資手数料
                        </div>
                      </th>
                      <th className={'p-0 align-bottom'}>
                        <div className={'form-control-plaintext form-control-sm text-center'} style={{width: 100}}>
                          総利率
                        </div>
                      </th>
                    </tr>

                    <tr className={clazz}>
                      <th className={'p-1'} style={{minWidth: 50}}>
                        <div className={'form-control-plaintext form-control-sm text-end'}>金額</div>
                      </th>

                      <td colSpan={4} className={'p-1'}>
                        <FormNumber value={cond.amount}
                                    name={'amount'}
                                    data-index={index}
                                    precision={currencyPrecision}
                                    realtime={true}
                                    size={'sm'}
                                    style={{width: 150}}
                                    onChange={handleChange}
                                    tabIndex={tabIndex + 2} required/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.amount`)}/>
                        </Form.Text>
                      </td>

                      <th className={'p-1'}>
                        <div className={'form-control-plaintext form-control-sm text-end'} style={{minWidth: 40}}>
                          通常時
                        </div>
                      </th>


                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.management_fee_rate}
                                      name={'management_fee_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 11}
                                      required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.management_fee_rate`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.interest_rate}
                                      name={'interest_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 12} required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.interest_rate`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.loan_fee_rate}
                                      name={'loan_fee_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 13} required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.loan_fee_rate`)}/>
                        </Form.Text>
                      </td>


                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber name={'total_rate'} data-index={index}
                                      value={Big(cond.management_fee_rate?.toNumber(2) || 0).add(cond.interest_rate?.toNumber(2) || 0)
                                                                                            .add(cond.loan_fee_rate?.toNumber(2) || 0)
                                                                                            .toFixed(2)}

                                      precision={2}
                                      readOnly={true}
                                      onChange={handleChange}/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                      </td>
                    </tr>

                    <tr className={clazz}>
                      <th className={'p-1'} style={{minWidth: 50}}>
                        <div className={'form-control-plaintext form-control-sm text-end'}>期間</div>
                      </th>

                      <td className={'p-1'}>
                        <FormText value={cond.since} name={'since'} data-index={index}
                                  type={'date'}
                                  size={'sm'}
                                  onChange={handleChange}
                                  tabIndex={tabIndex + 2} required/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.since`)}/>
                        </Form.Text>
                      </td>
                      <td className={'p-1'}>
                        <div className={'form-control-plaintext form-control-sm text-center'}>~</div>
                      </td>
                      <td className={'p-1'}>
                        <FormText value={cond.until} name={'until'} data-index={index}
                                  type={'date'}
                                  size={'sm'}
                                  onChange={handleChange}
                                  tabIndex={tabIndex + 3} required/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.until`)}/>
                        </Form.Text>
                      </td>
                      <td className={'p-1'}>&nbsp;</td>

                      <th className={'p-1'}>
                        <div className={'form-control-plaintext form-control-sm text-end'} style={{minWidth: 50}}>
                          遅延時
                        </div>
                      </th>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.delay_management_fee_rate}
                                      name={'delay_management_fee_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 21}
                                      required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.delay_management_fee_rate`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.delay_interest_rate}
                                      name={'delay_interest_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 22}
                                      required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.delay_interest_rate`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber value={cond.delay_loan_fee_rate}
                                      name={'delay_loan_fee_rate'}
                                      data-index={index}
                                      precision={2}
                                      onChange={handleChange}
                                      tabIndex={tabIndex + 23}
                                      required/>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`conditions.${index}.delay_loan_fee_rate`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: 100}}>
                        <InputGroup size={'sm'}>
                          <FormNumber name={'delay_total_rate'} data-index={index}
                                      value={Big(cond.delay_management_fee_rate?.toNumber(2) || 0).add(cond.delay_interest_rate?.toNumber(2) || 0)
                                                                                                  .add(cond.delay_loan_fee_rate?.toNumber(2) || 0)
                                                                                                  .toFixed(2)}
                                      precision={2}
                                      readOnly
                                      onChange={handleChange}/>
                          <InputGroup.Text id={`delay_total_rate-${index}`}>%</InputGroup.Text>
                        </InputGroup>
                      </td>
                    </tr>

                    <tr className={`${clazz}`}>
                      <th className={'p-1'} style={{minWidth: 50}}>
                        <div className={'form-control-plaintext form-control-sm text-end'}>メモ</div>
                      </th>
                      <td className={'p-1'} colSpan={9}>
                        <FormText value={cond.memo} name={'memo'} data-index={index}
                                  size={'sm'}
                                  onChange={handleChange}
                                  tabIndex={tabIndex + 99}/>
                      </td>
                    </tr>

                    <tr className={`${clazz} border-bottom`}>
                      <th className={'p-1'} style={{minWidth: 50}}>
                        <div className={'form-control-plaintext form-control-sm text-end'}>&nbsp;</div>
                      </th>
                      <td className={'p-1'} colSpan={9}>
                        <Form.Check id={`conditions-delete-${index}`}
                                    name={'deleted'}
                                    data-index={index}
                                    checked={cond.deleted}
                                    onChange={handleChange}
                                    label={cond.deleted ? '保存時に条件が削除されます'
                                                        : '条件を削除する'}/>
                      </td>
                    </tr>
                  </React.Fragment>
              )
            })}
            </tbody>
          </Table>
        </Col>
      </Row>
  )
}

