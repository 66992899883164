import {ChangeEventHandler, FC, ReactNode, useCallback, useEffect} from "react";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import {useImmer} from "use-immer";
import {Test01} from "./Test01";
import {Test02} from "./Test02";


export const UIUXNumbersPage: FC = () => {

  useEffect(() => {
    document.title = 'UIUX - 数値入力';
  }, []);

  const [model, setModel] = useImmer<Record<string, string>>({
    test01        : '',
    test02        : '',
    test02realtime: '',
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {name, value}}) => {
    setModel(draft => void (draft[name] = value));
  }, []);


  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>デバッグ</Breadcrumb.Item>
          <Breadcrumb.Item active>UIUXチェック</Breadcrumb.Item>
          <Breadcrumb.Item active>数値入力</Breadcrumb.Item>
        </Breadcrumb>

        <Container>
          <Row>
            <Wrapper title={'#01'}>
              <Test01 precision={0}
                      name={'test01'}
                      value={model.test01}
                      onChange={handleChange}/>
            </Wrapper>

            <Wrapper title={'#02'}>
              <Test02 precision={0}
                      name={'test02'}
                      value={model.test02}
                      onChange={handleChange}/>
            </Wrapper>

            <Wrapper title={'#02+realtime'}>
              <Test02 precision={0}
                      name={'test02realtime'}
                      value={model.test02realtime}
                      onChange={handleChange}
                      realtime/>
            </Wrapper>
          </Row>
        </Container>
      </div>
  );
}

const Wrapper: FC<{ title: string, children: ReactNode }> = ({title, children}) => {
  return (
      <>
        <Col xs={2} md={2}
             className={'d-flex justify-content-end align-items-center'}>

          <label>{title}</label>
        </Col>
        <Col xs={10} md={4}>
          {children}
        </Col>
      </>
  )
}



