import React, {FC, useCallback} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {ScrollManager} from "backoffice/ui/Components/ScrollManager";
import {Layout} from "backoffice/ui/Layouts";
import {Navigate, Routes} from "react-router";
import {HomePage} from "backoffice/ui/Pages/Home";
import {LogoutPage} from "backoffice/ui/Pages/Logout";
import {LegacyPage} from "backoffice/ui/Pages/Legacy";
import {UsersPage} from "backoffice/ui/Pages/Etc/Users";
import {SQLViewPage} from "backoffice/ui/Pages/SQLView";
import {LoanManagerPage} from "backoffice/ui/Pages/LoanManager";
import {MembersPage} from "backoffice/ui/Pages/Members";
import {TestPage} from "backoffice/ui/Pages/Test";
import {RemoveTrailingSlash} from "backoffice/ui/Components";
import {AccountingPage} from "./Accounting";
import {BankingPage} from "backoffice/ui/Pages/Banking";

/**
 *
 */
export const Backoffice: FC = () => {

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    let target = event.target as HTMLInputElement;

    if (target.tagName !== 'INPUT' || target.type !== 'date') {
      return;
    }

    // 日付コピペ
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }

    switch (event.keyCode) {
      case 67: {
        // Copy
        navigator.clipboard.writeText(target.value);
        break;
      }

      case 86: {
        // Paste
        navigator.clipboard.readText().then(text => {
          if (Date.isValid(text)) {
            Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set?.call(target, new Date(text).toYmd());
          }
          target.dispatchEvent(new Event('input', {bubbles: true}));
        })
        break;
      }
    }
  }, []);

  return (
      <div onKeyDown={handleKeyDown}>
        <BrowserRouter>
          <RemoveTrailingSlash/>
          <ScrollManager/>

          <Routes>
            <Route path="/legacy" element={<LegacyPage/>}/>
            <Route path="/logout" element={<LogoutPage/>}/>

            <Route element={<Layout/>}>
              <Route path="/" element={<HomePage/>}/>
              <Route path="/accounting/*" element={<AccountingPage/>}/>
              <Route path="/banking/*" element={<BankingPage/>}/>
              <Route path="/members/*" element={<MembersPage/>}/>
              <Route path="/loan-manager/*" element={<LoanManagerPage/>}/>
              <Route path="/users/*" element={<UsersPage/>}/>
              <Route path="/sql-view/:category" element={<SQLViewPage/>}/>
              <Route path="/test/*" element={<TestPage/>}/>
              <Route path="*" element={<Navigate to="/" replace/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}
