import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {MembersNoticeMngDevicePage} from "backoffice/ui/Pages/Members/NoticeMng/Device";
import {MembersNoticeMngNotificationPage} from "backoffice/ui/Pages/Members/NoticeMng/Notification";

export const MembersNoticeMngPage: FC = () => {
  return (
      <Routes>
        <Route path="devices/*" element={<MembersNoticeMngDevicePage/>}/>
        <Route path="notifications/*" element={<MembersNoticeMngNotificationPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


