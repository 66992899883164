import Big from "big.js";
import {RawCreateParams, z, ZodType} from "zod";

/**
 * preprocess
 *
 * 入力がstringの場合、
 *  trimして
 *    emptyだった場合に undefined、
 *    emptyでなければ trimした文字列
 *
 * string以外の場合、そのまま返す
 *
 *
 * -----
 * ブラウザからのフォーム入力の際、基本的に文字列で受け付ける。
 *
 *   <input type="date"> などで入力させると、入力 -> 値のクリア をすると "" (空文字) になる。
 *   そのまま z_string_dateで受けると "Invalid date string" になってしまう。
 *   superjsonを使っているとはいえ、client側で Dateに変換 や undefinedへの置換などの制御を入れると、
 *   ChangeEventHandler を 入力値の種別毎に実装する必要が出てきてしまう。
 *
 */
export const z_prestring_ignore_empty = <T extends ZodType>(schema: T, params?: RawCreateParams) => {
  return z.preprocess(v => typeof v === "string" ? v.trim() || undefined : v, schema, params);
}


/**
 * string -> number
 */
export function z_string_int() {
  return z.string()
          .regex(/(\d)+/, 'Invalid number string')
          .transform(v => parseInt(v));
}

/**
 * string -> number optional
 */
export function z_string_int_optional() {
  return z_prestring_ignore_empty(z_string_int().optional());
}

/**
 * string -> Big
 */
export function z_string_number(precision: number = 0) {
  return z.string()
          .refine(v => v.toNumber(precision) !== null, 'Invalid number')
      // @ts-ignore refineでnullにならない確認済み
          .transform(v => new Big(v.toNumber(precision)));
}

/**
 * string -> Big optional
 */
export function z_string_number_optional(precision: number = 0) {
  return z_prestring_ignore_empty(z_string_number(precision).optional());
}

/**
 * string -> Date
 */
export function z_string_date(option?: { message?: string }) {
  return z.string()
          .refine(v => Date.isValid(v), option?.message ?? 'Invalid date string')
          .transform(v => new Date(v));
}

/**
 * string -> Date optional
 */
export function z_string_date_optional() {
  return z_prestring_ignore_empty(z_string_date().optional());
}

/**
 * Big.js
 */
export function z_big() {
  return z.any()
          .refine(v => v instanceof Big, 'Invalid parameter')
          .transform(v => v as Big);
}

/**
 * 選択
 * @param elems
 * @param error
 */
export function z_contains(elems: (string[] | number[]), error: string = "Invalid Selection") {
  const conv = typeof elems[0] === 'string' ? (v: any) => String(v)
                                            : (v: any) => Number(v);

  return z.union([z.string(), z.number()])
          .refine((val: string | number) => val === "" || elems.includes(val as never), error)
          .transform(val => val === "" ? null : conv(val));
}
