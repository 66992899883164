import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id     : 0,
    value  : "募集開始前（公開前）",
    sort   : 0,
    publish: false,
  }, {
    id     : 21,
    value  : "募集開始前",
    sort   : 1,
    publish: true,
  }, {
    id     : 1,
    value  : "募集中",
    sort   : 2,
    publish: true,
  }, {
    id     : 2,
    value  : "満額成立（バッチ処理待ち）",
    sort   : 3,
    publish: true,
  }, {
    id     : 3,
    value  : "成立",
    sort   : 4,
    publish: true,
  }, {
    id     : 4,
    value  : "深夜成立（メール送信待ち）",
    sort   : 5,
    publish: true,
  }, {
    id     : 10,
    value  : "運用開始前",
    sort   : 6,
    publish: true,
  }, {
    id     : 11,
    value  : "運用中",
    sort   : 7,
    publish: true,
  }, {
    id     : 19,
    value  : "運用終了",
    sort   : 8,
    publish: true,
  }, {
    id     : 20,
    value  : "募集停止",
    sort   : 9,
    publish: false,
  }, {
    id     : 95,
    value  : "深夜不成立（メール送信待ち）",
    sort   : 95,
    publish: true,
  }, {
    id     : 99,
    value  : "不成立",
    sort   : 99,
    publish: true,
  }
] as const;


/**
 * タイプ
 */
export type FundStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<FundStatus['value'], FundStatus>);


/**
 * 公開
 */
export const FundStatus = Object.assign(CodeClass, NameAccessor);
