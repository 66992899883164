import {FC} from "react";
import {Route} from "react-router-dom";
import {Routes} from "react-router";
import {UIUXPage} from "./UIUX";

export const TestPage: FC = () => {
  return (
      <Routes>
        <Route path="uiux/*" element={<UIUXPage/>}/>
      </Routes>
  );
}
