/**
 *
 */
export interface ByUser {
  id: number,
  name: string,
}

/**
 *
 * @param user
 */
export function userConv(user: ByUser | null | undefined): string | null {
  if (!user) {
    return null;
  }
  return `${user.id}: ${user.name}`;
}
