import {FC, FormEventHandler, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useImmer} from "use-immer";
import {FormText, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {CreateUserType} from "backoffice/api/AppRouter/User";
import {ValidationError} from "backoffice/api/ValidationError";


export const UserCreatePage: FC = () => {

  useEffect(() => {
    document.title = '新規作成';
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  // ステート
  const [model, setModel] = useImmer<CreateUserType>({email: '', name: ''}),
        [error, setError] = useState<ValidationError | null>(null);

  // 新規作成
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      setError(null);

      try {
        await trpc.user.create.mutate(model);

        Message.show('登録が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  };

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>ユーザー管理</Breadcrumb.Item>
          <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
        </Breadcrumb>

        <Form style={{marginTop: 50}} onSubmit={handleSubmit}>
          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <FormText type="email" placeholder="xxxx@crowd-sec.com"
                          value={model.email}
                          onChange={({currentTarget: {value}}) => setModel(draft => void (draft.email = value))}
                          required/>

                <Form.Text className="text-muted">
                  <small>
                    このアドレスにログインパスワードが送信されます。
                  </small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('email')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="username">
                <Form.Label>名称</Form.Label>
                <FormText placeholder="クラウド太郎"
                          value={model.name}
                          onChange={({currentTarget: {value}}) => setModel(draft => void (draft.name = value))}
                          required/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Button variant="primary" type="submit" className={'w-100'}>
                作成する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
  );
}


