import {AbstractCode} from "src/codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: "融資",
  }, {
    id   : 9,
    value: "融資（分配残）*外貨建ファンド用",
  }, {
    id   : 101,
    value: "元本返済",
  }, {
    id   : 109,
    value: "プール金（分配残）",
  }, {
    id   : 151,
    value: "貸倒金",
  }, {
    id   : 159,
    value: "雑益（貸倒金）",
  }, {
    id   : 201,
    value: "営業者報酬（利息）",
  }, {
    id   : 202,
    value: "投資家分配可能額（利息）",
  }, {
    id   : 209,
    value: "雑益（利息）",
  }, {
    id   : 211,
    value: "営業者報酬（遅損金）",
  }, {
    id   : 212,
    value: "投資家分配可能額（遅損金）",
  }, {
    id   : 219,
    value: "雑益（遅損金）",
  }, {
    id   : 221,
    value: "分配調整加算金",
  }, {
    id   : 401,
    value: "キャッシュバック",
  }, {
    id   : 411,
    value: "遅延損害金（営業者）",
  }
] as const;


/**
 * タイプ
 */
export type LoanTradeDividendType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キー アクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<LoanTradeDividendType['value'], LoanTradeDividendType>);


/**
 * 公開
 */
export const LoanTradeDividendType = Object.assign(CodeClass, NameAccessor);
