import {ChangeEventHandler, FC, ReactNode} from "react";
import {Form} from "react-bootstrap";

interface Props {
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  value?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  children?: ReactNode;
}


export const FormCheck: FC<Props> = (props) => {
  return (
      <Form.Check.Label className={'d-flex align-items-center px-3 py-2 border rounded bg-light'}
                        htmlFor={props.id}>
        <input id={props.id} type={'checkbox'} className={'form-check-input me-2'}
               name={props.name}
               checked={props.checked}
               onChange={props.onChange}
               disabled={props.disabled ?? false}/>
        {props.children}
      </Form.Check.Label>
  );
}
