import "../superjson";

let loaded: any = null;

export const config = async () => {
  if (loaded === null) {
    loaded = await (await fetch('/config.json')).json();
  }
  return loaded;
}
