import {FC, useEffect} from "react";
import {fetchAuthSession} from "aws-amplify/auth";
import {useLoader} from "backoffice/ui/Components";
import {config} from "backoffice/ui/config";

/**
 * 旧バージョンへのリダイレクト処理
 */
export const LegacyPage: FC = () => {

  const Loader = useLoader();

  useEffect(() => {
    Loader.show('旧バージョンのBackofficeサイトにリダイレクトしています');

    (async () => {

      const {LegacyURL} = await config();
      try {
        await Date.sleep(500);

        const path = (new URLSearchParams(window.location.search)).get('path') || '';
        const session = await fetchAuthSession();
        const accessToken = session!.tokens!.accessToken.toString();

        window.location.href = `${LegacyURL}/${path}?access_token=${accessToken}`;

      } catch (err) {
        debugger;
        console.error(err);

      } finally {
        Loader.hide();
      }

    })();
  }, [Loader]);

  return <></>;
}
