import {FC} from "react";

/**
 * トップページ
 */
export const HomePage: FC = () => {

  // 経過措置としてレガシーにリダイレクト
  // const Navigate = useNavigate();
  // Navigate('./legacy')
  return <div className={'container'}>Home</div>;
}
