import {AbstractCode, CodeType} from "src/codes/abstract";

/**
 * コードタイプ
 */
interface Type extends CodeType<number> {
}

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '融資',
    // dividend -> 1, 9
  }, {
    id   : 11,
    value: '利息',
    // dividend -> 201, 202, 209
  }, {
    id   : 12,
    value: '遅損金',
    // dividend -> 211, 212, 219
  }, {
    id   : 13,
    value: '手数料',
    // dividend -> none
  }, {
    id   : 14,
    value: '元本返済',
    // dividend -> 101, 109
  }, {
    id   : 111,
    value: '利息（営業者報酬なし）',
    // dividend -> 202, 209
  }, {
    id   : 112,
    value: '遅損金（営業者報酬なし）',
    // dividend -> 212, 219
  }, {
    id   : 201,
    value: '貸倒金',
    // dividend -> 151, 159
  }, {
    id   : 211,
    value: '為替差損',
    // dividend -> 161, 169
  }, {
    id   : 212,
    value: '為替差益',
    // dividend -> 232, 239
  }, {
    id   : 301,
    value: '代理受領分(IL)',
    // dividend -> none
  }, {
    id   : 302,
    value: '代理受領分(PC)',
    // dividend -> none
  }
] as const;


/**
 * タイプ
 */
export type LegacyLoanTradeType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キー アクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<LegacyLoanTradeType['value'], LegacyLoanTradeType>);


/**
 * 公開
 */
export const LegacyLoanTradeType = Object.assign(CodeClass, NameAccessor);
