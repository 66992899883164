import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {LoanManagerCustomerPage} from "./Customer";
import {LoanManagerLegacyTradePage} from "./LegacyTrade";
import {LoanExchangePage} from "./Exchange";
import {LoanManagerProjectPage} from "./Project";
import {LoanManagerTradePage} from "./Trade";


export const LoanManagerPage: FC = () => {
  return (
      <Routes>
        <Route path="customer/*" element={<LoanManagerCustomerPage/>}/>
        <Route path="project/*" element={<LoanManagerProjectPage/>}/>
        <Route path="exchange/*" element={<LoanExchangePage/>}/>
        <Route path="trade/*" element={<LoanManagerTradePage/>}/>
        <Route path="legacy-trade/*" element={<LoanManagerLegacyTradePage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


