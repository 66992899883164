import React, {FC, useEffect, useState} from "react";
import {useTRPC} from "backoffice/ui/App";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {ConfirmModal, FormCol, FormSelect, FormText, useLoader, useMessage} from "backoffice/ui/Components";
import {Helmet} from "react-helmet";
import type {AccountType} from "src/services/GmoAozoraNetBankAPI/types";

export const DepositPage: FC = () => {

  const Loader  = useLoader(),
        Message = useMessage(),
        tRPC    = useTRPC();

  const [raList, setRaList] = useState<AccountType[]>([]),
        [raId, setRaId]     = useState(''),
        [date, setDate]     = useState(''),
        [modal, setModal]   = useState('');

  // 口座情報
  useEffect(() => {
    Loader.task(tRPC.banking.gmoAozoraNet.accounts.query, 500)
          .then(([raList, raId]) => {
            setRaList(raList);
            setRaId(raId);
          })
          .catch(err => Message.error(err));
  }, [])

  const importDeposit = () => {
    Loader.task(async () => {
      try {
        await tRPC.banking.gmoAozoraNet.importDeposit.mutate({raId, date});

        setModal('');
        Message.show('取り込み処理が完了しました');

      } catch (err) {
        Message.error(err);
      }
    }, 500).then();
  }

  return (
      <>
        <Helmet>
          <title>入金取り込み GMOあおぞら銀行連携</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'./'}>GMOあおぞらネット銀行連携</Breadcrumb.Item>
            <Breadcrumb.Item active>入金取り込み</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'pt-5'}>
          <Row>
            <Col xs={{span: 12}}
                 md={{span: 8, offset: 2}}>
              <Container>
                <Row>
                  <Row>
                    <FormCol inputId={'raId'}
                             className={'py-1'}
                             labelColClassname={'text-end'}
                             title={'入金口座'}
                             size={{xs: [2, 10]}}>

                      <FormSelect value={raId}
                                  onChange={({currentTarget: {value}}) => setRaId(value)}>

                        {raId === "" && <option value="">-</option>}
                        {raList.map(el => (
                            <option key={el.accountId} value={el.accountId}>
                              {el.branchName} | {el.accountTypeName} | {el.accountNumber} | {el.accountName}
                            </option>
                        ))}
                      </FormSelect>
                    </FormCol>
                  </Row>

                  <FormCol inputId={'valueDate'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'取引日'}
                           size={{xs: [2, 8]}}>

                    <FormText type={'date'}
                              value={date}
                              onChange={({currentTarget: {value}}) => setDate(value)}
                              placeholder={''}/>
                  </FormCol>
                </Row>

                <Row className={'pt-5'}>
                  <Col className={'text-center'}>
                    <Button onClick={() => setModal('confirm')}
                            className={'w-50'}
                            disabled={date === ""}>
                      取り込み
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <ConfirmModal show={modal === 'confirm'}
                      confirmLabel={'取り込む'}
                      onConfirm={importDeposit}
                      onCancel={() => setModal('')}>

          {date} の入金レコードを取り込みますか？

        </ConfirmModal>
      </>
  );
}
