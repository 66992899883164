import React, {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row, Table} from "react-bootstrap";
import {FormCol, FormText, Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate, useParams} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {Link, useSearchParams} from "react-router-dom";
import {JournalSystem} from "src/codes/accounting";
import {Helmet} from "react-helmet";
import {useImmer} from "use-immer";
import {JournalListType} from "backoffice/api/AppRouter/Accounting/Journal";

const pageSize = 50;

const defaultCondition = {
  since: '',
  until: '',
}

export const AccountingJournalListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // システムID
  const {systemName, systemId}    = useParams(),
        [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState({total: 1, results: [] as JournalListType[]});


  // システム存在チェック
  if (systemName === undefined || !(JournalSystem.has(systemName))) {
    Message.show(`${systemName}は無効です`, () => {
      Navigate('..');
    });
  }

  useEffect(() => {
    if (systemName === undefined || systemId === undefined) {
      return;
    }


    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
            cond[k as keyof typeof defaultCondition] = query.get(k) || v;
            return cond;
          }, {} as typeof defaultCondition),
          page = Number(query.get('page')) || 1;

    setCondition(cond);


    Loader.task(async () => {
      try {


        // 検索結果をクラス化してセット
        const {total, results} = await trpc.accounting.journal.list.query({...cond, systemName, systemId, page, pageSize});
        setResult({total, results});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();

  }, [systemName, systemId, query])


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', String(new Date().getTime()));
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>仕訳 | {systemName}:{systemId}</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/accounting"}}>会計モジュール</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/accounting/journal"}}>仕訳</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `/accounting/journal/${systemName}`}} active>{systemName}</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `/accounting/journal/${systemName}/${systemId}`}} active>{systemId}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Form className={'container my-5'}>
          <Row>
            <FormCol inputId={'since'} className={'py-1'}
                     title={'期間開始'}
                     size={{xs: [4, 8], md: [2, 4]}}>
              <FormText value={condition.since}
                        type={'date'}
                        onChange={({target: {value}}) => setCondition(draft => void (draft.since = value))}/>
            </FormCol>

            <FormCol inputId={'until'} className={'py-1'}
                     title={'期間終了'}
                     size={{xs: [4, 8], md: [2, 4]}}>
              <FormText value={condition.until}
                        type={'date'}
                        onChange={({target: {value}}) => setCondition(draft => void (draft.until = value))}/>
            </FormCol>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
              <Button variant="primary"
                      className={'w-100'}
                      onClick={handleSearch}>
                検索する
              </Button>
            </Col>
          </Row>
        </Form>

        <div className={'container small'}>
          <Table bordered>
            <colgroup>
              <col span={1} style={{minWidth: '4rem'}}/>
              <col span={1} style={{minWidth: '7rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
            </colgroup>
            <thead>
            <tr>
              <th rowSpan={2} style={{textAlign: 'center'}}>ID</th>
              <th rowSpan={2} style={{textAlign: 'center'}}>日付</th>
              <th rowSpan={2} style={{textAlign: 'center'}}>摘要</th>
              <th colSpan={3} style={{textAlign: 'center'}}>借方</th>
              <th colSpan={3} style={{textAlign: 'center'}}>貸方</th>
            </tr>
            <tr>
              <th style={{textAlign: 'center'}}>勘定科目</th>
              <th style={{textAlign: 'center'}}>金額</th>
              <th style={{textAlign: 'center'}}>摘要</th>
              <th style={{textAlign: 'center'}}>勘定科目</th>
              <th style={{textAlign: 'center'}}>金額</th>
              <th style={{textAlign: 'center'}}>摘要</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.results.length === 0 && (
                <tr>
                  <td colSpan={9}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.results.map((journal, journalIndex) => (
                <React.Fragment key={journal.id}>
                  {journal.rows.map((row, rowIndex) => (
                      <tr key={rowIndex} className={`${(journalIndex % 2 === 0) && 'bg-light'}`}>
                        {/* ヘッダー部分 */}
                        {rowIndex === 0
                         ? <>
                           <td className={'text-end'}>{journal.id}</td>
                           <td className={'text-center'}>{journal.date.toYmd()}</td>
                           <td>
                             <TextDiv width={'10rem'}>
                               {journal.memo}
                             </TextDiv>
                           </td>
                         </>
                         : <>
                           <td>&nbsp;</td>
                           <td>&nbsp;</td>
                           <td>&nbsp;</td>
                         </>}

                        {/* 借方*/}
                        <td>{row.debit?.account?.value ?? ''}</td>
                        <td className={'text-end'}>{row.debit?.amount.toFixed(0).withComma ?? ''}</td>
                        <td>
                          <TextDiv width={'10rem'}>
                            {row.debit?.memo ?? ''}
                          </TextDiv>
                        </td>

                        {/* 貸方 */}
                        <td>{row.credit?.account?.value ?? ''}</td>
                        <td className={'text-end'}>{row.credit?.amount.toFixed(0).withComma ?? ''}</td>
                        <td>
                          <TextDiv width={'10rem'}>
                            {row.credit?.memo ?? ''}
                          </TextDiv>
                        </td>
                      </tr>
                  ))}
                </React.Fragment>
            ))}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>
      </>
  );
}
