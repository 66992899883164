import React, {FC, useEffect, useState} from "react";
import {useTRPC} from "backoffice/ui/App";
import {useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";

export const CallbackPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();


  // ステート
  const [state, setState] = useState('初期化中');


  useEffect(() => {

    Loader.task(async () => {

      // ./Auth.tsxで取得したnonce
      const nonce = localStorage.getItem('gmo-aozora-net-auth-nonce') ?? '';

      // コード登録 App.tsx useEffectにて cognitoのOAuth実行前にチェックを行っている
      const code = localStorage.getItem('gmo-aozora-net-auth-code') ?? '';
      try {
        await tRPC.banking.gmoAozoraNet.token.mutate({nonce, code});
        setState('トークンの取得に成功しました');

      } catch (err) {
        setState('トークンの取得に失敗しました');

      } finally {
        localStorage.removeItem('gmo-aozora-net-auth-nonce');
        localStorage.removeItem('gmo-aozora-net-auth-code')
      }
    });


  }, []);


  return (
      <>
        <Helmet>
          <title>コールバック GMOあおぞら銀行連携</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'./'}>GMOあおぞら銀行連携</Breadcrumb.Item>
            <Breadcrumb.Item active>認証コールバック</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'pt-5'}>
          <Row>
            <Col xs={{span: 12}}
                 md={{span: 8, offset: 2}}>
              <Container>
                <Row className={'mt-5'}>
                  <Col>
                    {state}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
  );
}
