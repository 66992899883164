export {}

// new Date()の拡張は将来的に不安なので廃止
// // @ts-ignore
// Date = class extends Date {
//   constructor(...args: any[]) {
//     if (arguments.length == 0) {
//       // @ts-ignore
//       return super();
//
//     } else if (arguments.length === 1) {
//       if (typeof args[0] === 'string') {
//         const [ymd, time] = args[0].trim().split(' ');
//         let tmp           = (time ?? '0:0:0:0').split(':').concat(['0', '0', '0']).splice(0, 4);
//
//         // @ts-ignore
//         return super(`${ymd} ${tmp.map(el => Number(el)).join(':')}`);
//       }
//
//       // @ts-ignore
//       return super(args[0]);
//     }
//
//     // @ts-ignore
//     return super(...args);
//   }
// };


declare global {

  interface DateConstructor {

    /**
     *
     * @param msec 待機時間（ミリ秒）
     */
    sleep: (msec: number) => Promise<null>;

    /**
     * {task}を実行して{msec}ミリ秒より早く終了した場合、{mse}cミリ秒経過するまでスリープ
     *
     * @param task 処理
     * @param msec 待機時間（ミリ秒）
     */
    duty: <T> (task: () => T, msec: number) => Promise<T>;

    /**
     * Dateに変換できなければundefinedを返す
     *
     * @param input
     */
    optional: (input: string) => Date | undefined;

    /**
     *
     * @param string
     */
    isValid: (string: string) => boolean;

    max: (...dates: (Date | string | null | undefined)[]) => Date | null;

    min: (...dates: (Date | string | null | undefined)[]) => Date | null;

    days: (start: Date | string, end: Date | string) => number;

    get SEC(): number;

    get MIN(): number;

    get HOUR(): number;

    get DAY(): number;
  }
}

/**
 *
 * @param msec
 */
Date.sleep = (msec) => new Promise(resolve => setTimeout(resolve, msec));

/**
 *
 * @param task
 * @param msec
 */
Date.duty = async (task, msec) => {
  const start   = Date.now(),
        result  = await task(),
        elapsed = Date.now() - start;

  if (elapsed < msec) {
    await Date.sleep(msec - elapsed);
  }
  return result;
}

/**
 *
 * @param input
 */
Date.optional = (input: string) => {
  if (input) {
    return new Date(input);
  }
  return undefined;
}

/**
 *
 * @param string
 */
Date.isValid = string => new Date(string).toDateString() !== 'Invalid Date';

/**
 *
 * @param dates
 */
Date.max = (...dates) => {
  return dates.reduce((ret, elem) => {
    if (elem === null || elem === undefined) {
      return ret;
    }
    // @ts-ignore
    return (ret < elem) ? elem : ret;
  }, null) as (Date | null);
}

/**
 *
 * @param dates
 */
Date.min = (...dates) => {
  return dates.reduce((ret, elem) => {
    if (elem === null || elem === undefined) {
      return ret;
    }
    if (typeof elem === "string") {
      elem = new Date(elem);
    }

    if (ret === null) {
      return elem;
    }

    // @ts-ignore
    return (elem < ret) ? elem : ret;
  }, null) as (Date | null);
}


Date.days = (start: Date | string, end: Date | string) => {
  if (typeof start === 'string') {
    start = new Date(start);
  }
  if (typeof end === 'string') {
    end = new Date(end);
  }

  start.setHms([0, 0, 0, 0]);
  end.setHms([0, 0, 0, 0]);

  return (end.getTime() - start.getTime()) / Date.DAY;
}

Object.defineProperty(Date, 'SEC', {
  get: () => 1000
});

Object.defineProperty(Date, 'MIN', {
  get: () => 60 * 1000
});

Object.defineProperty(Date, 'HOUR', {
  get: () => 60 * 60 * 1000
});

Object.defineProperty(Date, 'DAY', {
  get: () => 24 * 60 * 60 * 1000
});
