import {FC,} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {LoanExchangeListPage} from "./List";
import {LoanExchangeFundCreateFormPage} from "./CreateForm/Fund";
import {LoanExchangeTradeCreateFormPage} from "./CreateForm/Trade";
import {LoanExchangeFundFormPage} from "./Form/Fund";
import {LoanExchangeTradeFormPage} from "./Form/Trade";

export const LoanExchangePage: FC = () => {
  return (
      <Routes>
        <Route index element={<LoanExchangeListPage/>}/>
        <Route path="fund/new" element={<LoanExchangeFundCreateFormPage/>}/>
        <Route path="fund/:id" element={<LoanExchangeFundFormPage/>}/>
        <Route path="trade/new" element={<LoanExchangeTradeCreateFormPage/>}/>
        <Route path="trade/:id" element={<LoanExchangeTradeFormPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}
