import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {UserListPage} from "./List";
import {UserCreatePage} from "./Create";
import {UserEditPage} from "./Edit";
import {RolesPage} from "./Roles";

export const UsersPage: FC = () => {
  return (
      <Routes>
        <Route path="/roles/*" element={<RolesPage/>}/>

        <Route index element={<UserListPage/>}/>
        <Route path="create" element={<UserCreatePage/>}/>
        <Route path=":userId/edit" element={<UserEditPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


