import {ChangeEventHandler, FC, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, OverlayTrigger, Row, Tab, Tabs, Tooltip} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useImmer} from "use-immer";
import {Footer, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import type {LoanManager} from "backoffice/api/RDS";
import {ModelObjectSlim, Overwrite} from "backoffice/api/types";
import {Basic} from "./Basic";
import {Conditions} from "./Conditions";
import {v4 as uuid} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMoneyBillAlt, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";
import {ValidationError} from "backoffice/api/ValidationError";
import type {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";
import {Info} from "backoffice/ui/Pages/LoanManager/Project/Form/Info";


/**
 * 編集用モデル定義
 */
export type FormModel = Partial<Overwrite<ModelObjectSlim<LoanManager.Project>, {
  amount: string
  customer: ModelObjectSlim<LoanManager.Customer> | undefined

  conditions: Partial<Overwrite<ModelObjectSlim<LoanManager.ProjectCondition>, {
    uuid?: string,
    deleted?: boolean,

    since: string,
    until: string,
    amount: string;
    management_fee_rate: string;
    interest_rate: string;
    loan_fee_rate: string;
    total_rate: string;
    delay_management_fee_rate: string;
    delay_interest_rate: string;
    delay_loan_fee_rate: string;
    delay_total_rate: string;
  }>>[]
}>>


export const LoanManagerProjectFormPage: FC = () => {

  // パラメータ
  const {projectId} = useParams(),
        isNew       = projectId === 'new';

  useEffect(() => {

    if (isNew) {
      setModel({name: ''});

    } else if (Number(projectId)) {
      doGet().then();

    } else {
      Message.show('不正なIDが指定されました', () => {
        Navigate('..');
      })
    }
  }, [projectId]);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  // ステート
  const [model, setModel]   = useImmer<FormModel>({
          name      : '',
          monitoring: '資金使途どおりの費消を確認済み',
        }),
        [error, setError]   = useState<ValidationError | null>(null),
        [tabKey, setTabKey] = useState<string | null>('basic');

  // サーバーから取得したモデルをセット
  const setServerModel = (model: LoanProjectType) => {
    setTabKey('basic');

    setModel({
      ...model,
      amount    : model.amount?.toString(),
      customer  : model.customer,
      conditions: model.conditions?.map(cond => {
        return {
          ...cond,
          since                    : cond.since?.toYmd(),
          until                    : cond.until?.toYmd(),
          amount                   : cond.amount?.toString(),
          management_fee_rate      : cond.management_fee_rate?.toString() ?? '',
          interest_rate            : cond.interest_rate?.toString() ?? '',
          loan_fee_rate            : cond.loan_fee_rate?.toString() ?? '',
          total_rate               : cond.total_rate?.toString() ?? '',
          delay_management_fee_rate: cond.delay_management_fee_rate?.toString() ?? '',
          delay_interest_rate      : cond.delay_interest_rate?.toString() ?? '',
          delay_loan_fee_rate      : cond.delay_loan_fee_rate?.toString() ?? '',
          delay_total_rate         : cond.delay_total_rate?.toString() ?? '',
          deleted                  : false,
        }
      })
    });
  }

  // 条件追加
  const addCondition = () => {
    setModel(draft => {
      if (!draft.conditions) {
        draft.conditions = []
      }

      draft.conditions.push({
        uuid                     : uuid(),
        since                    : '',
        until                    : '',
        amount                   : '',
        management_fee_rate      : '',
        interest_rate            : '',
        loan_fee_rate            : '',
        total_rate               : '',
        delay_management_fee_rate: '',
        delay_interest_rate      : '',
        delay_loan_fee_rate      : '',
        delay_total_rate         : '',
        deleted                  : false,
      });
    });
  }

  // 読み込み
  const doGet: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      if (!projectId) {
        return
      }

      try {
        let model = await trpc.loan.project.get.query(Number(projectId));
        setServerModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [projectId]);


  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = useCallback(({
    currentTarget: {name, value}
  }) => {
    // @ts-ignore サーバー側でチェックするので、クライアント側ではタイプチェックをしない
    setModel(draft => void (draft[name] = value))
  }, [setModel]);


  // 保存 サーバー側でチェックするので、クライアント側ではタイプチェックをしない
  const handleSubmit = async () => {
    Loader.task(async () => {
      if (!model) {
        return;
      }

      // エラーを解除
      setError(null);

      try {
        const postModel = {
          ...model,

          conditions: model.conditions?.map(cond => ({
            ...cond,
            since: new Date(cond.since!),
            until: new Date(cond.until!),
          }))
        }

        // 新規作成
        if (isNew) {
          const response = await trpc.loan.project.save.mutate(postModel as any);

          Message.show('登録が完了しました', () => {
            Navigate(`../${response.id}`);
          });
          return;
        }

        // 更新
        const response = await trpc.loan.project.save.mutate({id: Number(projectId), ...postModel} as any);

        Message.show('更新が完了しました', () => {
          setServerModel(response);
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  return (
      <>
        <Helmet>
          <title>{isNew ? '新規作成' : `編集`}</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>融資案件一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>{isNew ? '新規作成' : `編集 #${model.id}`}</Breadcrumb.Item>
          </Breadcrumb>

          <Row className={'mt-5'}>
            <Col xl={{offset: 2, span: 8}}>
              <Form>
                <input type='submit' className={'d-none'} disabled/>

                <Tabs activeKey={tabKey || "basic"} onSelect={key => setTabKey(key)}>
                  <Tab eventKey={"basic"} title={"基本情報"} className={'m-3'}>
                    <Basic {...{model, setModel, handleChange, error}}/>
                  </Tab>
                  <Tab eventKey={"conditions"} title={"融資条件"} className={'m-3'}>
                    <Conditions {...{model, setModel, handleChange, error}}/>
                  </Tab>

                  {!isNew && (
                      <Tab eventKey={"info"} title={'その他情報'} className={'m-3'}>
                        <Info {...{model}}/>
                      </Tab>
                  )}
                </Tabs>
              </Form>
            </Col>
          </Row>

          {!isNew && (
              <Row>
                <Col xl={{span: 8, offset: 2}} className={'text-end'}>
                  <Form.Text className="text-muted">
                    <small>
                      <>最終更新日時: {model.update_time?.toYmd_hms() ?? model.create_time?.toYmd_hms()}</>
                    </small>
                  </Form.Text>
                </Col>
              </Row>
          )}
        </div>

        <Footer>
          {!isNew && (
              <div className={'m-2'}>
                <OverlayTrigger placement={'top'} overlay={<Tooltip>案件詳細</Tooltip>}>
                  <div className={'btn btn-secondary btn-sm disabled me-2'}>
                    <FontAwesomeIcon icon={faEdit}/>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger placement={'top'}
                                overlay={<Tooltip>アロケーション</Tooltip>}>
                  <Link to={`./allocation`} className={'btn btn-info btn-sm me-2'}>
                    <FontAwesomeIcon icon={faSlidersH}/>
                  </Link>
                </OverlayTrigger>

                <OverlayTrigger placement={'top'}
                                overlay={<Tooltip>融資/返済</Tooltip>}>
                  <Link to={`./trade`} className={'btn btn-info btn-sm me-2'}>
                    <FontAwesomeIcon icon={faMoneyBillAlt}/>
                  </Link>
                </OverlayTrigger>
              </div>
          )}

          <Button variant="secondary" className={'m-2'} style={{minWidth: 100}} size={'sm'}
                  onClick={() => {
                    setTabKey('conditions');
                    addCondition();
                  }}>
            条件追加
          </Button>

          <Button variant="primary" className={'m-2'} style={{minWidth: 100}} size={'sm'}
                  onClick={handleSubmit}>
            保存
          </Button>
        </Footer>
      </>
  );
}


