import {FormEventHandler, useCallback, useEffect} from 'react';
import {useNavigate} from "react-router";
import {Link} from 'react-router-dom';
import {Button, Col, Form, Row} from "react-bootstrap";
import {signIn, signOut} from 'aws-amplify/auth'
import {FormPassword, FormText, useLoader, useMessage} from "backoffice/ui/Components";
import {Helmet} from 'react-helmet';


export const LoginPage = () => {

  const navigate     = useNavigate(),
        MessageModal = useMessage(),
        Loader       = useLoader();


  useEffect(() => {
    signOut();
  }, []);

  // フォームサブミット
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    const form: HTMLFormElement = event.currentTarget;

    // 入力値チェック
    if (!form.checkValidity()) {
      return MessageModal.show('エラー', '入力内容をご確認ください');
    }

    const username = form.username.value,
          password = form.password.value;

    try {
      const {isSignedIn, nextStep} = await Loader.task(
          '通信中です',
          async () => await signIn({username, password}),
          500);

      //パスワードの変更要求があった場合
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate(`/new-password-required/${username}`);
      }

      // ログイン成功
      else if (isSignedIn) {

        // ログイン成功（リロード）
        const searchParams   = new URLSearchParams(window.location.search)
        window.location.href = searchParams.get('path') || '/';
      }

    } catch (err) {
      // @ts-ignore
      MessageModal.show('エラー', err.message);
    }
  }, []);

  return (
      <>
        <Helmet>
          <title>ログイン｜backoffice</title>
        </Helmet>

        <Form onSubmit={handleSubmit} style={{marginTop: 150}}>
          <Row style={{marginBottom: 50}}>
            <Col className="text-center">
              <big>ログインページ</big>
            </Col>
          </Row>

          <Row>
            <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
              <Form.Group controlId="email">
                <FormText name="username" type="text" placeholder="ユーザー名" required/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
              <Form.Group controlId="passwd">
                <FormPassword name="password" placeholder="パスワード" required/>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{marginTop: 20}}>
            <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
              <Button variant="dark" type="submit" className={'w-100'}>ログイン</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={{span: 4, offset: 4}}>
              <p style={{marginTop: 50}}>
                パスワードをお忘れの場合は<Link to={'/reset-password'}>こちら</Link>からパスワードの再発行を行ってください。
              </p>
            </Col>
          </Row>
        </Form>
      </>
  );
};
