import React, {FC, KeyboardEventHandler, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {useImmer} from "use-immer";
import {ConfirmModal, Footer, FormCol, FormNumber, FormText, Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import type {ModelObjectRCSV} from "backoffice/api/types";
import {LoanTradeStatus, LoanTradeType} from "src/codes";
import Big from "big.js";
import {Helmet} from "react-helmet"
import type {LoanManager} from "backoffice/api/RDS";
import {Link, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useImmerChangeHandler} from "backoffice/ui/utils/react/hooks";

import "./index.scss";

// ページサイズ
const pageSize = 50;

// 先月１日
const since = new Date();
since.setDate(1);

// 検索条件 初期値
const defaultCondition = {
  customer: '',
  project : '',
  since   : since.toYmd(),
  until   : '',
} as Record<string, any>;

export const LoanManagerTradeListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState({total: 1, list: [] as ModelObjectRCSV<LoanManager.Trade>[]}),
        [selection, setSelection] = useState([] as number[]),
        [modal, setModal]         = useState('');

  // 初期化
  useEffect(() => {
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
            cond[k as keyof typeof defaultCondition] = query.get(k) || v;
            return cond;
          }, {} as typeof defaultCondition),
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        const req = {...cond, page, pageSize};

        // @ts-ignore チェックはサーバーサイドで実行
        const {total, list} = await trpc.loan.trade.list.query(req);
        setResult({total, list});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);

  // 検索条件 編集
  const handleChange = useImmerChangeHandler(setCondition);

  // エンターキー入力
  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.key === 'Enter' && handleSearch();
  };

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    setQuery(query);
  }


  // 承認
  const handleConfirm = () => {
    Loader.task(async () => {
      for (let index = 0; index < result.list.length; index++) {
        const trade = result.list[index];
        if (!selection.includes(trade.id)) {
          continue;
        }

        try {
          const updated = await trpc.loan.trade.confirm.mutate({id: trade.id, timestamp: trade.timestamp!});
          if (updated) {
            setResult(({total, list}) => ({total, list: list.toSpliced(index, 1, updated)}));
            setSelection(prev => prev.filter(el => el !== trade.id));
          }

        } catch (err) {
          Message.error(err);
          break;
        }
      }
    }, 500);
  }

  // 選択合計
  const [total, confirmable] = useMemo(() => {
    return result.list.reduce(([total, confirmable], trade) => {
      if (!selection.includes(trade.id)) {
        return [total, confirmable];
      }

      const _total = trade.details.reduce((total, detail) => {
        return total.add(detail.amount ?? 0);
      }, total);

      return [_total, confirmable && trade.statusId === LoanTradeStatus['実績（承認待ち）'].id];
    }, [new Big(0), true]);
  }, [selection]);


  return (
      <>
        <Helmet>
          <title>融資取引一覧</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item active>取引一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Form className={'my-5'}>
                <input type='submit' className={'d-none'} disabled/>

                <Row>
                  <FormCol inputId={'customer'} className={'py-1'}
                           title={'融資先名称'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText name={'customer'}
                              value={condition.customer}
                              onChange={handleChange}
                              onKeyDown={handleEnter}
                              placeholder={'融資先名称'}/>
                  </FormCol>

                  <FormCol inputId={'project'} className={'py-1'}
                           title={'案件名称'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText name={'project'}
                              value={condition.project}
                              onChange={handleChange}
                              onKeyDown={handleEnter}
                              placeholder={'案件名称'}/>
                  </FormCol>
                </Row>
                <Row>
                  <FormCol inputId={'since'} className={'py-1'}
                           title={'取引日 期間開始'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText type={'date'}
                              name={'since'}
                              value={condition.since}
                              onChange={handleChange}
                              onKeyDown={handleEnter}/>
                  </FormCol>

                  <FormCol inputId={'until'} className={'py-1'}
                           title={'取引日 期間終了'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText type={'date'}
                              name={'until'}
                              value={condition.until}
                              onChange={handleChange}
                              onKeyDown={handleEnter}/>
                  </FormCol>
                </Row>

                <Row style={{marginTop: 20}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'}
                            onClick={handleSearch}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <div className={'container small'}>
          <Table responsive>
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th className={'text-end'}>
                案件ID
              </th>
              <th className={'text-center'}>
                融資先 / 案件名称
              </th>
              <th className={'text-center'}>
                取引日 / 進捗
              </th>
              <th className={'text-center'} style={{minWidth: '14em'}}>実績日 / 計算期間</th>
              <th className={'text-center'}>種別</th>
              <th className={'text-center'} style={{width: '5em'}}>通貨</th>
              <th className={'text-end'}>金額</th>
              <th>メモ</th>
              <th>&nbsp;</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.list.length === 0 && (
                <tr>
                  <td className={'text-center align-middle'}
                      colSpan={10}>
                    no data
                  </td>
                </tr>
            )}

            {/* データあり */}
            {result.list.map(trade => {
              const checked = selection.includes(trade.id),
                    bgColor = checked ? 'confirming'
                                      : trade.isPlan ? 'plan'
                                                     : trade.isConfirmReady ? 'confirmReady'
                                                                            : '';

              return (
                  <React.Fragment key={`${trade.id}-${trade.statusId}`}>
                    <tr className={bgColor}>
                      <td rowSpan={trade.details.length}
                          className={'cursor-pointer text-center align-middle'}
                          style={{width: '3em'}}
                          onClick={trade.isConfirmReady ? () => setSelection(prev => prev.toggle(trade.id))
                                                        : () => null}>
                        <input type={'checkbox'}
                               checked={checked}
                               disabled={!trade.isConfirmReady}
                               readOnly/>
                      </td>
                      <td rowSpan={trade.details.length}
                          className={'cursor-pointer text-end align-middle'}
                          style={{width: '5em'}}>
                        {trade.loanProjectId}
                      </td>
                      <td className={'align-middle'}
                          rowSpan={trade.details.length}
                          style={{width: '15em'}}>
                        <TextDiv width={'15em'} placement={'top'}>{trade.project.customer.name}</TextDiv>
                        <TextDiv width={'15em'} placement={'bottom'}>{trade.project.name}</TextDiv>
                      </td>
                      <td rowSpan={trade.details.length}
                          className={'text-center align-middle'}
                          style={{width: '10em'}}>
                        {trade.processDate?.toYmd() ?? '-'}<br/>
                        <TextDiv width={'10em'} placement={'bottom'}>{LoanTradeStatus.find(trade.statusId)?.value || '-'}</TextDiv>
                      </td>
                      <TradeRow trade={trade} detail={trade.details[0]}/>

                      <td rowSpan={trade.details.length}
                          className={'align-middle'}
                          style={{whiteSpace: 'nowrap', width: 0}}>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>編集</Tooltip>}>
                          <Link to={`../project/${trade.loanProjectId}/trade/${trade.id}`} className={'btn btn-secondary btn-sm'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </OverlayTrigger>
                      </td>

                    </tr>

                    {trade.details.slice(1).map(detail => (
                        <tr key={`${detail.id}`} className={bgColor}>
                          <TradeRow trade={trade} detail={detail}/>
                        </tr>
                    ))}
                  </React.Fragment>
              )
            })}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>

        {/* フッター */}
        <Footer className={`py-2`}>
          <div style={{width: '15em'}}>
            <FormNumber align={'end'}
                        value={total.toString()}
                        precision={total.toString().split('.')[1]?.length || 0}
                        readOnly/>
          </div>

          <Button className={'btn-primary mx-2'}
                  size={'sm'}
                  style={{width: '10em'}}
                  disabled={selection.length === 0 || !confirmable}
                  onClick={() => setModal('confirm')}>
            承認
          </Button>
        </Footer>

        <ConfirmModal show={modal === 'confirm'}
                      onConfirm={() => {
                        setModal('');
                        handleConfirm()
                      }}
                      onCancel={() => setModal('')}
                      confirmLabel={'承認する'}
                      keyword={'承認する'}>
          {selection.length}件の取引を承認しますか？
        </ConfirmModal>
      </>
  );
}

const TradeRow: FC<{ trade: ModelObjectRCSV<LoanManager.Trade>, detail: ModelObjectRCSV<LoanManager.TradeDetail> }> = ({trade, detail}) => {
  const currency  = trade.project.currency,
        tradeType = LoanTradeType.find(detail.typeId);
  return (
      <>
        {tradeType.dateType === "single" && (
            <td className={'text-center'} style={{width: '16em'}}>{detail?.since?.toYmd() ?? '-'}</td>
        )}
        {tradeType.dateType === "single" || (
            <td className={'text-center'} style={{width: '16em'}}>{detail?.since?.toYmd() ?? '-'} ~ {detail?.until?.toYmd() ?? '-'}</td>
        )}
        <td className={'text-center'} style={{width: '10em'}}>{LoanTradeType.find(detail?.typeId)?.value}</td>
        <td className={'text-center'} style={{width: '5em'}}>{currency?.value ?? '-'}</td>
        <td className={'text-end'} style={{width: '9em'}}>{detail.amount?.toFixed(currency?.precision ?? 0).withComma ?? '0'}</td>
        <td>{detail?.memo}</td>
      </>
  );
}
