import React, {FC} from "react";
import {Route} from "react-router-dom";
import {Routes} from "react-router";
import {GmoAozoraNetPage} from "./GmoAozoraNet";

export const BankingPage: FC = () => {
  return (
      <Routes>
        <Route path="gmo-aozora-net/*" element={<GmoAozoraNetPage/>}/>
      </Routes>
  );
}
