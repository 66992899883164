import {BrowserRouter, Route} from 'react-router-dom'
import {Navigate, Routes} from "react-router";
import {LoginPage} from "./Login";
import {ResetPasswordPage} from "./ResetPassword";
import {NewPasswordRequiredPage} from "backoffice/ui/Pages/Auth/NewPasswordRequired";

export const AuthPage = () => {
  let loginPath = '/';
  if (location.pathname !== '/') {
    loginPath = `/?path=${encodeURI(`${location.pathname}${location.search}`)}`
  }

  return (
      <div style={{padding: 10}}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage/>}/>
            <Route path="/new-password-required/:username" element={<NewPasswordRequiredPage/>}/>
            <Route path="/reset-password" element={<ResetPasswordPage/>}/>
            <Route path="*" element={<Navigate to={loginPath} replace/>}/>
          </Routes>
        </BrowserRouter>
      </div>
  );
};
