import {Currency} from "src/codes";
import {FormCol, FormText} from "backoffice/ui/Components";
import {FC} from "react";
import {Form, Row} from "react-bootstrap";
import {useImmer} from "use-immer";
import {FormModel} from "backoffice/ui/Pages/LoanManager/Project/Form/index";


interface Props {
  model: ReturnType<typeof useImmer<FormModel>>[0],
}

export const Info: FC<Props> = ({model}) => {


  // 通貨桁数
  const currencyPrecision = Currency.find(model.currencyId)?.precision ?? 0;
  return (
      <>
        <Row>
          <FormCol inputId={'loaned'}
                   title={'累計 融資実行額'}
                   className={'py-1'}
                   size={{xs: [4, 8], md: [2, 10]}}>

            <FormText value={model?.loaned?.toFixed(currencyPrecision).withComma ?? '-'}
                      readOnly/>
          </FormCol>
        </Row>

        <Row>
          <FormCol inputId={'repaid'}
                   title={'累計 返済額'}
                   className={'py-1'}
                   size={{xs: [4, 8], md: [2, 10]}}>

            <FormText value={model?.repaid?.toFixed(currencyPrecision).withComma ?? '-'}
                      readOnly/>
          </FormCol>
        </Row>

        <Row>
          <FormCol inputId={'pooled'}
                   title={'プール金（現時点）'}
                   className={'py-1'}
                   size={{xs: [4, 8], md: [2, 10]}}>

            <FormText value={model?.pooled?.toFixed(currencyPrecision).withComma ?? '-'}
                      readOnly/>
          </FormCol>
        </Row>

        <Row>
          <FormCol inputId={'latestTradeId'}
                   title={'最新取引ID'}
                   className={'py-1'}
                   size={{xs: [4, 8], md: [2, 10]}}>

            <FormText value={model?.latestTradeId ?? '-'}
                      readOnly/>

            <div>
              <Form.Text className="small">
                ロールバック可能な取引ID
              </Form.Text>
            </div>
          </FormCol>
        </Row>

        <Row>
          <FormCol inputId={'latestTradeDate'}
                   title={'最新取引日'}
                   className={'py-1'}
                   size={{xs: [4, 8], md: [2, 10]}}>

            <FormText value={model?.latestTradeDate?.toYmd() ?? '-'}
                      readOnly/>

            <div>
              <Form.Text className="small">
                この日付以前のアロケーション操作、融資取引の入力はできません
              </Form.Text>
            </div>
          </FormCol>
        </Row>
      </>
  );
}
