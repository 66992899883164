import {AbstractCode} from "./abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id    : 1,
    value : '日本円 → 米ドル',
    symbol: 'USD/JPY',
  },
  {
    id    : 2,
    value : '米ドル → 日本円',
    symbol: 'USD/JPY',
  },
  {
    id    : 3,
    value : '日本円 → 豪ドル',
    symbol: 'AUD/JPY',
  },
  {
    id    : 4,
    value : '豪ドル → 日本円',
    symbol: 'AUD/JPY',
  },
] as const;


/**
 * タイプ
 */
export type CurrencyPair = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<CurrencyPair['value'], CurrencyPair>);


/**
 * 公開
 */
export const CurrencyPair = Object.assign(CodeClass, NameAccessor);
