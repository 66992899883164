import {AbstractCode} from "../abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id     : 1,
    value  : 'インキュラボ',
    key    : 'IL',
    name   : 'クラウドバンク・インキュラボ株式会社',
    address: '〒106-0032 東京都港区六本木7-15-7 新六本木ビル 6F',
    corpNo : '5010001079474'
  },
  {
    id     : 2,
    value  : 'フィナンシャル',
    key    : 'FS',
    name   : 'クラウドバンク・フィナンシャルサービス株式会社',
    address: '〒106-0032 東京都港区六本木7-15-7 新六本木ビル 6F',
    corpNo : '9010401116749'
  },
  {
    id     : 3,
    value  : 'キャピタル',
    key    : 'CBC',
    name   : 'クラウドバンク・キャピタル株式会社',
    address: '〒106-0032 東京都港区六本木7-15-7 新六本木ビル 6F',
    corpNo : '------------'
  },
] as const;


/**
 * タイプ
 */
export type LoanManager = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.key] = elem;
  return result;
}, {} as Record<LoanManager['key'], LoanManager>);


/**
 * 公開
 */
export const LoanManager = Object.assign(CodeClass, NameAccessor);
