import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {MembersTradesListPage} from "./List";

export const MembersTradesPage: FC = () => {
  return (
      <Routes>
        <Route index element={<MembersTradesListPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


