import {useTRPC} from "backoffice/ui/App";
import {ConfirmModal, Footer, FormCol, FormSelect, FormText, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {ChangeEventHandler, FC, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Container, Row, Table} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Currency, LoanManager} from "src/codes";
import Big from "big.js";
import type {ExchangeTrade} from "backoffice/api/AppRouter/Loan/Exchange/Trade";
import {useSearchParams} from "react-router-dom";


export const LoanExchangeTradeCreateFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const [query, setQuery]             = useSearchParams(),
        [requestDate, setRequestDate] = useState(''),
        [targets, setTargets]         = useState([] as ExchangeTrade[]),
        [selected, setSelected]       = useState([] as string[]),
        [modal, setModal]             = useState('');

  const currencyId = query.get('currencyId') ?? '',
        managerId  = query.get('managerId') ?? '',
        debug      = query.get('debug') ?? '';

  useEffect(() => {
    if (currencyId) {
      Loader.task(tRPC.loan.exchange.trade.targets.query({currencyId, managerId}), 500)
            .then(targets => setTargets(targets))
            .catch(err => Message.error(err));
    }
  }, [currencyId, managerId])


  const handleChange: ChangeEventHandler<HTMLSelectElement> = useCallback(({currentTarget: {name, value}}) => {
    setQuery(q => {
      if (value) {
        q.set(name, value);
      } else {
        q.delete(name)
      }
      return q;
    });
  }, [setQuery]);


  const handleCreate = () => {
    const proc = tRPC.loan.exchange.trade.create.mutate({
      currencyId,
      executeDateReq: new Date(requestDate),
      targetIds     : selected,
    });

    Loader.task(proc, 500)
          .then(taskId => Navigate(`../${taskId}`, {relative: "path"}))
          .catch(err => Message.error(err));
  };

  const selectedManagerId = (selected.length === 0) ? undefined
                                                    : targets.find(el => selected.includes(el.id))!.manager.id;


  // 選択合計金額
  const selectedTotal = targets.reduce((total, elem) => {
    if (selected.includes(String(elem.id))) {
      return total.add(elem.amount);
    }
    return total;
  }, Big(0)).toString().withComma;

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item href={'../'}>両替一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>{`新規返済両替登録`}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'mt-5 small'}>
          <Row>
            <Col xl={{offset: 1}}>
              <Container>
                <Row>
                  <FormCol inputId={'currencyId'}
                           className={'py-1'}
                           title={'通貨'}
                           size={{xs: [4, 8], lg: [2, 4]}}>

                    <FormSelect name='currencyId'
                                value={currencyId}
                                onChange={handleChange}>

                      {currencyId === "" && (<option>-</option>)}
                      {Currency.all.map(curr => {
                        if (curr === Currency.JPY) {
                          return null;
                        }
                        return <option key={curr.key} value={curr.id}>{curr.value}</option>
                      })}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'managerId'}
                           className={'py-1'}
                           title={'営業者'}
                           size={{xs: [4, 8], lg: [2, 4]}}>

                    <FormSelect name='managerId'
                                value={managerId}
                                onChange={handleChange}>
                      <option value={''}>-</option>
                      {LoanManager.all.map(el => {
                        return <option key={el.key} value={el.id}>{el.value}</option>
                      })}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'exchangeDateReq'}
                           className={'py-1'}
                           title={'両替希望日'}
                           size={{xs: [4, 8], lg: [2, 4]}}>

                    <FormText type={'date'}
                              name='exchangeDateReq'
                              value={requestDate}
                              onChange={({currentTarget: {value}}) => setRequestDate(value)}/>
                  </FormCol>
                </Row>

                <Row className={'mt-3'}>
                  <Col lg={{span: 10}}>
                    <Table>
                      <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th scope="col" className={'text-center'}>営業者</th>
                        <th scope="col" className={'text-center'}>融資案件</th>
                        <th scope="col" className={'text-start'}>融資先</th>
                        <th scope="col" className={'text-center'}>返済日</th>
                        <th scope="col" className={'text-end'}>金額</th>
                      </tr>
                      </thead>

                      <tbody>
                      {targets.length === 0 && (
                          <tr>
                            <td>&nbsp;</td>
                            <td colSpan={6}>両替可能な返済がありません</td>
                          </tr>
                      )}

                      {targets.map(target => {
                        const selectable = !selectedManagerId || (target.manager.id === selectedManagerId),
                              enabled    = debug === 'true' ? true : selectable,
                              bgColor    = enabled ? '' : 'bg-light';

                        return (
                            <tr key={target.id}
                                onClick={() => {
                                  if (enabled) {
                                    setSelected(selected.toggle(String(target.id)))
                                  }
                                }}>

                              <td scope="row">
                                <input type={'checkbox'}
                                       checked={selected.includes(String(target.id))}
                                       readOnly
                                       disabled={!enabled}/>
                              </td>

                              <td className={`text-center ${bgColor}`}>
                                <TextDiv width={"8rem"}>
                                  {target.manager.name}
                                </TextDiv>
                              </td>
                              <td className={`text-start  ${bgColor}`}>
                                <a href={`/loan-manager/project/${target.projectId}`}>
                                  <TextDiv width={"15rem"}>
                                    {target.projectName}
                                  </TextDiv>
                                </a>
                              </td>
                              <td className={`text-start  ${bgColor}`}>
                                <a href={`/loan-manager/customer/${target.customerId}`}>
                                  <TextDiv width={"15rem"}>
                                    {target.customerName}
                                  </TextDiv>
                                </a>
                              </td>
                              <td className={`text-center ${bgColor}`}>
                                <a href={`/loan-manager/project/${target.projectId}/trade/${target.id}`}>
                                  {target.processDate?.toYmd()}
                                </a>
                              </td>
                              <td className={`text-end    ${bgColor}`}>{target.amount.toString().withComma}</td>
                            </tr>
                        )
                      })}
                      </tbody>

                      {targets.length !== 0 && (
                          <tfoot>
                          <tr>
                            <td>&nbsp;</td>
                            <th colSpan={4} className={'text-center'}>合計金額</th>
                            <th className={'text-end'}>{selectedTotal}</th>
                          </tr>
                          </tfoot>
                      )}
                    </Table>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        {/* フッター */}
        <Footer>
          <Button variant='primary'
                  className={'m-2'}
                  style={{width: 200}}
                  size={'sm'}
                  onClick={() => setModal('confirm')}
                  disabled={selected.length === 0 || requestDate.trim() === ''}>
            新規両替申請
          </Button>
        </Footer>

        {/* 確認モーダル */}
        <ConfirmModal show={modal === 'confirm'}
                      confirmLabel={'申請する'}
                      onConfirm={() => {
                        setModal('');
                        handleCreate();
                      }}
                      onCancel={() => setModal('')}>

          両替申請を行いますか？


          <div className={'mt-5'}>
            <Table className={'table border-1 fs-6'} hover>
              <tbody>
              <tr>
                <th>両替希望日</th>
                <td className={'text-end'}>{requestDate || '----/--/--'}</td>
              </tr>
              <tr>
                <th>件数</th>
                <td className={'text-end'}>{selected.length}</td>
              </tr>
              <tr>
                <th>合計金額</th>
                <td className={'text-end'}>{selectedTotal}</td>
              </tr>
              </tbody>
            </Table>
          </div>
        </ConfirmModal>
      </>
  );
}
