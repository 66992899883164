import {FC, useEffect, useState,} from "react";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Footer, FormCol, FormSelect, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {Breadcrumb, Button, Col, Container, Row, Table} from "react-bootstrap";
import {Currency, ExchangeCategory, LoanManager} from "src/codes";
import {Link, useSearchParams} from "react-router-dom";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useImmer} from "use-immer";
import type {ModelObject} from "objection";
import type {ExchangeTask} from "backoffice/api/RDS";


const pageSize = 50;

const defaultCondition = {
  categoryId: '',
  managerId : '',
  currencyId: '',
  statusId  : '',
  since     : '',
  until     : '',
}

export const LoanExchangeListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  // ステート
  const [query, setQuery]          = useSearchParams(),
        [condition, setCondition]  = useImmer(defaultCondition),
        [{total, list}, setResult] = useState({total: 0, list: [] as ModelObject<ExchangeTask>[]});

  // 初期化
  useEffect(() => {
    const cond = {
            categoryId: query.get('categoryId') || defaultCondition.categoryId,
            managerId : query.get('managerId') || defaultCondition.managerId,
            currencyId: query.get('currencyId') || defaultCondition.currencyId,
            statusId  : query.get('statusId') || defaultCondition.statusId,
            since     : query.get('since') || defaultCondition.since,
            until     : query.get('until') || defaultCondition.until,
          },
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        const req = {...cond, page, pageSize};

        // @ts-ignore チェックはサーバーサイドで実行
        const {total, list} = await tRPC.loan.exchange.list.query(req);
        setResult({total, list});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>両替一覧</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item active>両替一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Container className={'mt-5'}>
                <Row>
                  <FormCol inputId={'category'} className={'py-1'}
                           title={'種別'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormSelect value={condition.categoryId}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.categoryId = value))}>
                      <option value=''>-</option>
                      {ExchangeCategory.all.map(el => (<option key={el.id} value={el.id}>{el.value}</option>))}
                    </FormSelect>
                  </FormCol>

                  <FormCol inputId={'manager'} className={'py-1'}
                           title={'営業者'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormSelect value={condition.managerId}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.managerId = value))}>
                      <option value=''>-</option>
                      {LoanManager.all.map(el => (<option key={el.id} value={el.id}>{el.value}</option>))}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'status'} className={'py-1'}
                           title={'ステータス'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormSelect value={condition.statusId}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.statusId = value))}>
                      <option value=''>-</option>
                      <option value={'created'}>申請済み / 実行前</option>
                      <option value={'executed'}>実行後 / 承認前</option>
                      <option value={'confirmed'}>承認済み</option>
                    </FormSelect>
                  </FormCol>

                  <FormCol inputId={'currencyId'} className={'py-1'}
                           title={'通貨'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormSelect name='currencyId'
                                value={condition.currencyId}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.currencyId = value))}>
                      <option value=''>-</option>
                      {Currency.all.map(curr => {
                        if (curr === Currency.JPY) {
                          return null;
                        }
                        return <option key={curr.key} value={curr.id}>{curr.value}</option>
                      })}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'since'} className={'py-1'}
                           title={'申請期間(開始)'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormText name={'since'} type={'date'}
                              value={condition.since}
                              onChange={({target: {value}}) => setCondition(draft => void (draft.since = value))}/>
                  </FormCol>

                  <FormCol inputId={'until'} className={'py-1'}
                           title={'申請期間(終了)'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormText name={'until'} type={'date'}
                              value={condition.until}
                              onChange={({target: {value}}) => setCondition(draft => void (draft.until = value))}/>
                  </FormCol>
                </Row>

                <Row style={{marginTop: 20}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'} onClick={handleSearch} size={'sm'}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>


          <div className={'container small'}>
            <Table className={'mt-5'}>
              <thead>
              <tr>
                <th className={'text-center'}>種別</th>
                <th className={'text-center'}>営業者</th>
                <th className={'text-center'}>申請日</th>
                <th className={'text-center'}>実行希望日</th>
                <th className={'text-end'}>申請額</th>
                <th className={'text-center'}>実行日</th>
                <th className={'text-end'}>実行額</th>
                <th className={'text-end'}>結果額</th>
                <th className={'text-center'}>レート</th>
                <th className={'text-center'}>申請者</th>
                <th className={'text-center'}>実行者</th>
                <th className={'text-center'}>承認者</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              {list.map(exchange => {
                const currency = Currency.find(exchange.currencyId);

                // ファンド両替
                if (exchange.categoryId === ExchangeCategory['ファンド両替（邦貨 → 外貨）'].id) {
                  return (
                      <tr key={exchange.id}>
                        <td className={'align-middle text-center'}>{Currency.JPY.flag} → {Currency.find(exchange.currencyId).flag}</td>
                        <td className={'align-middle text-center'}>{exchange.manager.name}</td>
                        <td className={'align-middle text-center'}>{exchange.createdAt?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-center'}>{exchange.executeDateReq?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-end'}>{Currency.JPY.withSuffix(exchange.orderAmount)}</td>
                        <td className={'align-middle text-center'}>{exchange.executeDate?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-end'}>{Currency.JPY.withSuffix(exchange.executeAmount)}</td>
                        <td className={'align-middle text-end'}>{currency?.withSuffix(exchange.resultAmount)}</td>
                        <td className={'align-middle text-center'}>{exchange.rate?.toString() ?? '---.--'}</td>
                        <td className={'align-middle text-center'}>{exchange.createdBy?.name ?? '---'}</td>
                        <td className={'align-middle text-center'}>{exchange.executedBy?.name ?? '---'}</td>
                        <td className={'align-middle text-center'}>{exchange.confirmedBy?.name ?? '---'}</td>
                        <td>
                          <Link to={`./fund/${exchange.id}`} className={'btn btn-secondary btn-sm'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </td>
                      </tr>
                  );
                }
                // 返済両替
                else {
                  return (
                      <tr key={exchange.id}>
                        <td className={'align-middle text-center'}>{Currency.find(exchange.currencyId).flag} → {Currency.JPY.flag}</td>
                        <td className={'align-middle text-center'}>{exchange.manager.name}</td>
                        <td className={'align-middle text-center'}>{exchange.createdAt?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-center'}>{exchange.executeDateReq?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-end'}>{currency?.withSuffix(exchange.orderAmount)}</td>
                        <td className={'align-middle text-center'}>{exchange.executeDate?.toYmd() ?? '----/--/--'}</td>
                        <td className={'align-middle text-end'}>{currency?.withSuffix(exchange.executeAmount)}</td>
                        <td className={'align-middle text-end'}>{Currency.JPY.withSuffix(exchange.resultAmount)}</td>
                        <td className={'align-middle text-center'}>{exchange.rate?.toString() ?? '---.--'}</td>
                        <td className={'align-middle text-center'}>{exchange.createdBy?.name ?? '---'}</td>
                        <td className={'align-middle text-center'}>{exchange.executedBy?.name ?? '---'}</td>
                        <td className={'align-middle text-center'}>{exchange.confirmedBy?.name ?? '---'}</td>
                        <td>
                          <Link to={`./trade/${exchange.id}`} className={'btn btn-secondary btn-sm'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </td>
                      </tr>
                  );
                }
              })}
              </tbody>
            </Table>
          </div>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </Container>

        <Footer className={`py-2`}>
          <Link to={`./fund/new`}
                className={'btn btn-sm btn-primary me-2'}
                style={{width: "15em"}}>
            新規ファンド両替申請
          </Link>

          <Link to={`./trade/new`}
                className={'btn btn-sm btn-primary'}
                style={{width: "15em"}}>
            新規返済両替申請
          </Link>
        </Footer>
      </>
  );
}
