import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '初期登録',
  },
  {
    id   : 2,
    value: 'システム判定 OK',
  },
  {
    id   : 3,
    value: 'カスタマー判定 待ち',
  },
  {
    id   : 11,
    value: 'カスタマー判定 OK'
  },
  {
    id   : 12,
    value: 'カスタマー判定 NG'
  },
  {
    id   : 99,
    value: '対応不要',
  },
] as const;


/**
 * タイプ
 */
export type DepositTransactionStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * 名称アクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<typeof CodeList[number]['value'], DepositTransactionStatus>);


/**
 * 公開
 */
export const DepositTransactionStatus = Object.assign(CodeClass, NameAccessor);
