import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {MembersDepositListPage} from "./List";
import {MembersDepositFormPage} from "./Form";

export const MembersDepositPage: FC = () => {
  return (
      <Routes>
        <Route index element={<MembersDepositListPage/>}/>
        <Route path=":id" element={<MembersDepositFormPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}
