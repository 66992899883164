import {faEdit, faMoneyBillAlt, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTRPC} from "backoffice/ui/App";
import {Currency, LoanProjectStatus} from "src/codes";
import {Footer, FormCol, FormSelect, FormText, Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {FC, KeyboardEventHandler, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import {useImmer} from "use-immer";
import {ModelObjectRCSV} from "backoffice/api/types";
import type {Project} from "backoffice/api/RDS/Models/LoanManager";
import {useImmerChangeHandler} from "backoffice/ui/utils/react/hooks";

const pageSize = 50;

const defaultCondition = {
  statusId  : '',
  currencyId: '',
  keyword   : '',
} as Record<string, any>;

export const LoanManagerProjectListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState({total: 0, list: [] as ModelObjectRCSV<Project>[]});


  // 初期化
  useEffect(() => {
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
            cond[k as keyof typeof defaultCondition] = query.get(k) || v;
            return cond;
          }, {} as typeof defaultCondition),
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    // データ取得
    Loader.task(async () => {
      try {
        const req = {...cond, page, pageSize};

        // @ts-ignore チェックはサーバーサイドで実行
        const {total, list} = await trpc.loan.project.list.query(req);
        setResult({total, list});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);

  // 検索条件 編集
  const handleChange = useImmerChangeHandler(setCondition);

  // エンターキー入力
  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.key === 'Enter' && handleSearch();
  };

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>融資案件一覧</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item active>融資案件一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Form className={'my-5'}>
                <input type='submit' className={'d-none'} disabled/>
                <Row>
                  <FormCol inputId={'statusId'} className={'py-1'}
                           title={'ステータス'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect name={'statusId'}
                                value={condition.statusId}
                                onChange={handleChange}>
                      <option value=""> すべて</option>
                      {LoanProjectStatus.all.map(elem => <option value={elem.id} key={elem.id}>{elem.value}</option>)}
                    </FormSelect>
                  </FormCol>

                  <FormCol inputId={'currencyId'} className={'py-1'}
                           title={'通貨'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect name={'currencyId'}
                                value={condition.currencyId}
                                onChange={handleChange}>
                      <option value="">すべて</option>
                      {Currency.all.map(elem => <option value={elem.id} key={elem.id}>{elem.value}</option>)}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'keyword'} className={'py-1'}
                           title={'キーワード'}
                           size={{xs: [4, 8], md: [2, 10]}}>
                    <FormText name={'keyword'}
                              value={condition.keyword}
                              onChange={handleChange}
                              onKeyDown={handleEnter}/>
                  </FormCol>
                </Row>

                <Row style={{marginTop: 20}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'}
                            onClick={handleSearch}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>


        <div className={'container small'}>
          <Table hover>
            <thead>
            <tr>
              <th>コード</th>
              <th>融資先</th>
              <th>案件</th>
              <th className='text-center'>通貨</th>
              <th className='text-end'>貸付金額</th>
              <th className='text-center' colSpan={3}>実行期間</th>
              <th className='text-center'>&nbsp;</th>
            </tr>
            </thead>

            <tbody>
            {/* データなし */}
            {result.list.length === 0 && (
                <tr>
                  <td colSpan={10}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.list.map((elem, index) => (
                    <tr key={elem.id} className={(index % 2) ? 'bg-white' : 'bg-light'}>
                      <td className={'p-1 align-middle'}>
                        <TextDiv width={"10em"}>
                          {elem.customer?.code ?? ''} - {elem?.code ?? ''}
                        </TextDiv>
                      </td>
                      <td className={'p-1 align-middle'}>
                        <TextDiv width={"15em"}>
                          {elem.customer?.name ?? '-'}
                        </TextDiv>
                      </td>
                      <td className={'p-1 align-middle'}>
                        <TextDiv width={"10em"}>
                          {elem.name}
                        </TextDiv>
                      </td>
                      <td className='p-1 align-middle text-center' style={{minWidth: "5em"}}>
                        {Currency.find(elem.currencyId)?.value ?? '-'}
                      </td>
                      <td className='p-1 align-middle text-end' style={{minWidth: "10em"}}>
                        {elem.amount.toFixed(Currency.find(elem.currencyId)?.precision).withComma}
                      </td>
                      <td className='p-1 align-middle text-center' style={{minWidth: "7em"}}>
                        {elem.since?.toYmd()}
                      </td>
                      <td className='p-1 align-middle text-center'>
                        ~
                      </td>
                      <td className='p-1 align-middle text-center' style={{minWidth: "7em"}}>
                        {elem.until?.toYmd()}
                      </td>
                      <td className={'p-1 align-middle'} style={{minWidth: "8em"}}>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>編集</Tooltip>}>
                          <Link to={`./${elem.id}`} className={'btn btn-secondary btn-sm me-1'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>アロケーション</Tooltip>}>
                          <Link to={`./${elem.id}/allocation`} className={'btn btn-secondary btn-sm me-1'}>
                            <FontAwesomeIcon icon={faSlidersH}/>
                          </Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>融資/返済</Tooltip>}>
                          <Link to={`./${elem.id}/trade`} className={'btn btn-secondary btn-sm'}>
                            <FontAwesomeIcon icon={faMoneyBillAlt}/>
                          </Link>
                        </OverlayTrigger>
                      </td>
                    </tr>
                )
            )}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-between'}>
            <span>件数: {String(result.total).withComma}</span>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>

        <Footer className={`py-2`}>
          <Link to={'./new'}
                className={'btn btn-sm btn-primary mx-2'} style={{width: '10em'}}>
            新規作成
          </Link>
        </Footer>
      </>
  );
}
