import {Context, createContext, FC, useContext, useEffect, useState} from 'react';
import {fetchAuthSession} from "aws-amplify/auth";
import {CreateTRPCProxyClient, createTRPCProxyClient, httpBatchLink} from "@trpc/client";
import superjson from "superjson";
import {useLoader} from "./Components";
import {AppRouter} from "backoffice/api/AppRouter";
import {UserType} from "backoffice/api/AppRouter/User";
import {Backoffice} from "./Pages";
import {AuthPage} from "backoffice/ui/Pages/Auth";
import {merge} from "lodash";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


type TRPCClient = CreateTRPCProxyClient<AppRouter>;

// Cognitoユーザー共有
const UserContext: Context<UserType> = createContext({} as UserType);
export const useUser                 = () => useContext(UserContext);

// TRPCクライアント共有
const TRPCContext: Context<TRPCClient> = createContext<TRPCClient>({} as TRPCClient);
export const useTRPC                   = () => useContext(TRPCContext);

const App: FC<{ apiEndpoint: string }> = ({apiEndpoint}) => {

  const Loader = useLoader();

  // ステート (trpcを単体で useState で利用しようとするとundefinedになる)
  const [{init, trpc, user}, setState] = useState({
    init: false,
    trpc: {} as TRPCClient,
    user: false as UserType | false
  });

  useEffect(() => {
    Loader.task('初期化中です', async () => {

      try {
        // 他システムOAuth関連 (cognito前に処理)

        // codeを gmo-aozora-net-auth-codeとして、localStorageに退避し、/banking/gmo-aozora-net/cb 内で処理
        if (location.pathname === '/banking/gmo-aozora-net/cb') {
          location.search.substring(1).split('&').forEach(el => {
            const [k, v] = el.split('=');
            if (k === 'code') {
              localStorage.setItem('gmo-aozora-net-auth-code', v);
              location.href = location.pathname;
            }
          })
        }

        // TPRCクライアント生成
        const trpc = createTRPCProxyClient<AppRouter>({
          links: [
            httpBatchLink({
              url: apiEndpoint,

              // フェッチのオーバーライド
              async fetch(...args: Parameters<typeof fetch>): ReturnType<typeof fetch> {
                const [_url, _options] = args;

                const session = await fetchAuthSession();
                return fetch(_url.toString(), merge(_options, {
                  headers: {
                    Authorization: session.tokens!.accessToken.toString(),
                  }
                }));
              }
            })
          ],

          transformer: superjson,
        });

        // ユーザー取得
        const user = await trpc.user.currentUser.query();

        // 初期化完了
        setState({init: true, trpc, user});

      } catch (err) {
        setState({init: true, trpc, user: false});
      }
    }).then();

  }, []);

  // 初期化前
  if (!init) {
    return <div>&nbsp;</div>;
  }

  // 認証エラー
  if (!user) {
    return <AuthPage/>
  }

  return (
      <UserContext.Provider value={user}>
        <TRPCContext.Provider value={trpc}>
          <Backoffice/>
        </TRPCContext.Provider>
      </UserContext.Provider>

  );
}

export default App;
