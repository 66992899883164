import {ChangeEventHandler, useCallback, useEffect, useRef} from 'react';
import {Updater} from "use-immer";
import {Draft} from "immer";

/**
 * 前回の値
 *
 * @param value
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

type ImmerChangeEventHooks<S> = {
  before?: (arg: { draft: Draft<S>, name: string, newValue: string | boolean, oldValue: string | boolean }) => void;
  after?: (arg: { draft: Draft<S>, name: string, newValue: string | boolean, oldValue: string | boolean }) => void;
}

/**
 * 汎用 onChangeHandler for Immer
 *
 * @param updater
 */
export function useImmerChangeHandler<S>(updater: Updater<S>): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>


/**
 * 汎用 onChangeHandler for
 *
 * @param updater
 * @param hooks
 */
export function useImmerChangeHandler<S>(updater: Updater<S>, hooks?: ImmerChangeEventHooks<S>): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

/**
 * 汎用 onChangeHandler for Immer
 *
 * @param updater
 * @param hooks
 */
export function useImmerChangeHandler<S>(updater: Updater<S>, hooks?: ImmerChangeEventHooks<S>): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
  return useCallback(({
    currentTarget
  }) => {
    const {name, type, value} = currentTarget;


    updater(draft => {
      const wk: any                    = draft,
            oldValue: string | boolean = type === 'checkbox' ? !!wk[name] : wk[name],
            newValue: string | boolean = type === 'checkbox' ? !wk[name] : value;

      hooks?.before?.({draft, name, oldValue, newValue});

      wk[name] = newValue;

      hooks?.after?.({draft, name, oldValue, newValue});
    });
  }, [updater, hooks]);
}
