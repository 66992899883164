import {ConfirmModal, FormNumber, FormText, Nl2Br} from "backoffice/ui/Components";
import {ChangeEventHandler, FC, useCallback, useState} from "react"
import {FormModel} from "backoffice/ui/Pages/LoanManager/Project/Trade/Form/index";
import {Updater} from "use-immer"
import type {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";
import {ModelObjectRCSV} from "backoffice/api/types";
import {Allocation} from "backoffice/api/RDS/Models/LoanManager";
import {ValidationError} from "backoffice/api/ValidationError";
import {Button, Form} from "react-bootstrap";
import Big from "big.js";

export const DividendTable: FC<{
  project: LoanProjectType | null,
  allocations: ModelObjectRCSV<Allocation>[],
  model: FormModel,
  detailIndex: number,
  setModel: Updater<FormModel>,
  error: ValidationError | null
}> = ({project, allocations, model, detailIndex, setModel, error}) => {

  const precision = project?.currency?.precision || 0;

  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = useCallback(({
    currentTarget
  }) => {
    const {dataset}    = currentTarget,
          allocationId = dataset.allocationId,
          value        = currentTarget.value;

    setModel(draft => {
      if (!draft) {
        return;
      }

      draft.details[detailIndex].dividends[allocationId ?? ''] = value;
    })
  }, [setModel]);

  const [modal, setModal] = useState("");
  const handleAutoDiv     = () => {
    const [summary, total] = allocations.reduce(([summary, total], alloc) => {
      const item_id = alloc.item_id!,
            amount  = alloc.amount.sub(alloc.repaid ?? 0);

      summary[item_id] = (item_id in summary) ? summary[item_id].add(amount)
                                              : amount

      return [summary, total.add(amount)];
    }, [{}, Big(0)] as [Record<string, Big>, Big]);


    const amount = Big(model.details[detailIndex].amount ?? 0).add(project!.pooled ?? 0);
    for (const [item_id, running] of Object.entries(summary)) {
      summary[item_id] = amount.times(running).div(total).round(project!.currency.precision, Big.roundDown);
    }

    setModel(prev => {
      for (const alloc of allocations) {
        if (summary[alloc.item_id!].gt(0)) {
          const left = alloc.amount.sub(alloc.repaid ?? 0);

          if (summary[alloc.item_id!].gt(left)) {
            prev.details[detailIndex].dividends[alloc.id] = left.toString();
            summary[alloc.item_id!]                       = summary[alloc.item_id!].sub(left);
          } else {
            prev.details[detailIndex].dividends[alloc.id] = summary[alloc.item_id!].toString();
            summary[alloc.item_id!]                       = Big(0);
          }
        }
      }
    });
  };

  if (!project || !model) {
    return <div>...</div>
  }

  // 雑益
  const misc = model.details[detailIndex].dividends['null'];

  return (
      <div id={'list-table'} className={'d-flex flex-column'}>
        <div>
          <ul className={'d-flex m-0 p-0'}>
            <li className={'p-1 text-center fw-bolder flex-grow-1'}>ファンドID</li>
            <li className={'p-1 text-center fw-bolder date'}>運用終了予定</li>
            <li className={'p-1 text-center fw-bolder amount'}>ファンド総額</li>
            <li className={'p-1 text-center fw-bolder dateX2'}>アロケート期間</li>
            <li className={'p-1 text-center fw-bolder amount'}>金額</li>
            <li className={'p-1 text-center fw-bolder amount'}>返済済</li>
            <li className={'p-1 text-center fw-bolder amount'}>運用中</li>
            <li className={'p-1 text-center fw-bolder amount'}>分配額</li>
          </ul>
        </div>

        {allocations.length === 0 && (
            <div className={`border-top p-3`}>
              アロケーションが読み込まれていません。<br/>
              予定日 / 取引日を入力することで対象のアロケーションが表示されます。
            </div>
        )}

        {allocations.map(allocation => {
          const fund = allocation.item_id === 'proper' ? 'プロパー融資' : allocation.item_id ?? '雑益';
          return (
              <div key={allocation.id}
                   className={`border-top`}
                   data-id={allocation.id}>
                <ul className={'d-flex m-0 p-0'}>

                  <li className={'p-1 flex-grow-1'}>
                    <FormText value={fund}
                              className={'fund'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 date'}>
                    <FormText value={allocation.fund?.deactivate_date.toYmd() ?? '----/--/--'}
                              className={'fund'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 amount'}>
                    <FormText value={allocation.fund?.exchangeResult?.toFixed(precision).withComma
                                     ?? allocation.fund?.establishedAmount?.toFixed(precision).withComma
                                     ?? '-'}
                              className={'fund text-end'}
                              size={'sm'}
                              readOnly/>
                  </li>


                  <li className={'p-1 text-center date'}>
                    <FormText value={allocation.since.toYmd()}
                              className={'allocation'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 text-center date'}>
                    <FormText value={allocation.until.toYmd()}
                              className={'allocation'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 text-center amount'}>
                    <FormText value={allocation.amount.toFixed(precision).withComma}
                              className={'allocation text-end'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 text-center amount'}>
                    <FormText value={allocation.repaid?.toFixed(precision).withComma ?? '-'}
                              className={'allocation text-end'}
                              size={'sm'}
                              readOnly/>
                  </li>

                  <li className={'p-1 text-center amount'}>
                    <FormText value={allocation.amount.sub(allocation.repaid ?? 0).toFixed(precision).withComma ?? '-'}
                              className={'allocation text-end'}
                              size={'sm'}
                              readOnly/>
                  </li>


                  <li className={'p-1 amount'}>
                    <FormNumber data-allocation-id={allocation.id}
                                name={'amount'}
                                value={model.details[detailIndex].dividends[allocation.id] ?? ''}
                                size={'sm'}
                                align={'end'}
                                precision={precision}
                                realtime
                                onChange={handleChange}
                                disabled={model?.isConfirmed}/>

                    <Form.Text className="text-danger">
                      <Nl2Br text={error?.get(`details.${detailIndex}.dividends.${allocation.id}`)}/>
                    </Form.Text>
                  </li>
                </ul>
              </div>
          );
        })}

        {/* 承認前 */}
        {model.isConfirmed || (
            <>
              <div className={`border-top`}>
                <div className={'d-flex justify-content-center m-0 p-2'}>
                  <Button variant={'outline-primary'}
                          size={'sm'}
                          style={{width: "10rem"}}
                          onClick={() => setModal('confirmAutoDiv')}>
                    按分入力
                  </Button>
                </div>
              </div>

              <ConfirmModal show={modal === 'confirmAutoDiv'}
                            confirmLabel={'入力する'}
                            onConfirm={() => {
                              setModal('');
                              handleAutoDiv();
                            }}
                            onCancel={() => setModal('')}>

                <div className={'my-3'}>
                  分配額を自動入力しますか？
                </div>
                <small>* 既存データは上書きされます</small>
              </ConfirmModal>
            </>
        )}

        <div className={`border-top`}>
          <ul className={'d-flex m-0 p-0'}>
            <li className={'p-1 flex-grow-1'}/>
            <li className={'p-1 amount'}>

              <Form.Text className="text-danger">
                <Nl2Br text={error?.get(`details.${detailIndex}.amount`)}/>
              </Form.Text>
            </li>
          </ul>
        </div>

        {/* 承認後に雑益があれば表示 */}
        {model.isConfirmed && misc && (
            <div className={`border-top`}>
              <ul className={'d-flex m-0 p-0'}>

                <li className={'p-1 flex-grow-1'}>
                  <FormText value={'雑益'}
                            size={'sm'}
                            readOnly/>
                </li>

                <li className={'p-1 amount'}>
                  <FormNumber value={misc.toString()}
                              size={'sm'}
                              align={'end'}
                              precision={precision}
                              realtime
                              disabled/>
                </li>
              </ul>
            </div>
        )}
      </div>
  )
}

