import {AbstractCode} from "src/codes/abstract";
import {FundItem} from "./fundItem";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 'Fund',
    value: "ファンド",
    Code : FundItem
  }
] as const;


/**
 * タイプ
 */
export type JournalSystem = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.id] = elem;
  return result;
}, {} as Record<JournalSystem['id'], JournalSystem>);


/**
 * 公開
 */
export const JournalSystem = Object.assign(CodeClass, NameAccessor);
