import {ChangeEventHandler, FC, FormEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useImmer} from "use-immer";
import {FormCheck, FormNumber, FormText, FormTextArea, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {ValidationError} from "backoffice/api/ValidationError";
import {CustomerType} from "backoffice/api/AppRouter/Loan/Customer";
import {BoolPropsOf, StringPropsOf} from "backoffice/api/types";


export const LoanManagerCustomerFormPage: FC = () => {

  // パラメータ
  const {id} = useParams();

  useEffect(() => {

    if (id === 'new') {
      document.title = '新規作成'
      setModel({name: ''});

    } else if (Number(id)) {
      document.title = `編集`;
      doGet().then();

    } else {
      // IDが不正
      Navigate('..');
    }
  }, []);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  // ステート
  const [model, setModel] = useImmer<Pick<CustomerType, 'name'> & Partial<CustomerType>>({name: ''}),
        [error, setError] = useState<ValidationError | null>(null);


  // 読み込み
  const doGet: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      if (!id) {
        return
      }

      try {
        const model = await trpc.loan.customer.get.query(Number(id));
        setModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [id]);


  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {type, name, value, checked}}) => {
    if (type === 'checkbox') {
      setModel(draft => void (draft && (draft[name as BoolPropsOf<CustomerType>] = checked)));
    } else {
      setModel(draft => void (draft && (draft[name as StringPropsOf<CustomerType>] = value)));
    }
  }, [setModel]);


  // 更新
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      if (!model) {
        return;
      }

      // エラーを解除
      setError(null);

      try {

        // 新規作成
        if (id === 'new') {
          const response = await trpc.loan.customer.create.mutate(model);

          Message.show('登録が完了しました', () => {
            Navigate(`../${response.id}`);
          });
          return;
        }

        // 更新
        await trpc.loan.customer.update.mutate({id: Number(id), ...model});

        Message.show('更新が完了しました', () => {
          Navigate(`..`);
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>融資先一覧</Breadcrumb.Item>
          <Breadcrumb.Item active>{id === 'new' ? '新規作成' : `編集 #${model.id}`}</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}}>
          <input type='submit' className={'d-none'} disabled/>

          <Row>
            <Col md={{span: 4}} xl={{span: 2, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="code">
                <Form.Label>コード</Form.Label>
                <FormText placeholder={'コード'}
                          name={'code'}
                          value={model?.code ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('code')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 6}} xl={{span: 5}} style={{padding: 10}}>
              <Form.Group controlId="name">
                <Form.Label>名称</Form.Label>
                <FormText placeholder={'名称'}
                          name={'name'}
                          value={model?.name ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 2}} xl={{span: 1}} style={{padding: 10}}>
              <Form.Group controlId="short_name">
                <Form.Label>記号</Form.Label>
                <FormText placeholder={'記号'}
                          name={'short_name'}
                          value={model?.short_name ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('short_name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 4}} xl={{span: 3, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="charge_title">
                <Form.Label>担当者役職</Form.Label>
                <FormText placeholder={'担当者役職'}
                          name={'charge_title'}
                          value={model?.charge_title ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('charge_title')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 7}} xl={{span: 5}} style={{padding: 10}}>
              <Form.Group controlId="charge_name">
                <Form.Label>担当者名</Form.Label>
                <FormText placeholder={'担当者名'}
                          name={'charge_name'}
                          value={model?.charge_name ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('charge_name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 6}} xl={{span: 4, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="charge_email">
                <Form.Label>担当者Eメール</Form.Label>
                <FormText placeholder={'xxxx@xxxx.com'}
                          name={'charge_email'}
                          value={model?.charge_email ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('charge_email')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 6}} xl={{span: 4}} style={{padding: 10}}>
              <Form.Group controlId="charge_mobile">
                <Form.Label>担当者電話番号</Form.Label>
                <FormText placeholder={'0x0-0000-0000'}
                          name={'charge_mobile'}
                          value={model?.charge_mobile ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('charge_mobile')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 4}} xl={{span: 2, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="voting_right">
                <Form.Label>議決権保有割合</Form.Label>
                <FormNumber placeholder={'議決権保有割合 0.000 ~ 100.000'}
                            name={'voting_right'}
                            precision={3}
                            value={model?.voting_right ?? 0}
                            onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('voting-right')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 4}} xl={{span: 2,}} style={{padding: 10}}>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <FormCheck id={'boardmember_flg'}
                           name={'boardmember_flg'}
                           checked={model.boardmember_flg ?? false}
                           onChange={handleChange}>
                  役員兼務
                </FormCheck>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('boardmember_flg')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 4}} xl={{span: 2}} style={{padding: 10}}>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <FormCheck id={'group_flg'}
                           name={'group_flg'}
                           checked={model.group_flg ?? false}
                           onChange={handleChange}>
                  自社グループ
                </FormCheck>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('group_flg')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 6}} xl={{span: 4, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="accounting_customer_code">
                <Form.Label>経理：取引先コード</Form.Label>
                <FormText placeholder={'仕訳出力時に使用'}
                          name={'accounting_customer_code'}
                          value={model?.accounting_customer_code ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('accounting_customer_code')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>

            <Col md={{span: 6}} xl={{span: 4}} style={{padding: 10}}>
              <Form.Group controlId="accounting_loan_item_code">
                <Form.Label>経理：TK貸付 勘定科目コード</Form.Label>
                <FormText placeholder={'161010000'}
                          name={'accounting_loan_item_code'}
                          value={model?.accounting_loan_item_code ?? ''}
                          onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('accounting_loan_item_code')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xl={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormTextArea placeholder={'メモ'}
                              name={'memo'}
                              value={model?.memo ?? ''}
                              onChange={handleChange}/>

                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('memo')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          {id !== 'new' && (
              <Row>
                <Col>
                  <div className={'d-flex justify-content-end'}>
                    <Form.Text className="text-muted">
                      <small><>最終更新日時: {model.update_time?.toYmd_hms() ?? model.create_time?.toYmd_hms()}</>
                      </small>
                    </Form.Text>
                  </div>
                </Col>
              </Row>
          )}

          <Row style={{marginTop: 50}}>
            <Col md={{span: 4, offset: 2}} style={{padding: 10}}>
              <Button variant="primary" className={'w-100'} type='submit'>
                {id === 'new' ? '登録する' : '更新する'}
              </Button>
            </Col>

            <Col md={{span: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
  );
}


