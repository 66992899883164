import {FC, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {useSearchParams} from "react-router-dom";
import {DeviceInfoModel} from "backoffice/api/RDS/Models/NoticeMng/DeviceInfoModel";


export const MembersNoticeMngDeviceListPage: FC = () => {

  const Loader                          = useLoader(),
        Message                         = useMessage(),
        Navigate                        = useNavigate(),
        [searchParams, setSearchParams] = useSearchParams(),
        trpc                            = useTRPC();

  // ページ / サイズ
  const page = Math.max(Number(searchParams.get('page')) || 0, 1),
        size = Math.min(Number(searchParams.get('size')) || 100, 100);

  // ステート
  const [result, setResult] = useState({total: 1, results: [] as DeviceInfoModel[]});

  // 初回実行
  useEffect(() => {
    document.title = '紐付け状況一覧 | プッシュ通知管理';

    // 初期検索
    doList(page).then();
  }, []);

  // リスト読み込み
  const doList: (page: number) => Promise<void> = useCallback(async (page) => {

    // ページ
    setSearchParams(params => {
      if (page === 1) {
        params.delete('page');
      } else {
        params.set('page', String(page));
      }
      return params;
    })

    Loader.task(async () => {
      try {
        const {results, total} = await trpc.noticemng.device.list.query({page, size});
        setResult({results: DeviceInfoModel.inject(results), total});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  /**
   * CSVダウンロード
   */
  const handleDownload = () => {
    Loader.task(async () => {
      try {
        const result = await trpc.noticemng.device.csv.query();
        location.href = result.url;

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>プッシュ通知管理</Breadcrumb.Item>
            <Breadcrumb.Item active>紐付け状況一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container small'}>
          <Table bordered striped>
            <colgroup>
              <col span={1} style={{minWidth: '8rem'}}/>
              <col span={1} style={{minWidth: '8rem'}}/>
              <col span={1} style={{minWidth: '8rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
              <col span={1} style={{minWidth: '5rem'}}/>
            </colgroup>
            <thead>
            <tr>
              <th style={{textAlign: 'center'}}>ユーザID</th>
              <th style={{textAlign: 'center'}}>最終ログイン日</th>
              <th style={{textAlign: 'center'}}>紐付け日</th>
              <th style={{textAlign: 'center'}}>通知許可</th>
              <th style={{textAlign: 'center'}}>種別</th>
              <th>トークン</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.results.length === 0 && (
                <tr>
                  <td colSpan={6}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.results.map(device => (
                <tr key={device.device_id}>
                  <td style={{textAlign: 'center'}}>{device.user.user_id}</td>
                  <td style={{textAlign: 'center'}}>{device.user.last_login_date?.toYmd()}</td>
                  <td style={{textAlign: 'center'}}>{device.user.linked_date?.toYmd()}</td>
                  <td style={{textAlign: 'center'}}>{device.permittedValue}</td>
                  <td style={{textAlign: 'center'}}>{device.platform}</td>
                  <td>
                    <TextDiv width={'30rem'}>
                      {device.token}
                    </TextDiv>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={page}
                   pageSize={size}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => doList(page)}/>
          </div>
        </div>

        {/* フッター */}
        <footer>
          <div className={'container-fluid'} style={{backgroundColor: "rgba(80,80,80,0.5)"}}>
            <div className={'d-flex justify-content-end'}>
              <Button variant='primary' className={'m-2 '}
                      onClick={handleDownload}>
                CSVダウンロード
              </Button>
            </div>
          </div>
        </footer>
      </>
  );
}
