import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {AccountingJournalListPage} from "backoffice/ui/Pages/Accounting/Journal/List";
import {AccountingBooksPage} from "backoffice/ui/Pages/Accounting/Journal/Books";

export const AccountingJournalPage: FC = () => {
  return (
      <Routes>
        <Route path="/" element={<AccountingBooksPage/>}/>
        <Route path=":systemName/:systemId" element={<AccountingJournalListPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


