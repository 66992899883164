import React, {FC, useEffect, useRef} from "react";
import {Breadcrumb, Button, Col, Container, FormControl, Row, Tab, Tabs} from "react-bootstrap";
import {Footer, FormCol, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate, useParams} from "react-router";
import {useImmer} from "use-immer";
import {Helmet} from "react-helmet";
import {useTRPC} from "backoffice/ui/App";
import {DepositTransactionStatus} from "src/codes";
import {AppRouterOutput} from "backoffice/api/AppRouter";


export const MembersDepositFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const memoRef = useRef<HTMLTextAreaElement>(null);

  const [model, setModel] = useImmer<AppRouterOutput['member']['deposit']['get'] | null>(null);

  const {id} = useParams();


  // 初回実行
  useEffect(() => {

    if (!id) {
      Navigate('../');
      return;
    }

    memoRef.current?.focus();

    Loader.task(async () => {
      try {
        // データ登録
        const model = await tRPC.member.deposit.get.query({id});
        setModel(model);

      } catch (err) {
        console.error(err);
        Message.error(err, () => Navigate('../'));
      }
    }, 300).then();
  }, [id]);

  /**
   * 保存
   */
  const handleSubmit = (statusId: number) => {
    if (model === null) {
      return;
    }

    Loader.task(async () => {
      try {
        // データ登録
        await tRPC.member.deposit.update.mutate({
          id          : model.id,
          statusId    : statusId,
          decisionMemo: model.decisionMemo,
        });

        Message.show('登録が完了しました', () => Navigate(-1));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  let updatable = false;
  if (model) {
    const statusId = Number(model.statusId);
    if (statusId === DepositTransactionStatus['カスタマー判定 待ち'].id
        || statusId === DepositTransactionStatus['カスタマー判定 OK'].id
        || statusId === DepositTransactionStatus['カスタマー判定 NG'].id) {
      updatable = true;
    }
  }

  return (
      <>
        <Helmet>
          <title>詳細確認 #{id}</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>お客様管理</Breadcrumb.Item>
            <Breadcrumb.Item active>仮想口座入金</Breadcrumb.Item>
            <Breadcrumb.Item href={'./'}>取引一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>詳細確認 #{id}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>

          <Container className={'my-5'}>
            <Row>
              <Col xl={{offset: 2, span: 8}}>
                <Tabs defaultActiveKey="basic"
                      id="uncontrolled-tab-example"
                      className="mb-3">

                  <Tab eventKey="basic" title="基本情報">
                    <Container className={'my-4'}>
                      <Row>
                        <FormCol inputId={'transaction_date'} className={'py-1'}
                                 title={'取引日'}
                                 size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {model?.valueDate}
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'bankName'} className={'py-1'}
                                 title={'振込先銀行'}
                                 size={{xs: [4, 8], md: [2, 6]}}>

                              <span className={'form-control'}>
                                {model?.bankName}
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'userId'} className={'py-1'}
                                 title={'ユーザーID'}
                                 size={{xs: [4, 8], md: [2, 3]}}>

                              <span className={'form-control'}>
                                {model?.userId}&nbsp;
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'accountName'} className={'py-1'}
                                 title={'登録名義'}
                                 size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {model?.userName}&nbsp;
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'requesterName'} className={'py-1'}
                                 title={'振込名義'}
                                 size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {model?.remitterName}&nbsp;
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'deposit_amount'} className={'py-1'}
                                 title={'振込金額'}
                                 size={{xs: [4, 8], md: [2, 3]}}>

                              <span className={'form-control'}>
                                {model?.amount?.toString().withComma}&nbsp;
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'decisionMemo'} className={'py-1'}
                                 title={'判断メモ'}
                                 size={{xs: [4, 8], md: [2, 8]}}>

                          <FormControl ref={memoRef}
                                       as={'textarea'}
                                       value={model?.decisionMemo || ''}
                                       onChange={({currentTarget: {value}}) => setModel(draft => draft !== null && void (draft.decisionMemo = value))}
                                       readOnly={!updatable}/>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'statusId'} className={'py-1'}
                                 title={'進捗'}
                                 size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {DepositTransactionStatus.find(model?.statusId)?.value || '-'}
                              </span>
                        </FormCol>
                      </Row>

                      <Row>
                        <FormCol inputId={'systemMessage'} className={'py-1'}
                                 title={'システムメモ'}
                                 size={{xs: [4, 8], md: [2, 10]}}>

                              <span className={'form-control'}>
                                {model?.systemMessage ?? <>-</>}
                              </span>
                        </FormCol>
                      </Row>
                    </Container>
                  </Tab>

                  <Tab eventKey="raw" title="受信データ(RAW)">
                    <Container className={'my-4'} key={`${model?.id}`}>
                      <pre style={{whiteSpace: 'pre-wrap'}}>
                        {JSON.stringify(model?.rawData || {}, null, 2)}
                      </pre>
                    </Container>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>

        {/* フッター */}
        <Footer>
          <Button variant='primary'
                  className={'m-1'}
                  style={{width: 100}}
                  onClick={() => handleSubmit(DepositTransactionStatus['カスタマー判定 OK'].id)}
                  disabled={!updatable}>
            OK登録
          </Button>
          <Button variant='danger'
                  className={'m-1'}
                  style={{width: 100}}
                  onClick={() => handleSubmit(DepositTransactionStatus['カスタマー判定 NG'].id)}
                  disabled={!updatable}>
            NG登録
          </Button>
        </Footer>
      </>
  );
}
