import React, {FC, useEffect, useState} from "react";
import {useTRPC} from "backoffice/ui/App";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {FormCol, useLoader, useMessage} from "backoffice/ui/Components";
import {Helmet} from "react-helmet";

export const SubscribePage: FC = () => {

  const Loader  = useLoader(),
        Message = useMessage(),
        tRPC    = useTRPC();


  const [status, setStatus] = useState('unknown'),
        [age, setAge]       = useState(0);


  useEffect(() => {
    Loader.task(async () => {
      try {
        const status = await tRPC.banking.gmoAozoraNet.subscribeStatus.query();
        setStatus(status ? '配信中' : '停止中');

      } catch (err) {
        Message.error(err);
        setStatus('unknown');
      }
    }, 300).then();
  }, [age])


  return (
      <>
        <Helmet>
          <title>配信管理 GMOあおぞら銀行連携</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item href={'./'}>GMOあおぞら銀行連携</Breadcrumb.Item>
            <Breadcrumb.Item active>配信管理</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'pt-5'}>
          <Row>
            <Col xs={{span: 12}}
                 md={{span: 8, offset: 2}}>
              <Container>
                <Row>
                  <FormCol inputId={'status'}
                           className={'py-1'}
                           labelColClassname={'text-end'}
                           title={'配信状況'}
                           size={{xs: [2, 8]}}>

                    <b>{status}</b>
                  </FormCol>
                </Row>

                <Row className={'mt-5'}>
                  <Col>
                    <Button className={'w-100'}
                            variant={'outline-info'}
                            onClick={() => Loader.task(async () => {
                              setAge(age + 1);
                            }, 300)}>リロード</Button>
                  </Col>

                  <Col>
                    <Button className={'w-100'}
                            variant={'secondary'}
                            onClick={() => Loader.task(async () => {
                              await tRPC.banking.gmoAozoraNet.subscribe.query();
                              setAge(age + 1);
                            }, 300)}>配信開始</Button>
                  </Col>

                  <Col>
                    <Button className={'w-100'}
                            variant={'outline-secondary'}
                            onClick={() => Loader.task(async () => {
                              await tRPC.banking.gmoAozoraNet.unsubscribe.query();
                              setAge(age + 1);
                            }, 300)}>配信停止</Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
  );
}
