import {FC, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {FormCol, FormSelect, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {Link, useSearchParams} from "react-router-dom";
import {JournalSystem} from "src/codes/accounting";
import {useImmer} from "use-immer";

const pageSize = 50;

const defaultCondition = {
  systemName: '',
  systemId  : '',
}

export const AccountingBooksPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  // ステート
  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState({total: 1, results: [] as { systemName: string, systemId: string }[]});


  // 初期化
  useEffect(() => {
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
            cond[k as keyof typeof defaultCondition] = query.get(k) || v;
            return cond;
          }, {} as typeof defaultCondition),
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    if (cond.systemName) {

      // データ取得
      Loader.task(async () => {
        try {
          const req = {...cond, page, pageSize};

          // @ts-ignore チェックはサーバーサイドで実行
          const res = await trpc.accounting.journal.books.query(req);
          setResult(res);

        } catch (err) {
          console.error(err);
          Message.error(err);
        }
      }, 300).then();
    }

  }, [query])

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', String(new Date().getTime()));
    setQuery(query);
  }


  return (
      <>
        <Helmet>
          <title>仕訳</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/accounting"}}>会計モジュール</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "/accounting/journal"}} active>仕訳</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Form className={'container my-5'}>
          <Row>
            <FormCol inputId={'systemName'} className={'py-1'}
                     title={'システム'}
                     size={{xs: [4, 8], md: [2, 4]}}>

              <FormSelect value={condition.systemName}
                          onChange={({target: {value}}) => setCondition(draft => void (draft.systemName = value))}>
                <option value={''}>-</option>
                {JournalSystem.all.map(({id, value}) => <option key={id} value={id}>{value}</option>)}
              </FormSelect>
            </FormCol>

            <FormCol inputId={'systemId'} className={'py-1'}
                     title={'ID'}
                     size={{xs: [4, 8], md: [2, 4]}}>
              <FormText value={condition.systemId}
                        onChange={({target: {value}}) => setCondition(draft => void (draft.systemId = value))}/>
            </FormCol>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
              <Button variant="primary"
                      className={'w-100'}
                      onClick={handleSearch}>
                検索する
              </Button>
            </Col>
          </Row>
        </Form>

        <div className={'container small'}>

          <Row>
            {/*データなし*/}
            {result.results.length === 0 && (
                <Col>---</Col>
            )}

            {/* データあり */}
            {result.results.map(({systemName, systemId}) => (
                <Col key={`${systemName}-${systemId}`}>
                  <Link to={`./${systemName}/${systemId}`}>{systemId}</Link>
                </Col>
            ))}
          </Row>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>
      </>
  );
}
