import {DeepReadonly} from 'deep-freeze';
import {z_prestring_ignore_empty} from "src/utils/zod";
import {z} from 'zod';

/**
 * キータイプは数字/文字列
 */
type CodeIdType = string | number

export interface CodeType<K extends CodeIdType> {
  readonly id: K,
  readonly value: string,
}


export class AbstractCode<T extends DeepReadonly<CodeType<CodeIdType>>> {

  private readonly elemList: ReadonlyArray<T>;
  private readonly elemMap: Record<string, T>;

  constructor(elems: ReadonlyArray<T>) {
    this.elemList = elems;
    this.elemMap  = elems.reduce((result, elem) => {
      result[String(elem.id)] = elem;
      return result;
    }, {} as Record<string, T>);
  }

  /**
   * バリデーション
   */
  z_validation() {
    const conv = typeof this.first.id !== "string" ? (v: string | number) => Number(v)
                                                   : (v: string | number) => v;

    return z.string().or(z.number())
            .refine(val => this.has(val), 'Invalid Code')
            .transform(val => conv(val) as T['id']);
  }

  z_validation_optional() {
    return z_prestring_ignore_empty(this.z_validation().optional().nullable());
  }

  get all(): T[] {
    return this.elemList.slice(0) as T[];
  }

  get first(): T {
    return this.elemList[0] as T;
  }

  get last(): T {
    return this.elemList[this.elemList.length - 1] as T;
  }

  has(id: CodeIdType): boolean;
  has(code: T): boolean;

  has(arg: any): boolean {
    if (typeof arg === 'object') {
      return this.elemList.includes(arg);
    }

    if (typeof arg === 'number') {
      return this.elemMap.hasOwnProperty(String(arg));
    }
    return this.elemMap.hasOwnProperty(arg);
  }

  find(id: CodeIdType): T;
  find(id: CodeIdType | undefined): T;
  find(id: undefined): null;
  find(id: null): null;
  find(id: CodeIdType | undefined | null): T | null {
    if (typeof id === 'undefined' || id === null) {
      return null;
    }
    const code = this.findImpl(String(id));
    if (code === null) {
      return null;
    }
    return code as T;
  }

  findImpl(id: string): T | null {
    return this.elemMap[id] ?? null;
  }

  indexOf(id: string | number): number | false {
    const code = this.findImpl(String(id));
    if (code === null) {
      return false;
    }
    return this.elemList.indexOf(code);
  }
}
