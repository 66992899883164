import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 0,
    value: '検討中',
  },
  {
    id   : 1,
    value: '融資実行前',
  },
  {
    id   : 2,
    value: '融資実行中'
  },
  {
    id   : 3,
    value: '返済完了'
  },
  {
    id   : 9,
    value: 'デフォルト'
  },
  {
    id   : 99,
    value: '却下'
  }
] as const;


/**
 * タイプ
 */
export type LoanProjectStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeClass.all.reduce((result, elem) => {
  result[elem.value as LoanProjectStatus['value']] = elem;
  return result;
}, {} as Record<LoanProjectStatus['value'], LoanProjectStatus>);


/**
 * 公開
 */
export const LoanProjectStatus = Object.assign(CodeClass, NameAccessor);
