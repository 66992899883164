import {AbstractCode} from "src/codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: "太陽光"
  }, {
    id   : 2,
    value: "風力"
  }, {
    id   : 3,
    value: "バイオマス"
  }, {
    id   : 4,
    value: "水力"
  }, {
    id   : 5,
    value: "地熱"
  }, {
    id   : 6,
    value: "建設/不動産事業"
  }, {
    id   : 7,
    value: "物流"
  }, {
    id   : 8,
    value: "宿泊/飲食"
  }, {
    id   : 9,
    value: "エンターテイメント"
  }, {
    id   : 10,
    value: "医療/ヘルスケア"
  }, {
    id   : 11,
    value: "水処理"
  }, {
    id   : 12,
    value: "廃棄物処理"
  }, {
    id   : 13,
    value: "金融/マイクロファイナンス"
  }, {
    id   : 14,
    value: "IT/ソフトウェア"
  }, {
    id   : 99,
    value: "その他"
  }
] as const;


/**
 * タイプ
 */
export type LoanProjectCategory = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * 公開
 */
export const LoanProjectCategory = CodeClass;
