import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id: 1,
    value: '新興国マイクロファイナンスファンド',
    shortName: '新興国MF',
    caption: 'mf',
    slug: 'microfinance',
  }, {
    id: 2,
    value: '不動産担保型ローンファンド',
    shortName: '不動産',
    caption: 're',
    slug: 'realestate',
  }, {
    id: 3,
    value: '中小企業支援型ローンファンド',
    shortName: '中小支援',
    caption: 'sme',
    slug: 'sme',
  }, {
    id: 4,
    value: '中小企業支援型ローンファンド（売掛債権確保型）',
    shortName: '中小＊',
    caption: 'smem',
    slug: 'smem',
  }, {
    id: 5,
    value: '代替エネルギー特化型ローンファンド',
    shortName: '代替エネ',
    caption: 'rs',
    slug: 'energy',
  }, {
    id: 6,
    value: 'クラウドバンクファンド',
    shortName: 'クラウド',
    caption: 'cb',
    slug: 'crowd',
  }
] as const;


/**
 * タイプ
 */
export type FundCategory = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const SlugAccessor = CodeList.reduce((result, elem) => {
  result[elem.slug] = elem;
  return result;
}, {} as Record<FundCategory['slug'], FundCategory>);


/**
 * 公開
 */
export const FundCategory = Object.assign(CodeClass, SlugAccessor);
