import {Currency, LoanManager, LoanProjectCategory, LoanProjectDelayStatus, LoanProjectRegion, LoanProjectStatus} from "src/codes";
import {FormCol, FormNumber, FormText} from "backoffice/ui/Components";
import {ChangeEventHandler, FC, useState} from "react";
import {Col, Container, FormSelect, Row} from "react-bootstrap";
import {LoanCustomerModal} from "backoffice/ui/Components/LoanCustomerModal";
import {useImmer} from "use-immer";
import {ValidationError} from "backoffice/api/ValidationError";
import {FormModel} from "./index";


interface Props {
  model: ReturnType<typeof useImmer<FormModel>>[0],
  setModel: ReturnType<typeof useImmer<FormModel>>[1],
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  error: ReturnType<typeof useState<ValidationError | null>>[0],
}

export const Basic: FC<Props> = ({model, setModel, handleChange, error}) => {

  // 融資先選択モーダル
  const [showLoanCustomerModal, setShowLoanCustomerModal] = useState(false);


  // 通貨桁数
  const currencyPrecision = Currency.find(model.currencyId)?.precision ?? 0;
  return (
      <>
        <Row className={'py-1'}>
          <FormCol inputId={'customer_id'}
                   title={'融資先'}
                   size={{xs: [4, 8], md: [2, 10]}}
                   error={error?.get('customer_id')}>
            <FormText placeholder={'融資先を選択してください'}
                      value={model?.customer?.name ?? ''}
                      readOnly
                      onClick={() => setShowLoanCustomerModal(true)}/>

            {/* 顧客選択モーダル */}
            <LoanCustomerModal show={showLoanCustomerModal}
                               onSelect={customer => {
                                 setModel(draft => {
                                   draft.customer_id = customer.id;
                                   draft.customer    = customer;
                                 });
                                 setShowLoanCustomerModal(false);
                               }}
                               onCancel={() => setShowLoanCustomerModal(false)}/>
          </FormCol>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Container className={'p-0'}>
              <Row className={'py-1'}>
                <FormCol inputId={'code'}
                         title={'コード'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('code')}>

                  <FormText placeholder={'1234'}
                            name={'code'}
                            value={model?.code ?? ''}
                            maxLength={4}
                            onChange={handleChange}/>
                </FormCol>
              </Row>

              <Row className={'py-1'}>
                <FormCol inputId={'name'}
                         title={'案件名称'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('name')}>

                  <FormText placeholder={'案件名称'}
                            name={'name'}
                            value={model?.name ?? ''}
                            maxLength={255}
                            onChange={handleChange}/>
                </FormCol>
              </Row>

              <Row className={'py-1'}>

                <FormCol inputId={'amount'}
                         title={'融資金額'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('amount')}>

                  <FormNumber placeholder={'融資金額'}
                              name={'amount'}
                              value={model?.amount ?? ''}
                              precision={currencyPrecision}
                              onChange={handleChange}
                              realtime/>
                </FormCol>
              </Row>

              <Row className={'py-1'}>
                <FormCol inputId={'currencyId'}
                         title={'通貨'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('currencyId')}>

                  <FormSelect name={'currencyId'}
                              value={model?.currencyId ?? ''}
                              onChange={handleChange}>
                    <option>-</option>
                    {Currency.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>

              <Row className={'py-1'}>

                {/* レガシー対応で表示用として残している */}
                <FormCol inputId={'bondCurrencyId'}
                         title={<s>債権通貨</s>}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('bondCurrencyId')}>

                  <FormSelect name={'bondCurrencyId'}
                              value={model?.bondCurrencyId ?? ''}
                              onChange={handleChange}
                              disabled>
                    <option value={''}>-</option>
                    {Currency.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>
            </Container>
          </Col>

          <Col xs={12} md={6}>
            <Container className={'p-0'}>
              <Row className={'py-1'}>
                <FormCol inputId={'manager_id'}
                         title={'営業者'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('manager_id')}>

                  <FormSelect name={'manager_id'}
                              value={model?.manager_id ?? ''}
                              onChange={handleChange}>
                    <option>-</option>
                    {LoanManager.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>

              <Row className={'py-1'}>
                <FormCol inputId={'status'}
                         title={'ステータス'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('status')}>

                  <FormSelect name={'status'}
                              value={model?.status ?? ''}
                              onChange={handleChange}>
                    <option>-</option>
                    {LoanProjectStatus.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>

              <Row className={'py-1'}>
                <FormCol inputId={'repaymentDelayStatusId'}
                         title={'元本返済状況'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('repaymentDelayStatusId')}>

                  <FormSelect name={'repaymentDelayStatusId'}
                              value={model?.repaymentDelayStatusId ?? ''}
                              onChange={handleChange}>
                    <option>-</option>
                    {LoanProjectDelayStatus.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>

              <Row className={'py-1'}>
                <FormCol inputId={'interestDelayStatusId'}
                         title={'利息返済状況'}
                         size={{xs: [4, 8], md: [4, 8]}}
                         error={error?.get('interestDelayStatusId')}>

                  <FormSelect name={'interestDelayStatusId'}
                              value={model?.interestDelayStatusId ?? ''}
                              onChange={handleChange}>
                    <option>-</option>
                    {LoanProjectDelayStatus.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
                  </FormSelect>
                </FormCol>
              </Row>
            </Container>
          </Col>
        </Row>


        <Row>
          <FormCol inputId={'region_category'}
                   title={'地域'}
                   size={{xs: [4, 8], md: [2, 4]}}
                   error={error?.get('region_category')}
                   className={'py-1'}>
            <FormSelect name={'region_category'}
                        value={model?.region_category ?? ''}
                        onChange={handleChange}>
              <option>-</option>
              {LoanProjectRegion.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
            </FormSelect>
          </FormCol>

          <FormCol inputId={'project_category'}
                   title={'カテゴリ'}
                   size={{xs: [4, 8], md: [2, 4]}}
                   error={error?.get('project_category')}
                   className={'py-1'}>
            <FormSelect name={'project_category'}
                        value={model?.project_category ?? ''}
                        onChange={handleChange}>
              <option>-</option>
              {LoanProjectCategory.all.map(elem => <option key={elem.id} value={elem.id}>{elem.value}</option>)}
            </FormSelect>
          </FormCol>
        </Row>

        <Row className={'py-1'}>
          <FormCol inputId={'use_of_funds'}
                   title={'資金使途'}
                   size={{xs: [4, 8], md: [3, 9]}}
                   error={error?.get('use_of_funds')}>

            <FormText placeholder={'資金使途'}
                      name={'use_of_funds'}
                      value={model?.use_of_funds ?? ''}
                      onChange={handleChange}/>
          </FormCol>
        </Row>

        <Row className={'py-1'}>
          <FormCol inputId={'monitoring'}
                   title={'モニタリング状況'}
                   size={{xs: [4, 8], md: [3, 9]}}
                   error={error?.get('monitoring')}>
            <FormText placeholder={'モニタリング状況'}
                      name={'monitoring'}
                      value={model?.monitoring ?? ''}
                      onChange={handleChange}/>
          </FormCol>
        </Row>

        <Row className={'py-1'}>
          <FormCol inputId={'accountingCustomerCode'}
                   title={'経理：取引先コード'}
                   size={{xs: [6, 6], md: [4, 3]}}
                   error={error?.get('accountingCustomerCode')}>

            <FormText placeholder={'仕訳出力時に使用'}
                      name={'accountingCustomerCode'}
                      value={model?.accountingCustomerCode ?? ''}
                      onChange={handleChange}/>
          </FormCol>
        </Row>

        <Row className={'py-1'}>
          <FormCol inputId={'accountingLoanItemCode'}
                   title={'経理：TK貸付 勘定科目コード'}
                   size={{xs: [6, 6], md: [4, 3]}}
                   error={error?.get('accountingLoanItemCode')}>

            <FormText placeholder={'161010000'}
                      name={'accountingLoanItemCode'}
                      value={model?.accountingLoanItemCode ?? ''}
                      onChange={handleChange}/>
          </FormCol>
        </Row>
      </>
  );
}
