import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {MembersTradesPage} from "./Trades";
import {MembersNoticeMngPage} from "./NoticeMng";
import {MembersDepositPage} from "./Deposit";


export const MembersPage: FC = () => {
  return (
      <Routes>
        <Route path="deposit/*" element={<MembersDepositPage/>}/>
        <Route path="noticemng/*" element={<MembersNoticeMngPage/>}/>
        <Route path="trades/*" element={<MembersTradesPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


