import {FormEventHandler, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";
import {confirmSignIn} from 'aws-amplify/auth'
import {FormPassword, FormText, useLoader, useMessage} from "backoffice/ui/Components";


export const NewPasswordRequiredPage = () => {

  const Loader = useLoader(),
        MessageModal = useMessage(),
        navigate = useNavigate(),
        {username} = useParams();

  useEffect(() => {
    document.title = 'パスワードリセット｜backoffice';

    if (!username) {
      MessageModal.show('エラー', 'ユーザー名が確認出来ません。',
          () => navigate('/'));
    }
  }, []);


  // フォームサブミット
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    const form: HTMLFormElement = event.currentTarget;

    // 入力値チェック
    if (!form.checkValidity()) {
      return MessageModal.show('エラー', '入力内容をご確認ください');
    }

    const newPassword = form.new_password.value;

    const result = await Loader.task(
        '通信中です',
        async () => {
          const {isSignedIn} = await confirmSignIn({
            challengeResponse: newPassword,
          });

          return isSignedIn;
        }, 500);


    if (result) {
      MessageModal.show('完了通知', 'パスワードを変更しました', () => window.location.href = '/');
    } else {
      MessageModal.show('エラー');
    }
  }, []);

  return (
      <Form onSubmit={handleSubmit} style={{marginTop: 150}}>
        <Row style={{marginBottom: 50}}>
          <Col className="text-center">
            <big>パスワードの変更</big>
          </Col>
        </Row>

        <Row>
          <Col sm={{span: 4, offset: 4}}>
            ログインを行うためにはパスワードを変更する必要があります。
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}} style={{marginBottom: 50}}>
            パスワードは8〜255文字で、大文字・小文字・数字・記号をそれぞれ1文字以上含める必要があります。
          </Col>
        </Row>

        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="username">
              <FormText name="username" type="text" defaultValue={username} placeholder="ユーザー名" required/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Form.Group controlId="new_passwd">
              <FormPassword name="new_password" placeholder="新しいパスワード" required minLength={8} maxLength={255} autoComplete="new-password"/>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col sm={{span: 4, offset: 4}} style={{padding: 5}}>
            <Button variant="dark" type="submit" className={'w-100'}>パスワード変更</Button>
          </Col>
        </Row>
      </Form>
  );
};

