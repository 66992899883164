import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '日本',
  },
  {
    id   : 2,
    value: 'カナダ/米国'
  },
  {
    id   : 3,
    value: '中国'
  },
  {
    id   : 4,
    value: 'アジア/オセアニア'
  },
  {
    id   : 5,
    value: '欧州'
  },
  {
    id   : 6,
    value: 'アフリカ/中南米/その他'
  }
] as const;


/**
 * タイプ
 */
export type LoanProjectRegion = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * 公開
 */
export const LoanProjectRegion = CodeClass;
