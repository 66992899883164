import {FC, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Table} from "react-bootstrap";
import {Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {useSearchParams} from "react-router-dom";
import {NoticeInfoModel} from "backoffice/api/RDS/Models/NoticeMng/NoticeInfoModel";


export const MembersNoticeMngNotificationListPage: FC = () => {

  const Loader                          = useLoader(),
        Message                         = useMessage(),
        Navigate                        = useNavigate(),
        [searchParams, setSearchParams] = useSearchParams(),
        trpc                            = useTRPC();

  // ページ / サイズ
  const page = Math.max(Number(searchParams.get('page')) || 0, 1),
        size = Math.min(Number(searchParams.get('size')) || 100, 100);

  // ステート
  const [result, setResult] = useState({total: 1, results: [] as NoticeInfoModel[]});

  // 初回実行
  useEffect(() => {
    document.title = '配信状況一覧 | プッシュ通知管理';

    // 初期検索
    doList(page).then();
  }, []);

  // リスト読み込み
  const doList: (page: number) => Promise<void> = useCallback(async (page) => {

    // ページf
    setSearchParams(params => {
      if (page === 1) {
        params.delete('page');
      } else {
        params.set('page', String(page));
      }
      return params;
    })

    Loader.task(async () => {
      try {
        const {results, total} = await trpc.noticemng.notification.list.query({page, size});
        setResult({results: NoticeInfoModel.inject(results), total});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);

  /**
   * CSVダウンロード
   */
  const handleDownload = () => {
    Loader.task(async () => {
      try {
        const result = await trpc.noticemng.notification.csv.query();
        location.href = result.url;

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>プッシュ通知管理</Breadcrumb.Item>
            <Breadcrumb.Item active>配信状況一覧</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container small'}>
          <Table bordered striped>
            <colgroup>
              <col span={1} style={{minWidth: '10rem'}}/>
              <col span={1} style={{minWidth: '8rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
              <col span={1} style={{minWidth: '8rem'}}/>
              <col span={1}/>
              <col span={1} style={{minWidth: '6rem'}}/>
            </colgroup>
            <thead>
            <tr>
              <th style={{textAlign: 'left'}}>通知名</th>
              <th style={{textAlign: 'center'}}>配信予定日時</th>
              <th style={{textAlign: 'center'}}>配信状況<br/>(正常 / 失敗 / 総数)</th>
              <th style={{textAlign: 'center'}}>状態</th>
              <th style={{textAlign: 'center'}}>詳細</th>
              <th style={{textAlign: 'center'}}>&nbsp;</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.results.length === 0 && (
                <tr>
                  <td colSpan={6}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.results.map(notice => (
                <tr key={notice.notice_id}>
                  <td style={{textAlign: 'left', verticalAlign: 'middle'}}>{notice.notice_name}</td>
                  <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                    {notice.plan_delivery_date.clone().toYmd()}<br/>
                    {notice.plan_delivery_date.clone().toHms()}
                  </td>
                  <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                    {notice.delivery_status_value}
                    {notice.delivery_status === 2 && (
                        <>
                          <br/>
                          <span>
                            {String(notice.delivery_send ?? 0).withComma} &nbsp;/&nbsp;
                            {String(notice.delivery_faild ?? 0).withComma} &nbsp;/&nbsp;
                            {String(notice.delivery_total ?? 0).withComma}
                          </span>
                        </>
                    )}
                  </td>
                  <td style={{textAlign: 'center', verticalAlign: 'middle'}}>{notice.send_status_value}</td>
                  <td style={{textAlign: 'left'}}>
                    <TextDiv width={'20rem'}>
                      {notice.error_detail && JSON.stringify(notice.error_detail)}
                    </TextDiv>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <Button className={'mx-1'}
                            onClick={() => Navigate(`./${notice.notice_id}`)}>
                      詳細
                    </Button>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={page}
                   pageSize={size}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => doList(page)}/>
          </div>
        </div>

        {/* フッター */}
        <footer>
          <div className={'container-fluid'} style={{backgroundColor: "rgba(80,80,80,0.5)"}}>
            <div className={'d-flex justify-content-end'}>
              <Button variant='primary' className={'m-2'}
                      onClick={() => Navigate('./new')}>
                配信予約登録
              </Button>
              <Button variant='primary' className={'m-2 '}
                      onClick={handleDownload}>
                CSVダウンロード
              </Button>
            </div>
          </div>
        </footer>
      </>
  );
}
