import Big from "big.js";
import {AbstractCode, CodeType} from "./abstract";
import {CurrencyPair} from "src/codes/currencyPair";

interface ExchangeType {
  symbol: string
  ctSpread: string // セントラル短資スプレッド
  cbSpread: string // クラウドバンクスプレッド（利益）
  toPair: CurrencyPair
  fromPair: CurrencyPair
}

/**
 * コードリスト
 */
const CodeList = [
  {
    id           : 1,
    value        : '日本円',
    key          : 'JPY',
    accountingKey: null,
    prefix       : '¥',
    precision    : 0,
    flag         : '🇯🇵'
  },
  {
    id           : 2,
    value        : '米ドル',
    key          : 'USD',
    accountingKey: 'MIZUHO-USD',
    prefix       : 'US$',
    precision    : 2,
    flag         : '🇺🇸',
    exchange     : {
      symbol  : 'USD/JPY',
      ctSpread: '0.1', // セントラル短資スプレッド
      cbSpread: '0.1', // クラウドバンクスプレッド（利益）
      toPair  : CurrencyPair['日本円 → 米ドル'],
      fromPair: CurrencyPair['米ドル → 日本円']
    } as ExchangeType,
  },
  {
    id           : 3,
    value        : '豪ドル',
    key          : 'AUD',
    accountingKey: 'MIZUHO-AUD',
    prefix       : 'AU$',
    precision    : 2,
    flag         : '🇦🇺',
    exchange     : {
      symbol  : 'AUD/JPY',
      ctSpread: '0.1', // セントラル短資スプレッド
      cbSpread: '0.1', // クラウドバンクスプレッド（利益）
      toPair  : CurrencyPair['日本円 → 豪ドル'],
      fromPair: CurrencyPair['豪ドル → 日本円']
    } as ExchangeType,
  }
] as const;


/**
 * タイプ
 */
export type Currency = typeof CodeList[number];

/**
 * 外貨タイプ
 */
export type FCurrency = Extract<Currency, { exchange: ExchangeType }>;


/**
 * 通貨コード定義
 *
 * コードクラス生成時に指定することで、CodeList定義の漏れを検出
 */
export class CurrencyClass implements CodeType<number> {
  declare readonly id: Currency['id']
  declare readonly value: string
  declare readonly key: Currency['key']
  declare readonly prefix: string
  declare readonly suffix: string
  declare readonly precision: number
  declare readonly flag: string
  declare readonly exchange?: ExchangeType

  /**
   *  会計上のレート用キー
   *
   *  外貨ファンドの仕訳伝票生成時
   */
  declare readonly accountingKey: string | null;


  /**
   * 通貨フォーマット
   *
   * @param value
   * @param ifEmpty
   */
  format(value: undefined | null | number | string | Big, ifEmpty: string = '---') {
    if (!value) {
      return ifEmpty;
    }

    const str = typeof value === 'number' ? String(value).toNumber(this.precision)
                                          : typeof value === 'string' ? value.toNumber(this.precision)
                                                                      : value.toFixed(this.precision);
    return str?.withComma ?? ifEmpty;
  }

  /**
   * @param value
   * @param ifEmpty
   */
  withPrefix(value: undefined | null | number | string | Big, ifEmpty: string = '---') {
    return `${this.prefix} ${this.format(value, ifEmpty)}`;
  }

  /**
   * @param value
   * @param ifEmpty
   */
  withSuffix(value: undefined | null | number | string | Big, ifEmpty: string = '---') {
    return `${this.format(value, ifEmpty)} ${this.key}`;
  }
}


/**
 * コードクラス拡張
 */
class CurrencyCode extends AbstractCode<CurrencyClass> {

  /**
   * 外貨リスト
   */
  get fc(): Required<CurrencyClass>[] {
    const result: Required<CurrencyClass>[] = [];
    for (const code of this.all) {
      if (typeof code.exchange !== 'undefined') {
        result.push(code as Required<CurrencyClass>);
      }
    }
    return result;
  }
}

/**
 * コードクラス化
 */
const CodeClass = new CurrencyCode(CodeList.map(elem => Object.assign(new CurrencyClass(), elem)));

/**
 * キーアクセス
 */
const NameAccessor = CodeClass.all.reduce((result, elem) => {
  result[elem.key as Currency['key']] = elem;
  return result;
}, {} as Record<Currency['key'], CurrencyClass>);


/**
 * 公開
 */
export const Currency = Object.assign(CodeClass, NameAccessor);


