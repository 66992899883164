import {ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useMemo, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row, Table} from "react-bootstrap";
import {useImmer} from "use-immer";
import {FormNumber, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import type {ModelObjectRCSV, StringPropsOf} from "backoffice/api/types";
import {Currency, LegacyLoanTradeType} from "src/codes";
import Big from "big.js";
import {Helmet} from "react-helmet"
import {LoanManager} from "backoffice/api/RDS";


// ページサイズ
const pageSize = 50;

// 先月１日
const since = new Date();
since.addMonth(-1).setDate(1);

// 検索条件 初期値
const defaultSearchParam = {
  customer: '',
  project : '',
  since   : since.toYmd(),
  until   : '',
  page    : 0,
  pageSize
};

export const LoanManagerLegacyTradeListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [search, setSearch]       = useImmer(defaultSearchParam);
  const [result, setResult]       = useState({total: 1, results: [] as ModelObjectRCSV<LoanManager.LegacyTrade>[]});
  const [selection, setSelection] = useImmer({} as Record<number, ModelObjectRCSV<LoanManager.LegacyTrade>>);

  // 検索条件 編集
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {name, value}}) => {
    setSearch(draft => void (draft[name as StringPropsOf<typeof defaultSearchParam>] = value));
  }, []);


  // エンターキー入力
  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.key === 'Enter' && doList(1);
  };

  // リスト読み込み
  const doList: (page: number) => Promise<void> = useCallback(async (page) => {
    Loader.task(async () => {
      const work = {...search, page}
      setSearch(work);

      try {
        const {results, total} = await trpc.loan.legacyTrade.list.query(work);

        setResult({results, total});
        setSelection({});

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [search]);

  // 選択合計
  const total = useMemo(() => {
    return Object.values(selection).reduce((total, trade) => {
      return trade.details.reduce((total, detail) => {
        return total.add(detail.amount);
      }, total);
    }, new Big(0));
  }, [selection]);

  return (
      <>
        <Helmet>
          <title>融資取引一覧</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item active>取引一覧（レガシー）</Breadcrumb.Item>
          </Breadcrumb>

          <Form className={'my-5'}>
            <Row>
              <Col md={{span: 6}} lg={{span: 4, offset: 2}} style={{padding: 5}}>
                <Form.Group controlId="customer">
                  <Form.Label>融資先名称</Form.Label>
                  <FormText value={search.customer} name={'customer'}
                            onChange={handleChange}
                            onKeyDown={handleEnter}
                            placeholder={'融資先名称'}/>
                </Form.Group>
              </Col>

              <Col md={{span: 6}} lg={{span: 4}} style={{padding: 5}}>
                <Form.Group controlId="project">
                  <Form.Label>案件名称</Form.Label>
                  <FormText value={search.project} name={'project'}
                            onChange={handleChange}
                            onKeyDown={handleEnter}
                            placeholder={'案件名称'}/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={{span: 6}} lg={{span: 4, offset: 2}} style={{padding: 5}}>
                <Form.Group controlId="since">
                  <Form.Label>入金日 期間開始</Form.Label>
                  <FormText value={search.since} name={'since'} type={'date'}
                            onChange={handleChange}
                            placeholder={'期間開始'}/>
                </Form.Group>
              </Col>

              <Col md={{span: 6}} lg={{span: 4}} style={{padding: 5}}>
                <Form.Group controlId="until">
                  <Form.Label>入金日 期間終了</Form.Label>
                  <FormText value={search.until} name={'until'} type={'date'}
                            onChange={handleChange}
                            placeholder={'期間終了'}/>
                </Form.Group>
              </Col>
            </Row>

            <Row style={{marginTop: 50}}>
              <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                <Button variant="primary" className={'w-100'} onClick={() => doList(1)}>
                  検索する
                </Button>
              </Col>
            </Row>
          </Form>

          <Table bordered>
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th>
                融資先<br/>
                案件名称
              </th>
              <th className={'text-center'} style={{minWidth: '6em'}}>入金日</th>
              <th className={'text-center'} colSpan={2} style={{minWidth: '14em'}}>計算期間</th>
              <th>種別</th>
              <th className={'text-end'}>日本円</th>
              <th className={'text-end'}>外貨</th>
              <th className={'text-center'} style={{width: '5em'}}>通貨</th>
              <th>メモ</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.results.length === 0 && (
                <tr>
                  <td colSpan={10}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.results.length > 0 && (result.results.map(trade => {
                      const checked = trade.id in selection,
                            bgColor = checked ? 'bg-light' : '';

                      return (
                          <>
                            <tr key={trade.id} className={bgColor}>
                              <td rowSpan={trade.details.length}
                                  className={'cursor-pointer'}
                                  onClick={() => setSelection(draft => void (checked ? delete draft[trade.id] : draft[trade.id] = trade))}>
                                <input type={'checkbox'} checked={checked}/>
                              </td>
                              <td rowSpan={trade.details.length}>
                                {trade.project.customer.name}<br/>
                                {trade.project.name}
                              </td>
                              <td rowSpan={trade.details.length}
                                  className={'text-center'}>
                                {trade.payment_date?.toYmd() ?? '-'}
                              </td>
                              <TradeRow trade={trade} detail={trade.details[0]}/>
                            </tr>

                            {trade.details.slice(1).map(detail => (
                                <tr key={`${trade.id}-${detail.no}`} className={bgColor}>
                                  <TradeRow trade={trade} detail={detail}/>
                                </tr>
                            ))}
                          </>
                      )
                    }
                )
            )}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={search.page}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => doList(page)}/>
          </div>
        </div>

        {/* フッター */}
        <footer>
          <div className={'container-fluid bg-light opacity-75'}>
            <div className={'d-flex justify-content-end m-1 opacity-100'}>
              <FormNumber align={'end'}
                          value={total.toFixed(3)}
                          readOnly/>
            </div>
          </div>
        </footer>
      </>
  );
}

const TradeRow: FC<{ trade: ModelObjectRCSV<LoanManager.LegacyTrade>, detail: ModelObjectRCSV<LoanManager.LegacyTradeDetail> }> = ({trade, detail}) => (
    <>
      <td className={'text-center'}>{detail?.since?.toYmd() ?? '-'}</td>
      <td className={'text-center'}>{detail?.until?.toYmd() ?? '-'}</td>
      <td>{LegacyLoanTradeType.find(detail?.type).value}</td>
      <td className={'text-end'}>{detail?.amount.toFixed(0).withComma ?? '0'}</td>
      <td className={'text-end'}>{detail?.amount_fc?.toFixed(3).withComma ?? '0'}</td>
      <td className={'text-center'}>{Currency.find(trade.project.bondCurrencyId).value}</td>
      <td>{detail?.memo}</td>
    </>
);
