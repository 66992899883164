import React, {FC, useCallback} from "react";
import {FunctionCode, FunctionCodeType} from "src/codes/function";
import {Table} from "react-bootstrap";
import {Updater} from "use-immer";
import {RoleType} from "backoffice/api/AppRouter/User/Role";


export const PermissionTable: FC<{ role: RoleType, setRole: Updater<RoleType> }> = ({role, setRole}) => {
  const handleChange = useCallback((funcId: string, action: string) => {
    setRole(draft => {
      const pIndex = draft.permissions.findIndex(perm => perm.funcId === funcId);
      if (pIndex === -1) {
        draft.permissions.push({funcId, actions: [action]});
      } else {
        const aIndex = draft.permissions[pIndex].actions.indexOf(action);
        if (aIndex === -1) {
          draft.permissions[pIndex].actions.push(action);
        } else {
          draft.permissions[pIndex].actions.splice(aIndex, 1);
        }
      }
    });
  }, []);

  return (
      <Table striped bordered hover className={'mb-0'}>
        <tbody className={'border border-top-1'}>
        {FunctionCode.all.map(code => <PermissionRow key={code.id}
                                                     code={code}
                                                     value={role?.permissions?.find(el => el.funcId === code.id)?.actions ?? []}
                                                     handleChange={handleChange}/>)}

        </tbody>
      </Table>
  )
}


const PermissionRow: FC<{ code: FunctionCodeType, value: string[], handleChange: (funcId: string, action: string) => void }> = React.memo(({
  code,
  value,
  handleChange
}) => {
  return (
      <tr>
        <td className={"align-middle"}>{code.value}</td>
        <td>
          {code.actions.map((action) => (
              <label className={'m-1'} key={action}>
                <input type={'checkbox'}
                       className={'me-1'}
                       onChange={() => handleChange(code.id, action)}
                       checked={value.includes(action)}/>
                {action}
              </label>
          ))}
        </td>
      </tr>
  );
});


