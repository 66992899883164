import React, {FC} from "react";
import {useTRPC} from "backoffice/ui/App";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";

export const MenuPage: FC = () => {

  const Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();


  return (
      <>
        <Helmet>
          <title>GMOあおぞら銀行連携</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>GMOあおぞら銀行連携</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'pt-5'}>
          <Row>
            <Col xs={{span: 12}} md={{span: 8, offset: 2}}>
              <Container>
                <Row className={'pb-3'}>
                  <Col span={6}>
                    <Button className={'w-100'}
                            onClick={async () => {
                              const {nonce, uri} = await tRPC.banking.gmoAozoraNet.authorization.query();
                              console.log('nonce', nonce);

                              localStorage.setItem('gmo-aozora-net-auth-nonce', nonce);
                              location.href = uri;
                            }}>
                      認証開始
                    </Button>
                  </Col>

                  <Col span={6}>
                    <Button className={'w-100'}
                            onClick={async () => {
                              if (confirm('トークンを更新しますか？')) {
                                try {
                                  await tRPC.banking.gmoAozoraNet.refreshToken.mutate();
                                  Message.show('トークンを更新しました');

                                } catch (err) {
                                  Message.error(err);
                                }
                              }
                            }}>
                      リフレッシュトークン
                    </Button>
                  </Col>
                </Row>

                <Row className={'pb-3'}>
                  <Col span={6}>
                    <Button className={'w-100'}
                            onClick={() => Navigate('va')}>
                      仮想口座管理
                    </Button>
                  </Col>

                  <Col span={6}>
                    <Button className={'w-100'}
                            onClick={() => Navigate('deposit')}>
                      手動入金取り込み
                    </Button>
                  </Col>
                </Row>

                <Row className={'pb-3'}>
                  <Col span={6}>
                    <Button className={'w-100'}
                            onClick={() => Navigate('subscribe')}>
                      配信管理
                    </Button>
                  </Col>

                  <Col span={6}>
                    <Button className={'w-100'}
                            variant={'secondary'}
                            onClick={() => tRPC.banking.gmoAozoraNet.loginUri.query().then(uri => window.open(uri))}>
                      GMOあおぞらネット銀行
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
  );
}
