import {AbstractCode} from "src/codes/abstract";
import {AccountItemCodeType} from "src/codes/accounting/type";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 100,
    value: '匿名組合出資金',
    memo : 'ファンド運用開始',
    type : 'credit',
  },
  {
    id   : 101,
    value: '現預金',
    type : 'debit',
  },
  {
    id   : 102,
    value: '現預金-受取利息',
    memo : '投資家に分配する為の現預金、アロケーション利用不可',
    type : 'debit',
  },
  {
    id   : 109,
    value: '両替',
    type : 'debit',
  },
  {
    id   : 110,
    value: '貸付金',
    memo : '返済期限が1年後を超えるか否かで長期/短期が分かれる、詳細はアロケーションから取得する必要がある',
    type : 'debit',
  },
  {
    id   : 111,
    value: '受取利息',
    memo : '売上高',
    type : 'credit',
  },
  {
    id   : 112,
    value: '為替差益',
    memo : '売上高',
    type : 'credit',
  },
  {
    id   : 113,
    value: '為替差損',
    type : 'debit',
  },
  {
    id   : 120,
    value: '分配金',
    type : 'debit',
  },
  {
    id   : 121,
    value: '営業者報酬',
    type : 'debit',
  },
  {
    id   : 131,
    value: '預）分配金',
    memo : '預り金、n月発生の受取利息は、n+1月の分配償還時に、n月末の預かりとして計上され、n+1月に分配される',
    type : 'credit',
  },
  {
    id   : 132,
    value: '預）分配残',
    memo : 'ファンド雑損 (aka "雑益2") ',
    type : 'credit',
  },
  {
    id   : 139,
    value: '預）源泉所得税',
    memo : '預り金、n月発生の受取利息は、n+1月の分配償還時に、n月末の預かりとして計上され、n+2月に納付する',
    type : 'credit',
  },
  {
    id: 191,
    value: '損益分配金',
    type: 'debit'
  }
] as const;


/**
 * タイプ
 */
export type FundItem = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode<AccountItemCodeType>(CodeList);


/**
 * 名称アクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<typeof CodeList[number]['value'], FundItem>);


/**
 * 公開
 */
export const FundItem = Object.assign(CodeClass, NameAccessor);
