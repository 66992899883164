import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: "日時公開",
  }, {
    id   : 2,
    value: "トリガー公開",
  }
] as const;


/**
 * タイプ
 */
export type PublishTrigger = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<PublishTrigger['value'], PublishTrigger>);


/**
 * 公開
 */
export const PublishTrigger = Object.assign(CodeClass, NameAccessor);
