import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: "未処理",
  }, {
    id   : 2,
    value: "処理中",
  }, {
    id   : 9,
    value: "完了"
  }, {
    id   : 999,
    value: "不要"
  }
] as const;


/**
 * タイプ
 */
export type ExchangeStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<ExchangeStatus['value'], ExchangeStatus>);


/**
 * 公開
 */
export const ExchangeStatus = Object.assign(CodeClass, NameAccessor);
