import {Constructor} from "backoffice/api/types";
import {ReactModel} from "backoffice/ui/utils/react/model";
import {UserInfoModel} from "backoffice/api/RDS/Models/NoticeMng/UserInfoModel";

/**
 * noticemng.device_info
 *
 * クライアント側で利用するモデルクラス
 */
export class DeviceInfoModel extends DeviceInfoMixin(ReactModel) {

  // クライアント側のみで使用するコード
  static get modelMap() {
    return {
      user: UserInfoModel
    };
  }
}

/**
 * 共通機能機能 Mixin関数
 *
 * @param Base
 * @constructor
 */
export function DeviceInfoMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {

    // 共通コード
    declare device_id: string;
    declare platform: string;
    declare token: string;
    declare endpoint_arn: string;
    declare permitted: boolean;
    declare user_id: string;
    declare create_at: Date;
    declare update_at?: Date;

    // リレーション
    declare user: UserInfoModel;


    get permittedValue(): string {
      return this.permitted ? "許諾" : "未許諾";
    }
  };
}


