import {faTrash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FormNumber, FormSelect, FormText, Nl2Br} from "backoffice/ui/Components";
import {ChangeEventHandler, FC, useCallback} from "react"
import {Collapse, Form} from "react-bootstrap"
import {FormModel, newDetail} from "./index";
import {Updater} from "use-immer"
import {ValidationError} from "backoffice/api/ValidationError";
import {LoanTradeType} from "src/codes";
import type {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";

export const DetailTable: FC<{
  project: LoanProjectType | null,
  model: FormModel,
  setModel: Updater<FormModel>,
  error: ValidationError | null
}> = ({project, model, setModel, error}) => {

  const precision = project?.currency?.precision || 0;

  // 編集
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = useCallback(({
    currentTarget
  }) => {
    const {name, dataset} = currentTarget,
          index           = Number(dataset.index),
          value           = (currentTarget instanceof HTMLInputElement && currentTarget.type === 'checkbox')
                            ? currentTarget.checked
                            : currentTarget.value

    setModel(draft => {
      if (!draft) {
        return;
      }

      // 最終行の編集
      if (draft.details?.length === index + 1) {

        // 削除は無視
        if (name === 'deleted') {
          return;
        }
        // それ以外の編集は行の追加
        draft.details?.push(newDetail());
      }

      // 分配データリセット
      if (name === 'typeId') {
        draft.details[index].dividends = {};
      }

      // @ts-expect-error value can be boolean / string
      draft.details[index][name] = value;
    })
  }, [setModel]);

  if (!project || !model) {
    return <div>...</div>
  }

  return (
      <div id={'list-table'} className={'d-flex flex-column'}>
        <div>
          <ul className={'d-flex m-0 p-0'}>
            <li className={'p-1 text-center deleted'}>
              <FontAwesomeIcon icon={faTrash}/>
            </li>
            <li className={'p-1 text-center fw-bolder type'}>種別</li>
            <li className={'p-1 text-center fw-bolder dateX2'}>実績日 / 計算期間</li>
            <li className={'p-1 text-center fw-bolder amount'}>金額</li>
            <li className={'p-1 text-center fw-bolder flex-grow-1'}>摘要</li>
            <li className={'p-1 text-center fw-border'}>&nbsp;</li>
          </ul>
        </div>


        {model?.details?.map((detail, index) => {
          const tradeType = LoanTradeType.find(detail.typeId);
          return (
              <Collapse in={!detail.deleted}
                        key={detail.id ?? detail.uuid ?? ''}>

                <div className={`border-top`}>
                  <ul className={'d-flex m-0 p-0'}>
                    <li className={'p-1 text-center deleted'}>
                      <label className={'d-flex justify-content-center m-0 h-100'}>
                        <input data-index={index}
                               type={'checkbox'}
                               name={'deleted'}
                               className={`align-self-center`}
                               checked={detail.deleted}
                               onChange={handleChange}
                               disabled={model?.isConfirmed}/>
                      </label>
                    </li>
                    <li className={'p-1 type'}>
                      <FormSelect data-index={index}
                                  name={'typeId'}
                                  value={detail.typeId ?? ''}
                                  size={'sm'}
                                  onChange={handleChange}
                                  disabled={model?.isConfirmed}>
                        <option>-</option>
                        {LoanTradeType.all.map(elem => <option key={elem.id} value={elem.id} disabled={elem.disabled}>{elem.value}</option>)}
                      </FormSelect>

                      <Form.Text className="text-danger">
                        <Nl2Br text={error?.get(`details.${index}.typeId`)}/>
                      </Form.Text>
                    </li>

                    {/* 実績日パターン */}
                    {tradeType?.dateType === "single" && (
                        <li className={'p-1 text-center dateX2'}>
                          <FormText data-index={index}
                                    type={'date'}
                                    name={'since'}
                                    value={detail.since ?? ''}
                                    size={'sm'}
                                    onChange={handleChange}
                                    disabled={model?.isConfirmed}/>

                          <Form.Text className="text-danger">
                            <Nl2Br text={error?.get(`details.${index}.since`)}/>
                          </Form.Text>
                        </li>
                    )}

                    {/* 計算期間パターン */}
                    {tradeType?.dateType === "single" || (
                        <>
                          <li className={'p-1 text-center date'}>
                            <FormText data-index={index}
                                      type={'date'}
                                      name={'since'}
                                      value={detail.since ?? ''}
                                      size={'sm'}
                                      onChange={handleChange}
                                      disabled={model?.isConfirmed}/>

                            <Form.Text className="text-danger">
                              <Nl2Br text={error?.get(`details.${index}.since`)}/>
                            </Form.Text>
                          </li>


                          <li className={'p-1 text-center date'}>

                            <FormText data-index={index}
                                      type={'date'}
                                      name={'until'}
                                      value={detail.until ?? ''}
                                      size={'sm'}
                                      onChange={handleChange}
                                      disabled={model?.isConfirmed}/>

                            <Form.Text className="text-danger">
                              <Nl2Br text={error?.get(`details.${index}.until`)}/>
                            </Form.Text>

                          </li>
                        </>
                    )}

                    <li className={'p-1 amount'}>
                      <FormNumber data-index={index}
                                  name={'amount'}
                                  value={detail.amount ?? ''}
                                  size={'sm'}
                                  align={'end'}
                                  precision={precision}
                                  realtime
                                  onChange={handleChange}
                                  disabled={model?.isConfirmed}/>

                      <Form.Text className="text-danger">
                        <Nl2Br text={error?.get(`details.${index}.amount`)}/>
                      </Form.Text>
                    </li>
                    <li className={'p-1 text-center flex-grow-1'}>
                      <FormText data-index={index}
                                name={'memo'}
                                value={detail.memo ?? ''}
                                size={'sm'}
                                onChange={handleChange}
                                disabled={model?.isConfirmed}/>

                      <Form.Text className="text-danger">
                        <Nl2Br text={error?.get(`details.${index}.memo`)}/>
                      </Form.Text>
                    </li>
                  </ul>
                </div>
              </Collapse>
          );
        })}
      </div>
  )
}

