import {FC, useCallback, MouseEventHandler} from 'react';
import {Pagination} from 'react-bootstrap';

interface Props {
  activePage: number,
  pageSize: number,
  totalCount: number,
  displaySize: number,

  onChange?: (arg: { prev: number, page: number }) => void
}

interface PageItemType {
  label: string,
  page: number
}

export const Pager: FC<Props> = ({activePage, pageSize, totalCount, displaySize, onChange}) => {

  // クリックイベント
  const handleClick: MouseEventHandler<HTMLElement> = useCallback(({currentTarget}) => {
    if (onChange) {
      onChange({prev: activePage, page: Number(currentTarget.dataset.page)});
    }
  }, [onChange]);


  if (totalCount <= pageSize) {
    return null;
  }
  let pages = Math.ceil(totalCount / pageSize),
      f     = Number(Math.max(1, activePage - displaySize)),
      e     = Number(Math.min(pages, activePage + displaySize)),
      list  = new Array(e - f + 1);


  // リスト初期化
  for (let i = 0, v = f; v <= e; i++, v++) {
    list[i] = {
      label: v,
      page : String(v).withComma,
    }
  }

  // 先端処理
  if (f > 2) {
    list.unshift({
      label: 1,
      page : 1
    }, {
      label: '...',
      page : Number(f) - 1
    });
  } else if (f === 2) {
    list.unshift({
      label: '1',
      page : 1
    });
  }

  // 末端処理
  let p = pages - e;
  if (p > 1) {
    list.push({
      label: '...',
      page : e + 1
    }, {
      label: String(pages).withComma,
      page : pages
    });
  } else if (p === 1) {
    list.push({
      label: String(pages).withComma,
      page : pages
    });
  }

  return (
      <Pagination>
        <Pagination.Prev onClick={handleClick} data-page={Math.max(1, activePage - 1)}/>
        {list.map(item => <PageItem key={`${item.label}`} item={item} active={item.page == activePage} handleClick={handleClick}/>)}
        <Pagination.Next onClick={handleClick} data-page={Math.min(pages, activePage + 1)}/>
      </Pagination>
  );
}


const PageItem: FC<{ item: PageItemType, active: boolean, handleClick: MouseEventHandler<HTMLElement> }> = ({item, active, handleClick}) => {
  return (
      <Pagination.Item onClick={handleClick}
                       active={active}
                       data-page={item.page}>
        <span>{item.label}</span>
      </Pagination.Item>
  );
}
