import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {AccountingJournalPage} from "backoffice/ui/Pages/Accounting/Journal";

export const AccountingPage: FC = () => {
  return (
      <Routes>
        <Route path="journal/*" element={<AccountingJournalPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


