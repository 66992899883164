import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {id: 0, value: '登録申請前'},
  {id: 100, value: '仮会員'},
  {id: 200, value: '契約合意・本人確認中'},
  {id: 300, value: '本人確認済み・審査待ち'},
  {id: 350, value: '審査合格・会員新規登録・取引凍結'},
  {id: 400, value: '審査合格・会員登録・取引凍結'},
  {id: 500, value: '住居確認済み・取引可能'},
  {id: 600, value: '変更申請中'},
  {id: 650, value: '変更申請中（画像確認必要）'},
  {id: 700, value: 'マイナンバーの登録申請中'},
  {id: 900, value: '承認却下（除籍含む）'},
] as const;


/**
 * タイプ
 */
export type MemberProcessStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<MemberProcessStatus['value'], MemberProcessStatus>);


/**
 * 公開
 */
export const MemberProcessStatus = Object.assign(CodeClass, NameAccessor);
