import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {LoanManagerProjectListPage} from "./List";
import {LoanManagerProjectFormPage} from "./Form";
import {LoanManagerProjectAllocationPage} from "./Allocation";
import {LoanManagerProjectTradePage} from "./Trade";


export const LoanManagerProjectPage: FC = () => {
  return (
      <Routes>
        <Route index element={<LoanManagerProjectListPage/>}/>
        <Route path=":projectId" element={<LoanManagerProjectFormPage/>}/>
        <Route path=":projectId/allocation/*" element={<LoanManagerProjectAllocationPage/>}/>
        <Route path=":projectId/trade/*" element={<LoanManagerProjectTradePage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


