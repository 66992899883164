import {Constructor} from "backoffice/api/types";
import {ReactModel} from "backoffice/ui/utils/react/model";


/**
 * DeviceInfo
 *
 * クライアント側で利用するモデルクラス
 */
export class UserInfoModel extends UserInfoMixin(ReactModel) {
  // クライアント側のみで使用するコード
}

/**
 * 共通機能機能 Mixin関数
 *
 * @param Base
 * @constructor
 */
export function UserInfoMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {

    // 共通コード
    declare user_id: string;
    declare last_login_date: Date;
    declare linked_date: Date;
    declare create_at: Date;
    declare update_at?: Date;
  };
}


