import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '予定',
    memo : '適当に入れておける（入金日の入力なし）',
  }, {
    id   : 2,
    value: '実績',
    memo : '実際に入金がされたことを記録（入金日の入力がされる）',
  },
] as const;


/**
 * タイプ
 */
export type LegacyLoanTradeStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<LegacyLoanTradeStatus['value'], LegacyLoanTradeStatus>);


/**
 * 公開
 */
export const LegacyLoanTradeStatus = Object.assign(CodeClass, NameAccessor);
