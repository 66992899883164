/**
 * クライアント側（React側）で使用するモデル
 */
export class ReactModel implements Record<string, any> {

  static get modelMap(): Record<string, typeof this> {
    return {};
  }

  /**
   * 配列の一括変換
   *
   * @param list
   *
   */
  static inject<T extends typeof ReactModel>(this: T, list: Record<string, any>[]): InstanceType<T>[] {
    return list.map(elem => this.new(elem) as InstanceType<T>);
  }

  /**
   * JSONから変換
   * œ
   * @param data
   */
  static new<T extends typeof ReactModel>(this: T, data: Record<string, any>): InstanceType<T> {
    const instance = Object.assign(new this(), data) as Record<string, any>;

    // 子要素
    for (const [prop, clazz] of Object.entries(this.modelMap)) {
      if (prop in instance) {
        if (Array.isArray(instance[prop])) {
          instance[prop] = clazz.inject(instance[prop])
        } else {
          instance[prop] = clazz.new(instance[prop]);
        }
      }
    }
    return instance as InstanceType<T>;
  }
}
