import {FC, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, Table} from "react-bootstrap";
import {useImmer} from "use-immer";
import {FormText, Nl2Br, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {CustomerType} from "backoffice/api/AppRouter/Loan/Customer";

// ページサイズ
const pageSize = 50;


export const LoanManagerCustomerListPage: FC = () => {

  useEffect(() => {
    document.title = '融資先一覧';

    doList(1).then();
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  // ステート
  const [search, setSearch] = useImmer({keyword: '', page: 0, pageSize});
  const [result, setResult] = useState({total: 1, results: [] as CustomerType[]});


  // リスト読み込み
  const doList: (page: number) => Promise<void> = useCallback(async (page) => {
    Loader.task(async () => {
      const work = {...search, page}
      setSearch(work);

      try {
        const result = await trpc.loan.customer.list.query(work);
        setResult(result);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [search]);


  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
          <Breadcrumb.Item active>融資先一覧</Breadcrumb.Item>
        </Breadcrumb>


        <FormText value={search.keyword}
                  onChange={({currentTarget: {value}}) => setSearch(draft => void (draft.keyword = value))}
                  onKeyDown={({key}) => key === 'Enter' && doList(1)}
                  placeholder={'キーワード検索（会社名、担当者名）'}
                  className={'my-3'}/>


        <Table striped bordered hover>
          <thead>
          <tr>
            <th rowSpan={2}>ID</th>
            <th rowSpan={2}>コード</th>
            <th rowSpan={2}>名称</th>
            <th colSpan={4}>担当者</th>
            <th rowSpan={2}>メモ</th>
          </tr>
          <tr>
            <th>役職</th>
            <th>氏名</th>
            <th>メールアドレス</th>
            <th>電話番号</th>
          </tr>
          </thead>

          <tbody style={{borderTop: '1.05px solid gray'}}>

          {/* データなし */}
          {result.results.length === 0 && (
              <tr>
                <td colSpan={8}>---</td>
              </tr>
          )}

          {/* データあり */}
          {result.results.length > 0 && (
              result.results.map(customer => (
                      <tr key={customer.id} className={'cursor-pointer'}
                          onClick={() => Navigate(`./${customer.id}`)}>
                        <td className={'align-middle'}>{customer.id}</td>
                        <td className={'align-middle'}>{customer.code}</td>
                        <td className={'align-middle'}>{customer.name}</td>
                        <td className={'align-middle'}>{customer.charge_title}</td>
                        <td className={'align-middle'}>{customer.charge_name}</td>
                        <td className={'align-middle'}>
                          <a href={`mailto:${customer.charge_email}`}
                             onClick={event => event.stopPropagation()}>
                            {customer.charge_email}
                          </a>
                        </td>
                        <td className={'align-middle'}>{customer.charge_mobile}</td>
                        <td className={'align-middle'}><Nl2Br text={customer.memo ?? ''}/></td>
                      </tr>
                  )
              )
          )}
          </tbody>

          <tfoot style={{borderTop: '1.05px solid gray'}}>
          <tr>
            <td colSpan={8}>
              <div className={'d-flex justify-content-center'}>
                <Link to={'./new'} className={'btn btn-sm btn-outline-primary mx-2'} style={{width: '20em'}}>
                  新規作成
                </Link>
              </div>
            </td>
          </tr>
          </tfoot>
        </Table>

        <div className={'d-flex justify-content-end'}>
          <Pager activePage={search.page}
                 pageSize={pageSize}
                 totalCount={result.total}
                 displaySize={2}
                 onChange={({page}) => doList(page)}/>
        </div>
      </div>
  );
}



