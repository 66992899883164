import {AbstractCode} from "src/codes/abstract";

/**
 * コードリスト
 */
const CodeList = [
  {
    id      : 1,
    value   : '融資',
    dateType: 'single',
    disabled: false,
  },
  {
    id      : 11,
    value   : '利息',
    dateType: 'range',
    disabled: false,
  },
  {
    id      : 12,
    value   : '遅損金',
    dateType: 'range',
    disabled: false,
  },
  {
    id      : 13,
    value   : '手数料',
    dateType: 'range',
    disabled: false,
  },
  {
    id      : 14,
    value   : '元本返済',
    dateType: 'single',
    disabled: false,
  },
  {
    id      : 111,
    value   : '利息（営業者報酬なし）',
    dateType: 'range',
    disabled: false,
  },
  {
    id      : 112,
    value   : '遅損金（営業者報酬なし）',
    dateType: 'range',
    disabled: false,
  },
  {
    id      : 201,
    value   : '貸倒金',
    dateType: 'single',
    disabled: false,
  },
  {
    id      : 301,
    value   : '代理受領分(IL)',
    dateType: 'single',
    disabled: false,
  },
  {
    id      : 302,
    value   : '代理受領分(PC)',
    dateType: 'single',
    disabled: false,
  },
  {
    id      : 211,
    value   : '為替差損',
    dateType: 'single',
    disabled: true,
    memo    : 'レガシー対応'
  }
] as const;


/**
 * タイプ
 */
export type LoanTradeType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キー アクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<LoanTradeType['value'], LoanTradeType>);


/**
 * 公開
 */
export const LoanTradeType = Object.assign(CodeClass, NameAccessor);
