import React, {FC} from "react";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {CallbackPage} from "./Callback";
import {MenuPage} from "./Menu";
import {DepositPage} from "./Deposit";
import {VirtualAccountPage} from "./VirtualAccount";
import {SubscribePage} from "./Subscribe";

export const GmoAozoraNetPage: FC = () => {
  return (
      <Routes>
        <Route index element={<MenuPage/>}/>
        <Route path="cb" element={<CallbackPage/>}/>
        <Route path="subscribe" element={<SubscribePage/>}/>
        <Route path="va" element={<VirtualAccountPage/>}/>
        <Route path="deposit" element={<DepositPage/>}/>
        <Route path="*" element={<Navigate to="../" replace/>}/>
      </Routes>
  );
}
