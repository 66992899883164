import {FormTextBase, FormTextBaseProps, FormTextBaseState} from "backoffice/ui/Components/FormTextBase";


export interface FormNumberProps extends FormTextBaseProps {
  precision: number | 0
}


export interface FormNumberState extends FormTextBaseState {
}

/**
 *
 */
export class Test01 extends FormTextBase<FormNumberProps, FormNumberState> {

  format = (input: string): string => {
    return input.toNumber(this.props.precision || 0) ?? '';
  }

  controlProps() {
    let {value, ...props} = super.controlProps();

    if (!this.state.hasFocus) {
      value = String(value as (string | number)).toNumber(this.props.precision || 0)?.withComma ?? '';
    }
    return {
      ...props, value
    }
  };
}
