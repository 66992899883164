import {FC, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, Table} from "react-bootstrap";
import {useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {UserType} from "backoffice/api/AppRouter/User";


export const UserListPage: FC = () => {

  useEffect(() => {
    document.title = 'ユーザー管理';

    doListUsers().then();
  }, []);

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [list, setList] = useState<UserType[]>([]);

  // ユーザーリスト読み込み
  const doListUsers: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      try {
        const list = await trpc.user.list.query();
        setList(list.sort((a, b) => a.createdAt.localeCompare(b.createdAt)));

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);


  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>ユーザー管理</Breadcrumb.Item>
        </Breadcrumb>

        <Table striped bordered hover>
          <thead>
          <tr>
            <th>メールアドレス</th>
            <th>名称</th>
            <th>メモ</th>
            <th className={'text-center'} style={{width: 80}}>アクセス</th>
            <th className={'text-center'} style={{width: 200}}>最終更新日時</th>
          </tr>
          </thead>

          <tbody style={{borderTop: '1.05px solid gray'}}>

          {/* データなし */}
          {list.length === 0 && (
              <tr>
                <td colSpan={5}>---</td>
              </tr>
          )}

          {/* データあり */}
          {list.length > 0 && (
              list.map(user => (
                      <tr key={user.id} className={'cursor-pointer'}
                          onClick={() => Navigate(`./${user.id}/edit`)}>
                        <td className={'align-middle'}>{user.email}</td>
                        <td className={'align-middle'}>{user.name}</td>
                        <td className={'align-middle'}>{user.memo}</td>
                        <td className={'align-middle text-center'}>{user.disabled ? '無効' : '有効'}</td>
                        <td className={'align-middle text-center'}>
                          {new Date(user.createdAt).toYmd_hms()}
                        </td>
                      </tr>
                  )
              )
          )}
          </tbody>

          <tfoot style={{borderTop: '1.05px solid gray'}}>
          <tr>
            <td colSpan={6}>
              <div className={'d-flex justify-content-center'}>
                <Link to={'./create'} className={'btn btn-sm btn-outline-primary mx-2'} style={{width: '20em'}}>
                  新規作成
                </Link>
              </div>
            </td>
          </tr>
          </tfoot>
        </Table>
      </div>
  );
}



