import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '予定',
    memo : '適当に入れておける',
  },
  {
    id   : 2,
    value: '実績（承認待ち）',
    memo : '実際に入金がされたことを記録（入金日、分配償還対象日の入力がされる）',
  },
  {
    id   : 11,
    value: '承認（両替待ち）',
    memo : '分配データが作成され、両替対象データが含まれている',
  },
  {
    id   : 19,
    value: '承認',
    memo : [
      '承認され分配データが作成され、',
      '両替対象データが含まれていなかったか、',
      '含まれていた場合、両替が完了している',
      'また LegacyTradeが作成されている',
    ].join("\n")
  }
] as const;


/**
 * タイプ
 */
export type LoanTradeStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<LoanTradeStatus['value'], LoanTradeStatus>);


/**
 * 公開
 */
export const LoanTradeStatus = Object.assign(CodeClass, NameAccessor);
