import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 1,
    value: '出資払戻金',
    label: '売',
  }, {
    id   : 2,
    value: '入金',
  }, {
    id   : 3,
    value: '分配金',
  }, {
    id   : 4,
    value: '取消',
  }, {
    id   : 5,
    value: 'キャッシュバック',
  }, {
    id   : 6,
    value: 'Equityから振替',
  }, {
    id   : 7,
    value: '分配金調整加算額',
  }, {
    id   : 8,
    value: '源泉税調整加算額',
  }, {
    id   : 9,
    value: '遅延損害金',
  }, {
    id   : 10,
    value: '両替入金',
  }, {
    id   : 13,
    value: '損失確定額（帳尻合わせ）',
  }, {
    id   : 101,
    value: '地金売却',
  }, {
    id   : 1001,
    value: '投資',
    label: '買',
  }, {
    id   : 1002,
    value: '出金',
  }, {
    id   : 1004,
    value: '源泉徴収税',
  }, {
    id   : 1006,
    value: 'Equityへ振替',
  }, {
    id   : 1007,
    value: '分配金調整差引額',
  }, {
    id   : 1008,
    value: '源泉税調整差引額',
  }, {
    id   : 1010,
    value: '両替出金',
  }, {
    id   : 1011,
    value: '出金手数料',
  }, {
    id   : 1013,
    value: '損失確定額',
  }, {
    id   : 1101,
    value: '地金購入',
  }
] as const;


/**
 * タイプ
 */
export type TradeType = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<TradeType['value'], TradeType>);


/**
 * 公開
 */
export const TradeType = Object.assign(CodeClass, NameAccessor);
