import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {UIUXNumbersPage} from "./Numbers";

export const UIUXPage: FC = () => {
  return (
      <Routes>
        <Route path="numbers" element={<UIUXNumbersPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


