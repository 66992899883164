import React, {FC, ReactNode, useState} from 'react';
import ReactDOM from "react-dom";
import {LoaderComponent} from "./Component";

// Container
const container: HTMLBodyElement | null = document.querySelector('body');


interface LoaderTask<R> {
  (): Promise<R>;
}

interface LoaderContextType {
  // 表示
  show: (message: string) => void,

  // 非表示
  hide: () => void,

  // タスク
  task<R>(task: LoaderTask<R> | Promise<R>): Promise<R>,

  task<R>(task: LoaderTask<R> | Promise<R>, msec: number): Promise<R>,

  task<R>(message: string, task: LoaderTask<R> | Promise<R>): Promise<R>,

  task<R>(message: string, task: LoaderTask<R> | Promise<R>, msec: number): Promise<R>
}


// コンテキスト
const LoaderContext                             = React.createContext<LoaderContextType>({} as LoaderContextType);
export const useLoader: () => LoaderContextType = () => React.useContext(LoaderContext);


// コンテナ
export const LoaderContainer: FC<{ children: ReactNode }> = ({children}) => {

  // シングルトン
  const [context] = useState<LoaderContextType>(() => {

    // 表示
    function fnShow(message: string) {
      setCount(prev => prev + 1);
      setMessage(message);
    }

    // 非表示
    function fnHide() {
      setCount(prev => Math.max(0, prev - 1));
    }

    // タスク
    async function fnTask(message: any, task?: any, msec?: number) {
      let _message: string;
      let _task: LoaderTask<any>;

      // メッセージを指定
      if (typeof message === 'string') {
        _message = message;
        _task    = task;
      }

      // メッセージ未指定
      else {
        _message = 'loading...';
        _task    = (message instanceof Promise) ? async () => await message : message;
        msec     = task;
      }

      try {
        // ローディング
        fnShow(_message);

        // タスク実行
        if (msec) {
          return await Date.duty(_task, msec);
        }
        return await _task();

      } finally {
        fnHide();
      }
    }

    // コンテキスト
    return {
      show: fnShow,
      hide: fnHide,
      task: fnTask,

      // 強制クローズ
      exit: () => setCount(0),
    };
  });

  // State
  const [count, setCount]     = useState<number>(0);
  const [message, setMessage] = useState<string>('');

  return (
      <>
        <LoaderContext.Provider value={context}>
          {children}
        </LoaderContext.Provider>

        {0 < count && container && (
            ReactDOM.createPortal(
                <LoaderComponent message={message} fullScreen={true}/>,
                container,
            )
        )}
      </>
  );
}
