import {faEdit, faMoneyBillAlt, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumb, Button, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap"
import {Link, useNavigate, useParams} from "react-router-dom"
import {Footer, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {useEffect, useState} from "react";
import {LoanTradeStatus} from "src/codes";
import type {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";
import type {Outputs} from "backoffice/api/AppRouter/Loan/Project/Trade";
import Big from "big.js";


export const LoanManagerProjectTradeListPage = () => {
  const projectId = Number(useParams().projectId);


  useEffect(() => {

    // データ読み込み
    Loader.task(async () => {
      try {
        const [project, trades] = await Promise.all([
          trpc.loan.project.get.query(projectId),
          trpc.loan.project.trade.list.query(projectId)
        ]);

        setProject(project);
        setTrades(trades);


      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [projectId]);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();


  const [project, setProject] = useState(null as (null | LoanProjectType)),
        [trades, setTrades]   = useState([] as Outputs['list']);


  // 通貨桁数
  const precision = project?.currency?.precision ?? 0;

  // 残高
  let remain = Big(0);

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>融資案件一覧</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `../${projectId}`}}>
              {project?.customer?.name ?? '---'} : {project?.name ?? '---'}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>取引一覧</Breadcrumb.Item>
          </Breadcrumb>


          <Row className={'mt-5'}>
            <small>
              <Table striped>
                <thead>
                <tr>
                  <th className={'text-center'}>条件</th>
                  <th className={'text-center'}>予定日</th>
                  <th className={'text-center'}>入金日</th>
                  <th className={'text-center'}>分配対象日</th>
                  <th className={'text-center'}>状況</th>
                  <th className={'text-end'}>融資/返済</th>
                  <th className={'text-end'}>損失</th>
                  <th className={'text-end'}>利息等</th>
                  <th className={'text-end'}>遅損金</th>
                  <th className={'text-end'}>その他</th>
                  <th className={'text-end'}>合計</th>
                  <th className={'text-end'}>残高</th>
                  <th className={'text-center'}>摘要</th>
                  <th>&nbsp;</th>
                </tr>
                </thead>

                <tbody>
                {trades.length === 0 && (
                    <tr>
                      <td colSpan={15}>取引がありません</td>
                    </tr>
                )}
                {trades.map(trade => {
                  remain = remain.add(trade.summary.loan)
                                 .sub(trade.summary.repayment)
                                 .sub(trade.summary.loss);

                  return (
                      <tr key={trade.id}>
                        <td className={'p-1 align-middle'} style={{width: "15em"}}>
                          {trade.condition?.name ?? '-'}
                        </td>

                        <td className={'p-1 align-middle text-center'} style={{width: "12em"}}>
                          {trade.processPlan?.toYmd()}
                        </td>

                        <td className={'p-1 align-middle text-center'} style={{width: "12em"}}>
                          {trade.processDate?.toYmd()}
                        </td>

                        <td className={'p-1 align-middle text-center'} style={{width: "12em"}}>
                          {trade.dividendDate?.toYmd()}
                        </td>

                        <td className={'p-1 align-middle text-center'}>
                          <TextDiv width={'6em'} placement={"top"}>
                            {LoanTradeStatus.find(trade.statusId).value}
                          </TextDiv>
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "10em"}}>
                          {trade.summary.loan.add(trade.summary.repayment).toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "8em"}}>
                          {trade.summary.loss.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "8em"}}>
                          {trade.summary.interest.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "8em"}}>
                          {trade.summary.delay.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "8em"}}>
                          {trade.summary.etc.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "10em"}}>
                          {trade.summary.total.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle text-end'} style={{width: "10em"}}>
                          {remain.toString().withComma}
                        </td>

                        <td className={'p-1 align-middle'} style={{width: "8em"}}>
                          <TextDiv width={50}>
                            {trade.memo}
                          </TextDiv>
                        </td>
                        <td className={`p-1 align-middle`}>
                          <Link to={`./${trade.id}`} className={'btn btn-primary btn-sm'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </Table>
            </small>
          </Row>
        </div>

        <Footer>

          <div className={'pr-2'}>
            <OverlayTrigger placement={'top'} overlay={<Tooltip>案件詳細</Tooltip>}>
              <Link to={`../${projectId}`} className={'btn btn-info btn-sm me-2'}>
                <FontAwesomeIcon icon={faEdit}/>
              </Link>
            </OverlayTrigger>

            <OverlayTrigger placement={'top'} overlay={<Tooltip>アロケーション</Tooltip>}>
              <Link to={`../${projectId}/allocation`} className={'btn btn-info btn-sm me-2'}>
                <FontAwesomeIcon icon={faSlidersH}/>
              </Link>
            </OverlayTrigger>

            <OverlayTrigger placement={'top'} overlay={<Tooltip>融資/返済</Tooltip>}>
              <div className={'btn btn-secondary btn-sm disabled me-2'}>
                <FontAwesomeIcon icon={faMoneyBillAlt}/>
              </div>
            </OverlayTrigger>
          </div>


          <Button variant="primary" className={'m-1'} style={{minWidth: 100}} size={'sm'}
                  href={'./trade/new'}>
            新規作成
          </Button>
        </Footer>
      </>
  )
}
