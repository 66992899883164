import {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {LoanManagerTradeListPage} from "./List";


export const LoanManagerTradePage: FC = () => {
  return (
      <Routes>
        <Route index element={<LoanManagerTradeListPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}


