import {FormTextBase, FormTextBaseProps, FormTextBaseState} from "backoffice/ui/Components";
import {Form} from "react-bootstrap";


export interface FormNumberProps extends FormTextBaseProps {
  precision: number | 0;
  realtime?: boolean;
}


export interface FormNumberState extends FormTextBaseState {
}

/**
 *
 */
export class Test02 extends FormTextBase<FormNumberProps, FormNumberState> {

  format = (input: string): string => {
    return input.toNumber(this.props.precision || 0) ?? '';
  }

  controlProps() {
    let {value, ...props} = super.controlProps();

    if (!this.state.hasFocus) {
      value = String(value as (string | number)).toNumber(this.props.precision || 0)?.withComma ?? '';
    }
    return {
      ...props, value
    }
  };

  render() {
    const {value, precision, realtime} = this.props;

    const showFormat = this.state.hasFocus && realtime,
          formatted  = String(value as (string | number)).toNumber(precision || 0)?.withComma ?? '';


    return (
        <div className={'position-relative align-bottom'}>
          <Form.Control {...this.controlProps()}
                        style={showFormat ? {padding: '0.70rem 0.75rem 0.05rem'} : {}}
          />
          {showFormat && (
              <span className={'position-absolute text-start fw-light'}
                    style={{
                      fontSize       : 'x-small',
                      backgroundColor: 'transparent',
                      padding        : '0.05rem 0.75rem',
                      ...{
                        top   : 0,
                        right : 0,
                        bottom: 0,
                        left  : 0,
                      }
                    }}>
                {formatted}
              </span>
          )}
        </div>
    );
  }
}
