import {FC} from "react";

const regex = /(\n)/g;

export const Nl2Br: FC<{ text?: string }> = ({text}) => {
  if (text) {
    let index = 0;
    return (
        <>
          {text.trim().split(regex).map(line => {
            if (line.match(regex)) {
              return <br key={index++}/>
            } else {
              return line.trim();
            }
          })}
        </>
    );
  }
  return <></>;
}
