import {ChangeEventHandler, FC, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Breadcrumb, Button, Col, Form, Row, Table} from "react-bootstrap";
import {useImmer} from "use-immer";
import {FormNumber, FormSelect, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {ResultType, ScriptType} from "backoffice/api/AppRouter/SQL";


export const SQLViewPage: FC = () => {

  useEffect(() => {
    document.title = 'SQL View';

    doListScripts().then();
  }, []);

  const Loader  = useLoader(),
        Message = useMessage(),
        trpc    = useTRPC();

  // パラメータ
  const {category} = useParams();

  // ステート
  const [list, setList]         = useState<ScriptType[]>([]),
        [selected, setSelected] = useState<ScriptType | null>(null),
        [args, setArgs]         = useImmer<Record<string, any>>({}),
        [result, setResult]     = useState<ResultType>({id: '', list: []} as ResultType);

  // スクリプト読み込み
  const doListScripts: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      try {
        const list = await trpc.sql.list.query(category || '');
        setList(list);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);


  /**
   * スクリプト選択
   */
  const handleChangeScript: ChangeEventHandler<HTMLSelectElement> = useCallback(({currentTarget: {value}}) => {
    const elem = list[Number(value)] ?? null;
    setSelected(elem);

    if (elem) {
      const args = elem.args.reduce<Record<string, any>>((result, row) => {
        row.forEach(arg => {
          result[arg.id] = arg.value ?? '';
        });
        return result;
      }, {})

      setArgs({...args, page: 1});
    }
  }, [list]);

  /**
   * パラメータ編集
   */
  const handleChangeArgs: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget: {name, value}}) => {
    setArgs(draft => void (draft[name] = value));
  }, []);


  /**
   * SQL実行
   *
   * @param page
   */
  const handleExecute = (page: number = 1) => {
    Loader.task(async () => {
      setArgs(draft => void (draft.page = page));

      try {
        const result = await trpc.sql.execute.query({category: category ?? '', id: selected?.id ?? '', args, page});
        setResult(result);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  /**
   * CSVダウンロード
   */
  const handleDownload = () => {
    Loader.task(async () => {
      try {
        const result = await trpc.sql.csvDownload.query({category: category ?? '', id: selected?.id ?? '', args});
        location.href = result.url;

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item active>SQLView</Breadcrumb.Item>
          <Breadcrumb.Item active>{category}</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
            <Form.Group controlId="email">
              <FormSelect onChange={handleChangeScript}>
                <option value={-1}>スクリプトを選択してください</option>
                {list.map((elem, index) => (
                    <option key={elem.id} value={index}>{elem.name}</option>
                ))}
              </FormSelect>
            </Form.Group>
          </Col>
        </Row>

        {selected !== null && (
            <>
              <Form style={{marginTop: 50, marginBottom: 50}}>
                {selected.args.map((row, index) => (
                    <Row key={`${selected.id}-row-${index}`} style={{marginBottom: 30}}>
                      {row.map(arg => (
                          <Col key={arg.id} md={{span: arg.span, offset: arg.offset ?? 0}}>
                            <>
                              <Form.Label>{arg.name}</Form.Label>
                              {(arg.type === 'text' || arg.type === 'date') && (
                                  <FormText name={arg.id}
                                            type={arg.type}
                                            onChange={handleChangeArgs}
                                            value={args[arg.id]}/>
                              )}

                              {(arg.type === 'number') && (
                                  <FormNumber name={arg.id}
                                              realtime
                                              precision={arg.precision ?? 0}
                                              onChange={handleChangeArgs}
                                              value={args[arg.id]}/>
                              )}
                            </>
                          </Col>

                      ))}
                    </Row>
                ))}

                <Row style={{marginTop: 50}}>
                  <Col md={{span: 3, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'} onClick={() => handleExecute(1)}>
                      実行する
                    </Button>
                  </Col>

                  <Col md={{span: 3}} style={{padding: 10}}>
                    <Button variant={'danger'} className={'w-100'} onClick={() => handleDownload()}>
                      CSVをダウンロード
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table striped bordered hover>
                {result.count && (
                    <caption>総件数:{String(result.count).withComma}</caption>
                )}
                <thead>
                <tr>
                  {selected.columns.map((col, colIndex) =>
                      <th key={`${selected.id}-${colIndex}`} className={col.className ?? ''}
                          style={col.style ?? {}}>
                        {col.name}
                      </th>
                  )}
                </tr>
                </thead>

                <tbody style={{borderTop: '1.05px solid gray'}}>

                {/* データなし */}
                {result.list.length === 0 && (
                    <tr>
                      <td colSpan={selected.columns.length}>---</td>
                    </tr>
                )}

                {/* データあり */}
                {result.list.map((row, rowIndex) =>
                    <tr key={`${result.id}-${rowIndex}`}>
                      {selected.columns.map((col, colIndex) =>
                          <td key={`${result.id}-${rowIndex}-${colIndex}`}
                              className={col.className ?? ''}
                              style={col.style ?? {}}>
                            {row[col.id] as string}
                          </td>
                      )}
                    </tr>
                )}
                </tbody>
              </Table>

              {/* ページャー */}
              {"limit" in selected && result.count && (
                  <div className={'d-flex justify-content-end'}>

                    <Pager activePage={args.page}
                           pageSize={selected.limit}
                           totalCount={result.count}
                           displaySize={2}
                           onChange={({page}) => handleExecute(page)}/>
                  </div>
              )}
            </>
        )}
      </div>
  );
}



