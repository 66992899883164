import {FC, KeyboardEventHandler, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import {FormCol, FormSelect, FormText, Nl2Br, Pager, TextDiv, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {useTRPC} from "backoffice/ui/App";
import {Currency, TradeType} from "src/codes";
import {ValidationError} from "backoffice/api/ValidationError";
import {useSearchParams} from "react-router-dom";
import Big from "big.js";
import {Helmet} from "react-helmet";
import {useImmer} from "use-immer";
import {Trade} from "backoffice/api/RDS/Models/Member";
import {ModelObject} from "objection";

// ページサイズ
const pageSize = 50;

// 先月１日
const since = new Date();
since.addMonth(-1).setDate(1);

const defaultCondition = {
  userId     : '',
  fundId     : '',
  currencyId : '',
  typeId     : '',
  orderDate  : '',
  processDate: '',
}


export const MembersTradesListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // ステート
  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [error, setError]         = useState<ValidationError | null>(null),
        [result, setResult]       = useState({total: 1, list: [] as ModelObject<Trade>[]});


  // 初期化
  useEffect(() => {
    const cond = Object.entries(defaultCondition).reduce((cond, [k, v]) => {
            cond[k as keyof typeof defaultCondition] = query.get(k) || v;
            return cond;
          }, {} as typeof defaultCondition),
          page = Number(query.get('page')) || 1;

    setCondition(cond);

    if (!query.get('ts')) {
      return;
    }
    setError(null);

    // データ取得
    Loader.task(async () => {
      try {
        const {total, list} = await trpc.member.trade.list.query({...cond, page, pageSize});
        setResult({total, list});

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          setError(error);
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [query]);


  // エンターキー入力
  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.key === 'Enter' && handleSearch();
  };

  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', String(new Date().getTime()));
    setQuery(query);
  }

  return (
      <>
        <Helmet>
          <title>商品取引履歴</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>お客様管理</Breadcrumb.Item>
            <Breadcrumb.Item active>取引履歴</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Form className={'my-5'}>
                <Row>
                  <FormCol inputId={'userId'} className={'py-1'}
                           title={'ユーザーID'}
                           size={{xs: [4, 8], md: [2, 4]}}
                           error={error?.get('userId')}>

                    <FormText value={condition.userId}
                              onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.userId = value))}
                              onKeyDown={handleEnter}
                              placeholder={'ユーザーID'}/>
                  </FormCol>

                  <FormCol inputId={'fundId'} className={'py-1'}
                           title={'ファンドID'}
                           size={{xs: [4, 8], md: [2, 4]}}
                           error={error?.get('fundId')}>

                    <FormText value={condition.fundId}
                              onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.fundId = value))}
                              placeholder={'A0000XXXX'}/>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'currencyId'} className={'py-1'}
                           title={'通貨'}
                           size={{xs: [4, 8], md: [2, 4]}}
                           error={error?.get('currencyId')}>

                    <FormSelect value={condition.currencyId}
                                onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.currencyId = value))}>
                      <option value={''}>-</option>
                      {Currency.all.map(code => <option key={code.id} value={code.id}>{code.value}</option>)}
                    </FormSelect>

                  </FormCol>

                  <FormCol inputId={'typeId'} className={'py-1'}
                           title={'売買区分'}
                           size={{xs: [4, 8], md: [2, 4]}}
                           error={error?.get('typeId')}>

                    <FormSelect value={condition.typeId}
                                onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.typeId = value))}>
                      <option value={''}>-</option>
                      {TradeType.all.map(code => <option key={code.id} value={code.id}>{code.value}</option>)}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'orderDate'} className={'py-1'}
                           title={'注文日'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText value={condition.orderDate}
                              onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.orderDate = value))}/>
                  </FormCol>

                  <FormCol inputId={'processDate'} className={'py-1'}
                           title={'受渡日'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText value={condition.processDate}
                              onChange={({currentTarget: {value}}) => setCondition(draft => void (draft.processDate = value))}/>
                  </FormCol>
                </Row>

                <Row style={{marginTop: 30}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'} onClick={handleSearch}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <div className={'container-fluid small'}>
          <Table bordered striped>
            <colgroup>
              <col span={1} style={{width: '5rem'}}/>
              <col span={1} style={{width: '10rem'}}/>
              <col span={1} style={{width: '8rem'}}/>
              <col span={1} style={{width: '8rem'}}/>
              <col span={1} style={{width: '3rem'}}/>
              <col span={1} style={{width: '8rem'}}/>
              <col span={1} style={{width: '3rem'}}/>
              <col span={1} style={{width: '8rem'}}/>
              <col span={1} style={{width: '8rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
              <col span={1} style={{minWidth: '10rem'}}/>
            </colgroup>
            <thead>
            <tr>
              <th rowSpan={2}>ユーザID</th>
              <th rowSpan={2}>
                商品ID<br/>
                商品名
              </th>
              <th rowSpan={2}>売買区分</th>
              <th rowSpan={2}>単価</th>
              <th colSpan={2}>注文</th>
              <th colSpan={2}>約定</th>
              <th rowSpan={2}>受渡日</th>
              <th rowSpan={2}>摘要</th>
              <th rowSpan={2}>備考</th>
            </tr>

            <tr>
              {/*注文*/}
              <th>数量</th>
              <th>日時</th>

              {/*約定*/}
              <th>数量</th>
              <th>日時</th>
            </tr>
            </thead>

            <tbody style={{borderTop: '1.05px solid gray'}}>

            {/*データなし*/}
            {result.list.length === 0 && (
                <tr>
                  <td colSpan={13}>---</td>
                </tr>
            )}

            {/* データあり */}
            {result.list.map(trade => (
                <tr key={trade.record_number}>
                  <td>{trade.user_id}</td>
                  <td>
                    {trade.instrument_id && (
                        <TextDiv width={'10rem'}>
                          {trade.instrument_id}<br/>
                          {trade.instrument_name}
                        </TextDiv>
                    )}
                  </td>
                  <td>{trade.typeCode?.value}</td>
                  <td>
                    <div className={'text-end'}>{Big(trade.price).toFixed(2).withComma} {trade.currencyCode?.key || '---'}</div>
                  </td>
                  <td>
                    <div className={'text-end'}>{trade.order_quantity}</div>
                  </td>
                  <td>
                    <div className={'text-center'}>{trade.order_date?.toYmd_hms() ?? '----/--/-- --:--:--'}</div>
                  </td>
                  <td>
                    <div className={'text-end'}>{trade.agreement_quantity ?? '--'}</div>
                  </td>
                  <td>
                    <div className={'text-center'}>{trade.agreement_date?.toYmd_hms()}</div>
                  </td>
                  <td>
                    <div className={'text-center'}>{trade.process_date?.toYmd()}</div>
                  </td>
                  <td>
                    <TextDiv width={'10rem'}>
                      <Nl2Br text={[trade.summary, trade.summary2].filter(el => el).join("\n")}/>
                    </TextDiv>
                  </td>
                  <td>
                    <TextDiv width={'10rem'}>
                      {trade.memo}
                    </TextDiv>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-end'}>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={pageSize}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>
      </>
  );
}
