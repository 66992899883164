import {FC, useEffect} from "react";
import {signOut} from "aws-amplify/auth";

/**
 * ログアウト処理
 */
export const LogoutPage: FC = () => {
  useEffect(() => {
    (async () => {
      try {
        await signOut();

      } finally {

        // 画面リロード
        window.location.href = '/';
      }
    })();
  }, []);

  return <></>;
}
