import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id        : 1,
    value     : "ファンド両替（邦貨 → 外貨）",
  }, {
    id        : 2,
    value     : "返済両替（外貨 → 邦貨）",
  }
] as const;


/**
 * タイプ
 */
export type ExchangeCategory = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeList.reduce((result, elem) => {
  result[elem.value] = elem;
  return result;
}, {} as Record<ExchangeCategory['value'], ExchangeCategory>);


/**
 * 公開
 */
export const ExchangeCategory = Object.assign(CodeClass, NameAccessor);
