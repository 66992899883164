import {FC,} from "react";
import {Route} from "react-router-dom";
import {Routes} from "react-router";
import {MembersNoticeMngNotificationListPage} from "./List";
import {MembersNoticeMngNotificationFormPage} from "backoffice/ui/Pages/Members/NoticeMng/Notification/Form";


export const MembersNoticeMngNotificationPage: FC = () => {
  return (
      <Routes>
        <Route index element={<MembersNoticeMngNotificationListPage/>}/>
        <Route path=":id" element={<MembersNoticeMngNotificationFormPage/>}/>
      </Routes>
  );
}


