import "src/utils/browser";

import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from "aws-amplify";
import {LoaderContainer, MessageModalContainer} from "./src/ui/Components";
import App from './src/ui/App';
import {config} from "./src/ui/config";

import './index.css';


(async () => {
  const {Auth, API: {endpoint}} = await config();
  Amplify.configure({Auth});

  const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
  );

  root.render(
      <React.StrictMode>
        <LoaderContainer>
          <MessageModalContainer>
            <App apiEndpoint={endpoint as string}/>
          </MessageModalContainer>
        </LoaderContainer>
      </React.StrictMode>
  );
})();

