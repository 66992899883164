import {FormTextBase, FormTextBaseProps, FormTextBaseState} from "./FormTextBase";
import {Form} from 'react-bootstrap';


export interface FormNumberProps extends FormTextBaseProps {
  precision?: number
  realtime?: boolean
  align?: 'start' | 'end'
}


export interface FormNumberState extends FormTextBaseState {
}

/**
 *
 */
export class FormNumber extends FormTextBase<FormNumberProps, FormNumberState> {

  public static defaultProps: FormNumberProps = {
    precision: 0,
    realtime : false,
  };

  format = (input: string): string => {
    return String(input).toNumber(this.props.precision || 0) ?? '';
  }

  controlProps() {
    let {value, placeholder, realtime, ...props} = super.controlProps();

    if (!this.state.hasFocus) {
      value = String(value as (string | number)).toNumber(this.props.precision || 0)?.withComma ?? '';
    } else {
      placeholder = '';
    }

    return {
      ...props, value, placeholder
    }
  };

  render() {
    const {value, precision, size, realtime, align, className} = this.props;

    const someStyle = (() => {
      switch (size) {
        case 'sm':
          return {
            ctrlPadding: '.495rem .5rem .005rem',
            hintPadding: '.05rem .375rem',
            hintSize   : 'xx-small',
          }

        case 'lg':
          return {
            ctrlPadding: '.99rem 1rem .01rem',
            hintPadding: '.10rem .75rem',
            hintSize   : 'small'
          }

        default:
          return {
            ctrlPadding: '.7425rem .75rem .0075rem',
            hintPadding: '.075rem .5625rem',
            hintSize   : 'x-small'
          }
      }

    })();

    const showFormat = this.state.hasFocus && realtime,
          formatted  = String(value as (string | number)).toNumber(precision || 0)?.withComma ?? '';

    const formControl = <Form.Control {...this.controlProps()}
                                      className={`${className} text-${align ?? 'start'}`}
                                      style={showFormat ? {padding: someStyle.ctrlPadding} : {}}/>;

    if (realtime) {
      return (
          <div className={'position-relative align-bottom'}>
            {formControl}
            {showFormat && (
                <span className={`position-absolute text-${align ?? 'start'} fw-light`}
                      style={{
                        fontSize       : someStyle.hintSize,
                        backgroundColor: 'transparent',
                        padding        : someStyle.hintPadding,
                        inset          : 0
                      }}>
                {formatted}
              </span>
            )}
          </div>
      );
    }
    return formControl;
  }
}
