export {}

type KeyGenerator<T> = (elem: T) => string

/**
 * Array拡張
 */
declare global {
  interface Array<T> {
    toggle(input: T): Array<T>;

    // codeSort(): Array<T>;
    index(idName: string): Record<string, T>

    // 重複除外
    unique(): Array<T>
    unique(keyGenFn: KeyGenerator<T>): Array<T>
  }
}

Array.prototype.toggle = function (input) {
  if (this.includes(input)) {
    return this.filter(elem => elem !== input);
  }
  return [...this, input];
}


Array.prototype.index = function (idName: string) {
  return this.reduce((res, el) => {
    res[el[idName]] = el;
    return res;
  }, {});
}

Array.prototype.unique = function (keyGen?: KeyGenerator<any>) {
  if (!keyGen) {
    return [...new Set<any>(this)];
  }

  return Object.values(this.reduce((sets, el) => {
    const key = keyGen(el);
    if (!(key in sets)) {
      sets[key] = el;
    }
    return sets;
  }, {}));
}

