import {AbstractCode} from "src/codes/abstract";


/**
 * コードリスト
 */
const CodeList = [
  {
    id   : 0,
    value: '期日通り',
  },
  {
    id   : 1,
    value: '一部遅延中'
  },
  {
    id   : 2,
    value: '全額遅延中'
  },
] as const;


/**
 * タイプ
 */
export type LoanProjectDelayStatus = typeof CodeList[number];


/**
 * コードクラス化
 */
const CodeClass = new AbstractCode(CodeList);


/**
 * キーアクセス
 */
const NameAccessor = CodeClass.all.reduce((result, elem) => {
  result[elem.value as LoanProjectDelayStatus['value']] = elem;
  return result;
}, {} as Record<LoanProjectDelayStatus['value'], LoanProjectDelayStatus>);


/**
 * 公開
 */
export const LoanProjectDelayStatus = Object.assign(CodeClass, NameAccessor);
