import {FC, ReactNode} from "react";
import {Container} from "react-bootstrap";

export const Footer: FC<{ className?: string, children: ReactNode }> = ({className, children}) => (
    <Container as={'footer'} fluid
               className={`d-flex justify-content-end align-items-center ${className || ''}`}
               style={{backgroundColor: "rgba(200,200,200,0.6)"}}>
      {children}
    </Container>
);
