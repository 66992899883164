import {Constructor} from "backoffice/api/types";
import {ReactModel} from "backoffice/ui/utils/react/model";


/**
 * NoticeInfoModel
 *
 * クライアント側で利用するモデルクラス
 */
export class NoticeInfoModel extends NoticeInfoMixin(ReactModel) {
  // クライアント側のみで使用するコード
}

/**
 * 共通機能機能 Mixin関数
 *
 * @param Base
 * @constructor
 */
export function NoticeInfoMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {

    // 共通コード
    declare notice_id: string;
    declare notice_name: string;
    declare topic_arn: string;
    declare topic_name: string;
    declare message: string;
    declare title: string;
    declare subtitle: string;
    declare category: string;
    declare plan_delivery_date: Date;
    declare delivery_status: number;
    declare message_id?: string;
    declare send_status?: number;
    declare error_detail: {};
    declare last_summary_date: Date;
    declare delivery_total?: number;
    declare delivery_send?: number;
    declare delivery_faild?: number;
    declare open_count?: number;
    declare create_at: Date;
    declare update_at?: Date;


    // コード化するのが面倒だった
    get delivery_status_value(): string {
      switch (this.delivery_status) {
        case 0:
          return "未配信";

        case 1:
          return "配信処理中";

        case 2:
          return "配信済み";
      }
      return "";
    }

    get send_status_value(): string {
      switch (this.send_status) {
        case 0:
          return "未送信";

        case 1:
          return "正常";

        case 8:
          return "異常（送信前）";

        case 9:
          return "異常";
      }
      return "";
    }
  };
}


