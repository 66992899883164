import {FC, KeyboardEventHandler, useCallback, useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import {useTRPC} from "backoffice/ui/App";
import {FormText, useLoader, useMessage} from "./index";
import {ModelObjectSlim} from "backoffice/api/types";
import type {LoanManager} from "backoffice/api/RDS";

import "./LoanCustomerModal.scss";


/**
 * 融資先選択モーダル
 *
 * @param show
 * @param size
 * @param onSelect
 * @param onCancel
 * @constructor
 */
export const LoanCustomerModal: FC<{
  size?: 'sm' | 'lg' | 'xl',
  show: boolean,
  onSelect: (customer: ModelObjectSlim<LoanManager.Customer>) => void
  onCancel: () => void
}> = ({show, size, onSelect, onCancel}) => {

  const [keyword, setKeyword] = useState(''),
        [list, setList]       = useState([] as ModelObjectSlim<LoanManager.Customer>[]);


  const Loader  = useLoader(),
        Message = useMessage(),
        trpc    = useTRPC();


  // 融資客検索
  const search = useCallback((keyword: string) => {
    Loader.task(async () => {
      try {
        const {results} = await trpc.loan.customer.list.query({keyword, page: 1, pageSize: 20});
        setList(results);
      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);


  // エンターキー入力
  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.key === 'Enter' && search(keyword);
  };

  return (
      <Modal show={show}
             backdropClassName={'system-modal'}
             size={size || 'lg'}
             onHide={onCancel}>

        <Modal.Header>
          <h5>融資先検索</h5>
        </Modal.Header>

        <Modal.Body className={`m-body`}>
          <div className={'m-3'}>
            <FormText placeholder={'名称などを入力してエンターキーを押して検索'}
                      value={keyword}
                      onChange={(event) => void (setKeyword(event.currentTarget.value))}
                      onKeyDown={handleEnter}/>
          </div>

          <div id={'loan-customer-modal-table-container'}>
            <Table striped bordered hover>
              <thead>
              <tr>
                <th className={`text-center`} style={{width: 120}}>コード</th>
                <th className={`text-center`} style={{width: 100}}>略称</th>
                <th className={`text-left`}>名称</th>
                <th className={`text-center`} style={{width: 100}}>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              {list.length === 0 && (
                  <tr>
                    <td colSpan={4}>検索してください</td>
                  </tr>
              )}

              {list.map(elem => (
                  <tr key={elem.id}>
                    <td className={`text-center`}>{elem.code}</td>
                    <td className={`text-center`}>{elem.short_name}</td>
                    <td className={`text-left`}>{elem.name}</td>
                    <td className={`text-center`}>
                      <Button className={`mx-1`} onClick={() => onSelect(elem)}>選択</Button>
                    </td>
                  </tr>
              ))}

              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer className={'justify-content-center'}>
          <Button variant="secondary" style={{minWidth: '10em'}}
                  onClick={onCancel}>
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
