import {FC, ReactNode} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/esm/types';

import './TextDiv.scss';

export const TextDiv: FC<{ width: string | number, placement?: Placement, children: ReactNode }> = ({width, placement = 'auto', children}) => (
    <OverlayTrigger placement={placement} overlay={
      <Tooltip style={{textAlign: 'left'}}>
        {children}
      </Tooltip>
    }>
      <div style={{width}} className={'text-div'}>
        {children}
      </div>
    </OverlayTrigger>
);
