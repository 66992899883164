import {FC, FormEventHandler, MouseEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useImmer} from "use-immer";
import {ConfirmModal, FormCheck, FormNumber, FormText, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {ValidationError} from "backoffice/api/ValidationError";
import {UserType} from "backoffice/api/AppRouter/User";
import {RoleType} from "backoffice/api/AppRouter/User/Role";

export const UserEditPage: FC = () => {

  useEffect(() => {
    document.title = 'ユーザー編集';

    doGetUser().then();

    (async () => {
      try {
        const code = await trpc.user.role.get.query();
        setRoles(code.contents);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    })();
  }, []);


  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  // パラメータ
  const {userId} = useParams();


  // ステート
  const [roles, setRoles]       = useState<RoleType[] | null>(null),
        [model, setModel]       = useImmer<UserType | null>(null),
        [error, setError]       = useState<ValidationError | null>(null),
        [deleting, setDeleting] = useState<boolean>(false);


  // ユーザー読み込み
  const doGetUser: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      if (!userId) {
        return;
      }

      try {
        const user = await trpc.user.get.query(userId);
        setModel(user);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [userId]);


  // 更新
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      if (!model) {
        return;
      }

      setError(null);

      try {
        await trpc.user.update.mutate(model);

        Message.show('更新が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };


  // 削除
  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = useCallback(async (_event) => {
    if (!userId) {
      return;
    }

    Loader.task(async () => {
      try {
        await trpc.user.delete.mutate({id: userId, createdAt: model?.createdAt ?? ''});

        Message.show('削除が完了しました', () => {
          Navigate('..');
        });

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  }, [userId, model?.createdAt]);

  return (
      <div className={'container'}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>ユーザー管理</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {!!model?.deletedAt && "削除済み"}
            {!!model?.deletedAt || "編集"}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} style={{marginTop: 50}}>
          <input type='submit' className={'d-none'} disabled/>

          {!!model?.deletedAt && (
              <Row style={{marginBottom: 50}}>
                <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
                  <p className={'fs-2 text-danger border border-1 border-danger rounded p-2 text-center'}>
                    削除済みユーザーは編集できません
                  </p>
                </Col>
              </Row>
          )}

          <Row style={{marginBottom: 50}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <FormText type="email"
                          value={model?.email ?? ''}
                          readOnly
                          disabled={!!model?.deletedAt}/>
                <Form.Text className="text-muted">
                  <small>
                    メールアドレスの変更はできません。
                  </small>
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="username">
                <Form.Label>お名前</Form.Label>
                <FormText placeholder="クラウド太郎"
                          value={model?.name ?? ''}
                          onChange={({currentTarget: {value}}) => setModel(draft => void (draft && (draft.name = value)))}
                          disabled={!!model?.deletedAt}
                          required/>
                <Form.Text className="text-muted">
                  <small>
                  </small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('name')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Group controlId="memo">
                <Form.Label>メモ</Form.Label>
                <FormText placeholder="メモ"
                          value={model?.memo ?? ''}
                          onChange={({currentTarget: {value}}) => setModel(draft => void (draft && (draft.memo = value)))}
                          disabled={!!model?.deletedAt}/>
                <Form.Text className="text-muted">
                  <small></small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('memo')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}} style={{padding: 10}}>
              <Form.Label>ロール</Form.Label>
              <div className={'d-flex flex-wrap align-content-start'}>
                <div className={'m-1'}>
                  <FormCheck id={'role-admin'}
                             checked={model?.admin ?? false}
                             onChange={() => setModel(draft => void (draft && (draft.admin = !draft?.admin)))}
                             disabled={!!model?.deletedAt}>
                    <span>管理者</span>
                  </FormCheck>
                </div>

                {roles?.map(role => (
                    <div className={'m-1'}>
                      <FormCheck id={role.id} key={role.id}
                                 checked={model?.roles?.includes(role.id) ?? false}
                                 onChange={() => setModel(draft => void (draft && (draft.roles = draft.roles?.toggle(role.id) ?? [role.id])))}
                                 disabled={!!model?.deletedAt}>
                        <span>{role.name}</span>
                      </FormCheck>
                    </div>
                ))}
              </div>
            </Col>
          </Row>

          <Row style={{marginTop: 20}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="disabled">
                <Form.Check label={`アクセス無効化`}
                            type={'checkbox'}
                            onChange={() => setModel(draft => void (draft && (draft.disabled = !draft?.disabled)))}
                            checked={model?.disabled ?? false}
                            disabled={!!model?.deletedAt}/>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{marginTop: 20}}>
            <Col md={{span: 8, offset: 2}} style={{padding: 5}}>
              <Form.Group controlId="legacyId">
                <Form.Label>旧Ver. ID</Form.Label>
                <FormNumber value={model?.legacyId ?? ''}
                            onChange={({currentTarget: {value}}) => setModel(draft => void (draft && (draft.legacyId = Number(value))))}
                            disabled={!!model?.deletedAt}
                            required/>
                <Form.Text className="text-muted">
                  <small>旧システム連携用</small>
                </Form.Text>

                <div>
                  <Form.Text className="text-danger">
                    <Nl2Br text={error?.get('legacyId')}/>
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={{span: 8, offset: 2}}>
              <div className={'d-flex justify-content-end'}>
                <Form.Text className="text-muted">
                  <small><>最終更新日時: {new Date(model?.updatedAt ?? model?.createdAt ?? '').toYmd_hms()}</>
                  </small>
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row style={{marginTop: 50}}>
            <Col md={{span: 4, offset: 2}} style={{padding: 10}}>
              <Button variant="primary" className={'w-100'} type='submit' disabled={!model || !!model?.deletedAt}>
                更新する
              </Button>
            </Col>

            <Col md={{span: 4}} style={{padding: 10}}>
              <Button variant={'danger'} className={'w-100'} onClick={() => setDeleting(true)} disabled={!model || !!model?.deletedAt}>
                削除する
              </Button>
            </Col>
          </Row>

          <Row style={{marginTop: 100}}>
            <Col md={{span: 4, offset: 4}} style={{padding: 10}}>
              <Link to={'..'} className={'btn btn-secondary w-100'}>
                もどる
              </Link>
            </Col>
          </Row>
        </Form>

        {/* 削除モーダル */}
        <ConfirmModal show={deleting}
                      keyword={model?.email ?? '削除'}
                      confirmLabel={'削除する'}
                      confirmButtonProps={{
                        variant: 'danger',
                        value  : userId
                      }}
                      onConfirm={handleClickDelete}
                      onCancel={() => setDeleting(false)}>

          ユーザー「{model?.name}({model?.email})」を削除しますか？
        </ConfirmModal>
      </div>
  );
}


