import {faEdit, faMoneyBillAlt, faSlidersH, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Footer, FormNumber, FormText, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {ChangeEventHandler, useCallback, useEffect, useState} from "react";
import {Breadcrumb, Button, Form, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useTRPC} from "backoffice/ui/App";
import type {AllocationType} from "backoffice/api/AppRouter/Loan/Project/Allocation";
import {useImmer} from "use-immer";
import {Overwrite} from "backoffice/api/types";
import {v4 as uuid} from "uuid";
import {ValidationError} from "backoffice/api/ValidationError";
import {Helmet} from "react-helmet";
import {Currency} from "src/codes";
import {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";
import Big from "big.js";


/**
 * 編集用タイプ
 */
type AllocationEditType = Overwrite<AllocationType, {
  uuid?: string     // 管理用ID

  since: string
  until: string
  amount: string
  created: boolean
  edited: boolean
  deleted: boolean  // 削除フラグ
}>;


/**
 * ファンド名称
 *
 * 保存後にリセット
 */
let FundNames = {} as Record<string, string>;


/**
 * 新規アロケーション（編集行）
 */
const newAlloc = () => ({
  uuid    : uuid(),
  created : true,
  edited  : false,
  deleted : false,
  item_id : '',
  fundName: '',
  since   : '',
  until   : '',
  amount  : '0',
  memo    : '',
}) as AllocationEditType;


/**
 * サーバーから取得したデータ
 */
const LoadedData = {
  allocations: [] as AllocationEditType[]
}

/**
 * @constructor
 */
export const LoanManagerProjectAllocationFormPage = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const [project, setProject]           = useState({} as LoanProjectType),
        [allocations, setAllocations]   = useImmer<AllocationEditType[]>([]),
        [[error, errIndices], setError] = useState<[ValidationError | null, Record<number, number>]>([null, {}]),
        [showFinished, setShowFinished] = useState(false);

  // サーバーから取得したデータをセット
  const setServerAllocations = (allocations: AllocationType[]) => {

    // ファンド名称のキャッシュをクリア
    FundNames = {'proper': 'プロパー融資'} as Record<string, string>;

    // ファンド名称をキャッシュ
    allocations.forEach(alloc => {
      if (alloc.item_id && !FundNames[alloc.item_id]) {
        FundNames[alloc.item_id] = alloc.fundName;
      }
    });

    const converted: AllocationEditType[] = allocations.map(({since, until, amount, ...rest}) => ({
      ...rest,
      amount : amount.toString(),
      since  : since.toYmd(),
      until  : until.toYmd(),
      created: false,
      edited : false,
      deleted: false,
    }))

    // 読み込んだデータを保存
    LoadedData.allocations = converted;

    converted.push(newAlloc()); // 編集行追加
    setAllocations(converted);
  }

  // パラメータ
  const projectId = Number(useParams().projectId);

  useEffect(() => {

    // データ読み込み
    Loader.task(async () => {
      try {
        const _project     = await tRPC.loan.project.get.query(projectId),
              _allocations = await tRPC.loan.project.alloc.get.query(projectId);

        setProject(_project);
        setServerAllocations(_allocations);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [projectId]);


  /**
   * 終了したアロケーションが大量にあると、描画に時間がかかりすぎる（数秒）ため
   * 念のためローディングを表示する
   */
  const switchShowFinished = () => {
    Loader.show('レンダリング中です');

    setTimeout(() => {
      setShowFinished(!showFinished);
    }, 50)
  }

  // 描画終了でローダー非表示
  useEffect(() => {
    Loader.hide();
  }, [showFinished]);


  // データ編集
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(({
    currentTarget: {name, checked, type, dataset: {index}, value}
  }) => {
    const idx = Number(index);

    let fundName: string | false = false;
    if (name === 'item_id') {
      fundName = FundNames[value] ?? '';

      if (value.length === 9) {
        loadFundNames([value]).then();
      }
    }

    // アロケーション更新
    setAllocations(draft => {

      // ファンド名称
      if (fundName !== false) {
        draft[idx].fundName = fundName;
      }

      // 新規データの削除
      if (!draft[idx].id && name === 'deleted') {
        draft.splice(idx, 1);
        return;
      }

      // @ts-ignore 値更新
      draft[idx][name] = type === 'checkbox' ? checked : value;

      // 新規データでなければ、編集チェック
      if (!draft[idx].created) {
        const orig        = LoadedData.allocations[idx];
        draft[idx].edited = (draft[idx].item_id !== orig.item_id
                             || draft[idx].since !== orig.since
                             || draft[idx].until !== orig.until
                             || draft[idx].amount !== orig.amount
                             || draft[idx].memo !== orig.memo);
      }

      // 新規行の追加
      if (idx === draft.length - 1) {
        draft.push(newAlloc());
      }
    })
  }, [setAllocations]);

  // ファンド名称取得＆セット
  const loadFundNames = useCallback(async (fundIds: string[]) => {
    const names = await tRPC.loan.project.alloc.funds.query(fundIds);
    Object.entries(names).forEach(([id, name]) => {
      FundNames[id] = name;
    })

    // モデルセット
    setAllocations(draft => {
      draft.forEach(alloc => {
        alloc.fundName = FundNames[alloc.item_id ?? ''] ?? '';
      });
    });
  }, [FundNames]);


  // 保存
  const handleSubmit = async () => {
    Loader.task(async () => {

      // エラーを解除
      setError([null, {}]);

      /**
       * 未編集のデータは保存しない
       * そのため、バリデーションチェック時のデータと表示データの間にインデックスのズレが生まれるので、そのズレをindicesに保存しておく。
       * バリデーションエラー時に setErrorで一緒に保存しておき、表示する際に使用する
       */
      const [indices, allocs] = allocations.slice(0, -1).reduce(([indices, allocs], alloc, index) => {
        if (alloc.created || alloc.edited || alloc.deleted) {
          indices[index] = allocs.length;

          allocs.push({
            id     : alloc.id,
            item_id: alloc.item_id!,
            since  : new Date(alloc.since),
            until  : new Date(alloc.until),
            amount : Big(alloc.amount),
            memo   : alloc.memo,
            deleted: alloc.deleted
          });
        }
        return [indices, allocs];
      }, [{}, []] as [Record<number, number>, Parameters<typeof tRPC.loan.project.alloc.save.mutate>[0]["allocations"]]);


      if (allocs.length === 0) {
        return;
      }

      try {
        const allocations = await tRPC.loan.project.alloc.save.mutate({
          projectId,
          allocations: allocs,
        });


        Message.show('更新が完了しました', () => {
          if (allocations) {
            setServerAllocations(allocations);
          } else {
            location.reload();
          }
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError([error, indices]);
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  const tradeDate = project?.latestTradeDate?.toYmd() ?? '----/--/--',
        currency  = Currency.find(project?.currencyId);

  return (
      <>
        <Helmet>
          <title>アロケーション編集 | Backoffice</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: ".."}}>融資案件一覧</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `../${projectId}`}}>
              {project?.customer?.name ?? '---'} : {project?.name ?? '---'}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>アロケーション 最新返済日: {tradeDate}</Breadcrumb.Item>
          </Breadcrumb>

          <Row className={'mt-5'}>
            <Table className={'small'}>
              <thead>
              <tr>
                <th className={'text-center'}>
                  <FontAwesomeIcon icon={faTrash}/>
                </th>
                <th>ファンドID</th>
                <th>ファンド名称</th>
                <th>開始（予定）日</th>
                <th>終了（予定）日</th>
                <th>供出金額</th>
                <th>返済額</th>
                <th>メモ</th>
                <th>&nbsp;</th>
              </tr>
              </thead>

              <tbody>
              {allocations.length === 0 && (
                  <tr>
                    <td colSpan={7}>アロケーションがありません</td>
                  </tr>
              )}
              {allocations.map((alloc, index) => {
                let backgroundColor = '';
                if (alloc.deleted) {
                  backgroundColor = '#ffcccc';
                } else if (alloc.created) {
                  backgroundColor = '#e5ffcc';
                } else if (alloc.edited) {
                  backgroundColor = '#cce5ff';
                }

                if (!showFinished && alloc.ended) {
                  return;
                }

                // エラーコード
                const errAllocationIndex = `allocations.${errIndices[index] ?? 'none'}`;

                return (
                    <tr key={alloc.uuid ?? alloc.id}
                        style={{backgroundColor}}>

                      <td className={'p-1'}
                          style={{width: "2em"}}>

                        <label className={'d-flex justify-content-center m-0'}
                               style={{width: "2em", height: "2em"}}>

                          <input data-index={index}
                                 name={'deleted'}
                                 type={'checkbox'}
                                 className={'align-self-center'}
                                 checked={alloc.deleted}
                                 onChange={handleChange}
                                 disabled={alloc.started || allocations.length === index + 1}/>
                        </label>
                      </td>

                      <td className={'p-1'}
                          style={{width: "7.5em"}}>

                        <FormText data-index={index}
                                  name={'item_id'}
                                  value={alloc.item_id ?? ''}
                                  onChange={handleChange}
                                  maxLength={9}
                                  size={'sm'}
                                  disabled={alloc.started}/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`${errAllocationIndex}.item_id`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'}
                          style={{width: "16em"}}>

                        <FormText value={alloc.fundName}
                                  size={'sm'}
                                  readOnly disabled={alloc.started}/>
                      </td>

                      <td className={'p-1'} style={{width: "8em"}}>
                        <FormText data-index={index}
                                  name={'since'}
                                  type={'date'}
                                  value={alloc.since}
                                  onChange={handleChange}
                                  size={'sm'}
                                  disabled={alloc.started}/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`${errAllocationIndex}.since`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: "8em"}}>
                        <FormText data-index={index}
                                  name={'until'}
                                  type={'date'}
                                  value={alloc.until}
                                  onChange={handleChange}
                                  size={'sm'}
                                  disabled={alloc.ended}/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`${errAllocationIndex}.until`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: "8em"}}>
                        <FormNumber data-index={index}
                                    name={'amount'}
                                    align={'end'}
                                    value={alloc.amount}
                                    precision={currency?.precision || 0}
                                    onChange={handleChange}
                                    size={'sm'}
                                    realtime
                                    disabled={alloc.started}/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`${errAllocationIndex}.amount`)}/>
                        </Form.Text>
                      </td>

                      <td className={'p-1'} style={{width: "8em"}}>
                        <FormText value={alloc.repaid?.toString().withComma || '---'}
                                  className={'text-end'}
                                  size={'sm'}
                                  readOnly disabled/>
                      </td>

                      <td className={'p-1'}>
                        <FormText data-index={index} name={'memo'}
                                  value={alloc.memo ?? ''}
                                  onChange={handleChange}
                                  size={'sm'}/>

                        <Form.Text className="text-danger">
                          <Nl2Br text={error?.get(`${errAllocationIndex}.memo`)}/>
                        </Form.Text>
                      </td>
                    </tr>
                )
              })}
              </tbody>
            </Table>
          </Row>
        </div>

        <Footer>
          <OverlayTrigger placement={'top'}
                          overlay={<Tooltip>終了したアロケーションを表示する</Tooltip>}>
            <Form.Check type="switch"
                        checked={showFinished}
                        onChange={switchShowFinished}/>
          </OverlayTrigger>

          <div className={'px-2'}>
            <OverlayTrigger placement={'top'}
                            overlay={<Tooltip>案件詳細</Tooltip>}>

              <Link to={`../${projectId}`}
                    className={'btn btn-info btn-sm me-2'}>
                <FontAwesomeIcon icon={faEdit}/>
              </Link>
            </OverlayTrigger>

            <OverlayTrigger placement={'top'} overlay={<Tooltip>アロケーション</Tooltip>}>
              <div className={'btn btn-secondary btn-sm disabled me-2'}>
                <FontAwesomeIcon icon={faSlidersH}/>
              </div>
            </OverlayTrigger>

            <OverlayTrigger placement={'top'}
                            overlay={<Tooltip>融資/返済</Tooltip>}>

              <Link to={`../${projectId}/trade`}
                    className={'btn btn-info btn-sm me-2'}>
                <FontAwesomeIcon icon={faMoneyBillAlt}/>
              </Link>
            </OverlayTrigger>
          </div>

          <Button variant="primary" className={'m-1'} style={{minWidth: 100}} size={'sm'}
                  onClick={handleSubmit}>
            保存
          </Button>
        </Footer>
      </>
  );
}

