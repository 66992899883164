import {FC, FormEventHandler, MouseEventHandler, useCallback, useEffect, useState,} from "react";
import {Breadcrumb, Button, Form, Table} from "react-bootstrap";
import {useImmer} from "use-immer";
import {v4 as uuid} from 'uuid';
import {ConfirmModal, Nl2Br, useLoader, useMessage} from "backoffice/ui/Components";
import {useTRPC} from "backoffice/ui/App";
import {ValidationError} from "backoffice/api/ValidationError";
import {RoleModal} from "backoffice/ui/Pages/Etc/Users/Roles/Components/RoleModal";
import {SetCodeType} from "backoffice/api/AppRouter/Code";
import {RoleType} from "backoffice/api/AppRouter/User/Role";


type ModelType = SetCodeType<RoleType>;

// デフォルトモデル
const defaultModel: ModelType = {
  id      : 'dummy',
  contents: [],
};

export const RolesPage: FC = () => {

  useEffect(() => {
    document.title = 'ロール管理';

    // 初回データ取得
    doGetCode().then();
  }, []);

  const Loader  = useLoader(),
        Message = useMessage(),
        trpc    = useTRPC();

  const [model, setModel]               = useImmer<ModelType>(defaultModel),
        [error, setError]               = useState<ValidationError | null>(null),
        [editingRole, setEditingRole]   = useState<RoleType | null>(null),
        [deletingRole, setDeletingRole] = useState<RoleType | null>(null),
        [edited, setEdited]             = useState<boolean>(false);


  // コード読み込み
  const doGetCode: () => Promise<void> = useCallback(async () => {
    Loader.task(async () => {
      try {
        const code = await trpc.user.role.get.query();
        setModel(code);

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, []);


  // 更新
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    // デフォルトの挙動を止める
    event.preventDefault();
    event.stopPropagation();

    Loader.task(async () => {
      if (!model) {
        return;
      }

      setError(null);

      try {
        const response = await trpc.user.role.set.mutate(model);
        Message.show('保存が完了しました', () => {
          setModel(response);
          setEdited(false);
        });

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)
          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  // 削除
  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = useCallback(async (_event) => {
    if (!deletingRole) {
      return;
    }

    setModel(draft => void (draft.contents = draft.contents.filter(el => el.id !== deletingRole.id)));
    setDeletingRole(null);
    setEdited(true);
  }, [deletingRole]);

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>ロール管理</Breadcrumb.Item>
          </Breadcrumb>

          <Form id={'role-form'} onSubmit={handleSubmit} style={{marginTop: 50}}>
            <input type='submit' className={'d-none'} disabled/>

            <Table striped bordered hover>
              <colgroup>
                <col/>
                <col/>
                <col style={{width: 200}}/>
              </colgroup>

              <thead>
              <tr>
                <th>名称</th>
                <th>メモ</th>
                <th>&nbsp;</th>
              </tr>
              </thead>

              <tbody style={{borderTop: '1.05px solid gray'}}>
              {model.contents.length === 0 && (
                  <tr>
                    <td colSpan={3}>---</td>
                  </tr>
              )}
              {model.contents.map((elem, index) => (
                  <tr key={elem.id}>
                    <td className={'align-middle'}>
                      <div>
                        {elem.name}
                      </div>
                      <Form.Text className="text-danger">
                        <Nl2Br text={error?.get(`contents.${index}.name`)}/>
                      </Form.Text>
                    </td>
                    <td className={'align-middle'}>
                      <div>{elem.memo}</div>
                      <Form.Text className="text-danger">
                        <Nl2Br text={error?.get(`contents.${index}.memo`)}/>
                      </Form.Text>
                    </td>
                    <td className={'align-middle'}>
                      <div className={'d-flex justify-content-evenly'}>
                        <Button variant={"primary"} onClick={() => setEditingRole(elem)}>
                          編集
                        </Button>
                        <Button variant={"danger"} onClick={() => setDeletingRole(elem)}>
                          削除
                        </Button>
                      </div>
                    </td>
                  </tr>
              ))}

              </tbody>
              <tfoot style={{borderTop: '1.05px solid gray'}}>
              <tr>
                <td colSpan={6}>
                  <div className={'d-flex justify-content-center'}>
                    <Button variant='outline-primary' className={'mx-2'} style={{width: '20em'}}
                            onClick={() => setEditingRole({id: uuid(), name: '', permissions: [], memo: ''})}>
                      新規作成
                    </Button>
                  </div>
                </td>
              </tr>
              </tfoot>
            </Table>
          </Form>
        </div>

        {/* フッター */}
        <footer>
          <div className={'container'}>
            <div className={'m-1 d-flex flex-row-reverse'}>
              <Button variant='primary' className={'mx-2'} style={{width: "10em"}}
                      form={'role-form'} type={'submit'}
                      disabled={!edited}>
                保存
              </Button>
            </div>
          </div>
        </footer>

        {/* 編集モーダル */}
        <RoleModal value={editingRole}
                   onSubmit={(role: RoleType) => {
                     setModel(draft => {
                       if (editingRole === null) {
                         return;
                       }

                       const index = model.contents.indexOf(editingRole);
                       if (0 <= index) {
                         draft.contents.splice(index, 1, role);
                       } else {
                         draft.contents.push(role);
                       }
                     });
                     setEditingRole(null);
                     setEdited(true);
                   }}
                   onHide={() => setEditingRole(null)}/>


        {/* 削除モーダル */}
        <ConfirmModal show={deletingRole !== null}
                      confirmLabel={'削除する'}
                      confirmButtonProps={{
                        variant: 'danger',
                      }}
                      onConfirm={handleClickDelete}
                      onCancel={() => setDeletingRole(null)}>

          ロール「{deletingRole?.name ?? '名称未設定'}」を削除しますか？
        </ConfirmModal>
      </>
  );
}


